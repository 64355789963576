import { useCallback } from 'react';
import shallow from 'zustand/shallow';

import Modal from 'components/Modal';
import useMarketplaceQueryParams from 'hooks/useMarketplaceQueryParams';
import useAppStore from 'state';

import ConnectionComponents from './ConnectionComponents';

const ViewConnectionComponents = () => {
  const [connectionModalProps, setConnectionModalProps] = useAppStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { updateMarketplaceParams } = useMarketplaceQueryParams('/marketplace');

  const closeModal = useCallback(() => {
    setConnectionModalProps({});
  }, [setConnectionModalProps]);

  return (
    <Modal isOpen={true} onClose={closeModal} size="xl" showFooter={false}>
      <ConnectionComponents
        title={`${connectionModalProps.connection?.connection_type_description} components`}
        onClick={(uid) => {
          closeModal();
          updateMarketplaceParams({ itemType: 'components', itemUID: uid });
        }}
      />
    </Modal>
  );
};

export default ViewConnectionComponents;

import { useRouter } from 'next/router';

function usePreviousGraphVersion() {
  const router = useRouter();

  const previousGraphVersion = router.query.previousGraphVersion
    ? (router.query.previousGraphVersion as string)
    : null;

  return previousGraphVersion;
}

export default usePreviousGraphVersion;

import { Edge } from 'reactflow';
import { sortBy } from 'lodash';

import { EdgePath, GraphLookup, GraphNode, NodeData, Parameter, Port } from 'types';
import { GraphManifest, ManifestNode, NodeDisplay, ParameterType } from 'types/api';

import { getNodeName } from '.';

export function createInputsLookup(nodes: ManifestNode[]): Record<string, Edge> {
  // generate a lookup table from [input portName] => [node and output port]
  const allInputs: Record<string, Edge> = nodes.reduce((acc, n) => {
    // edges: input ports connected to node [n]'s output ports

    const edges = n.local_output_edges
      ? Object.keys(n.local_output_edges).reduce(
          (_, outputPort): Record<string, Edge> =>
            n.local_output_edges![outputPort].reduce((acc, edge) => {
              return {
                ...acc,
                [`${edge.node_id}-${n.id}`]: {
                  id: `${edge.node_id}-${n.id}`,
                  data: {
                    port: edge.port,
                    isImplicit: !(edge.port in (n.port_connections?.inputs || {})),
                  },
                  // data flows from source=>target
                  source: n.id,
                  target: edge.node_id,
                },
              };
            }, {}),
          {}
        )
      : {};

    return {
      ...acc,
      ...edges,
    };
  }, {});

  return allInputs;
}

function getOutputEdges(
  nodeId: string,
  outputs: Record<string, EdgePath[]>,
  connections: Record<string, string>
): Edge[] {
  return outputs
    ? Object.keys(outputs).reduce<Edge[]>((res, outputName) => {
        const edgePaths: EdgePath[] = outputs[outputName];
        return [
          ...res,
          ...edgePaths.map((edgePath) => ({
            id: `${nodeId}-${edgePath.node_id}`,
            // id of source node
            source: nodeId,
            data: { port: outputName, isImplicit: !(outputName in connections) },
            // id of target node
            target: edgePath.node_id,
          })),
        ];
      }, [])
    : [];
}

export function getInputEdges(
  nodeId: string,
  inputsLookup: Record<string, Edge>,
  connections: Record<string, string>
) {
  return inputsLookup && Object.values(inputsLookup)
    ? Object.values(inputsLookup)
        .filter((input) => input.target === nodeId)
        .map((edge) => {
          return {
            ...edge,
            data: {
              ...edge.data,
              isImplicit: !(edge.data.port in connections),
            },
          };
        })
    : [];
}

// used for converting manifest.nodes_by_id, interface.inputs, interface.outputs and interface.parameters
export function dictToArray<T>(keyField = 'id', dict: Record<string, any> = {}): T[] {
  return Object.keys(dict).map((key) => ({
    [keyField]: key,
    ...dict[key],
  }));
}

function addParameterValues(
  parameters: Parameter[],
  parameterValues: Record<string, any> | undefined
) {
  const newParameters =
    parameters?.map((p) => {
      const rawValue = parameterValues ? parameterValues[p.name] : undefined;
      // parameters with a value of 'undefined' or an empty string are considered 'unconfigured'. 'false' or zero are valid values
      const value = typeof rawValue !== 'undefined' && rawValue !== '' ? rawValue : null;
      return { ...p, value };
    }) || [];
  return newParameters;
}

// this function is used to create a lookup for the different graph levels
// it returns an array with nodes and edges for every graph level like:
// {
//  root: { nodes: [], edges: [] },
//  subGraphId: { nodes: [], edges: [] },
// }
export function createGraphLookup(manifest: GraphManifest): GraphLookup {
  if (!manifest.nodes) {
    return {};
  }

  const inputsLookup = createInputsLookup(manifest.nodes);

  const parsedNodes = manifest.nodes.reduce<GraphNode[]>((res, manifestNode) => {
    const hasParent = manifestNode.parent_node_id !== null;
    const isParent = manifestNode.node_type === 'graph';
    const parentNode = hasParent
      ? manifest.nodes.find((mn) => mn.id === manifestNode.parent_node_id)
      : null;
    const nodeParameters = dictToArray<Parameter>('name', manifestNode.interface?.parameters).map(
      (p) => {
        return { ...p, parameter_type: p.parameter_type || ParameterType.Text } as Parameter;
      }
    ); // if parameter_type is not defined, set it to text

    const parameters = addParameterValues(nodeParameters, manifestNode.parameter_values);
    const resolvedParameters = addParameterValues(
      nodeParameters,
      manifestNode.resolved_parameter_values
    );

    const parametersSorted = sortBy(parameters, (p) => p.name);
    const resolvedParametersSorted = sortBy(resolvedParameters, (p) => p.name);

    // parameters that are in manifestNode.parameter_values but not in manifestNode.interface?.parameters are considered 'unused'
    const parameterNames = Object.keys(manifestNode.interface?.parameters || {});
    const unusedParameters = Object.entries(manifestNode.parameter_values || {})
      .map(([key, value]) => {
        return { name: key, value: value };
      })
      .filter((p) => !parameterNames.includes(p.name));

    let parentGraphLevel = null;
    if (parentNode) {
      parentGraphLevel = parentNode.parent_node_id ? parentNode.parent_node_id : 'root';
    }

    const nodeInputs = dictToArray<Port>('name', manifestNode.interface?.inputs);
    const nodeOutputs = dictToArray<Port>('name', manifestNode.interface?.outputs);

    const nodeData: NodeData = {
      inputs: nodeInputs,
      outputs: nodeOutputs,
      parentNodeId: manifestNode.parent_node_id || null,
      id: manifestNode.id!,
      name: getNodeName(manifestNode, nodeOutputs),
      type: manifestNode.node_type,
      description_path: manifestNode.description_path,
      icon_url: manifestNode.icon_url,
      resolved_edges: manifestNode.resolved_output_edges
        ? getOutputEdges(
            manifestNode.id!,
            manifestNode.resolved_output_edges,
            manifestNode.port_connections?.outputs || {}
          )
        : [],
      local_output_edges: manifestNode.local_output_edges
        ? getOutputEdges(
            manifestNode.id!,
            manifestNode.local_output_edges,
            manifestNode.port_connections?.outputs || {}
          )
        : [],
      local_input_edges: getInputEdges(
        manifestNode.id!,
        inputsLookup,
        manifestNode.port_connections?.inputs || {}
      ),
      expanded: false,
      fromComponent: manifestNode.from_component || null,
      isParent: isParent,
      filePath: manifestNode.file_path || manifestNode.file_path_to_node_script_relative_to_root,
      display: manifestNode.display || ({ x: 0, y: 0, width: 1, height: 1 } as NodeDisplay),
      manifestErrors: manifestNode.errors || null,
      childNodeErrors: null,
      trigger: manifestNode.trigger || null,
      parentGraphLevel,
      parameters: parametersSorted,
      resolvedParameters: resolvedParametersSorted,
      unused_parameter_values: unusedParameters,
      resolvedStorage: manifestNode.resolved_storage
        ? {
            ...manifestNode.resolved_storage,
            namespacePath: manifestNode.resolved_storage.namespace_path,
          }
        : undefined,
      storageName: manifestNode.storage_name,
      wait_for_response: manifestNode.wait_for_response,
    };

    const graphNode: GraphNode = {
      id: manifestNode.id!,
      type: manifestNode.node_type === 'markdown' ? 'markdown' : 'patterns',
      data: nodeData,
      position: {
        x: 0,
        y: 0,
      },
    };

    res.push(graphNode);

    return res;
  }, []);

  // add manifest errors of child nodes to parents so that we can display them in the UI
  parsedNodes.forEach((node) => {
    if (node.data.manifestErrors && node.data.parentNodeId !== 'root') {
      const parentNode = parsedNodes.find((n) => n.id === node.data.parentNodeId);
      if (parentNode) {
        parentNode.data.childNodeErrors = parentNode.data.childNodeErrors || [];
        parentNode.data.childNodeErrors.push(...node.data.manifestErrors);
      }
    }
  });

  const graphLookup = parsedNodes.reduce<GraphLookup>((res, node) => {
    const parentId = node.data.parentNodeId || 'root';

    res[parentId] = res[parentId] || { nodes: [], edges: [], manifestErrors: [] };
    res[parentId].nodes.push(node);

    node.data?.local_output_edges?.forEach((edge) => {
      const connectionExists = res[parentId].edges.some((e) => e.id === edge.id);

      if (!connectionExists) {
        res[parentId].edges.push(edge);
      }
    });

    return res;
  }, {});

  return graphLookup;
}

import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { Graph } from 'types/api';
import { useDuplicateGraphURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

type DuplicateGraphData = {
  title: string;
  storage_uid: string;
  compute: string;
  organization_uid: string;
  graph_version_uid: string;
  slug?: string;
};
const url = useDuplicateGraphURL();

function useDuplicateGraph(options: UseMutationOptions<Graph, unknown, DuplicateGraphData> = {}) {
  const queryClient = useQueryClient();

  return useMutation<Graph, unknown, DuplicateGraphData>(async (data) => {
    const res = await Axios.post<Graph>(url, { ...data, slug: data.slug || data.title });

    queryClient.invalidateQueries(['graphs']);

    if (res.data.version_uid) {
      // Keeping track of the latest graph version updates for shitty multiplayer support
      const { setUpdatedGraphVersionUID } = useStore.getState();
      setUpdatedGraphVersionUID(res.data.version_uid);
    }

    return res?.data;
  }, options);
}

export default useDuplicateGraph;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { ConnectionTypePaginated } from 'types/api';
import { useConnectionTypesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (state: AppState) => state.organizationUID;

function useConnectionTypes(options?: UseQueryOptions<ConnectionTypePaginated>) {
  const organizationUID = useStore(selector);
  const url = useConnectionTypesURL(organizationUID);

  return useQuery<ConnectionTypePaginated>(
    ['connectionTypes', organizationUID],
    async () => {
      const res = await Axios.get<ConnectionTypePaginated>(url);

      return res.data;
    },
    {
      enabled: !!organizationUID,
      ...options,
    }
  );
}

export default useConnectionTypes;

import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { CloudStorage } from 'types/api';
import { useStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StorageQueryKey } from 'utils/queryKeys';

function useStorage(storageUid: string, options?: UseQueryOptions<CloudStorage>) {
  const queryClient = useQueryClient();
  const url = useStorageURL(storageUid);

  return useQuery<CloudStorage>(
    StorageQueryKey(storageUid),
    async () => {
      const res = await Axios.get<CloudStorage>(url);

      const storage = res.data;
      if (storage.scan_requested_at) {
        // The storage is currently being worked on.  Let's invalidate the query and have it rechecked
        setTimeout(() => {
          queryClient.invalidateQueries(StorageQueryKey(storageUid));
        }, 3000);
      }

      return storage;
    },
    {
      ...options,
    }
  );
}

export default useStorage;

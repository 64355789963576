import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  CircularProgress,
  HStack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import DateDisplay from 'components/DateDisplay';
import ModalComponent from 'components/Modal';
import { TForm, TFormInput } from 'components/TForm';
import { P1 } from 'components/Typography';
import useStorage from 'hooks/api/useStorage';
import useStorageScan from 'hooks/api/useStorageScan';
import useUpdateStorage from 'hooks/api/useUpdateStorage';
import useOrganization from 'hooks/useOrganization';
import useStore from 'state';
import { AppState, EditDatabaseModalProps } from 'types';
import { CloudStorage } from 'types/api';

import { storageTypes } from './DatabaseTypes';

const selector = (state: AppState) => ({
  setModalProps: state.setDatabaseModalProps,
});

export default function DatabaseDetailsModal(modalProps: EditDatabaseModalProps) {
  const { setModalProps } = useStore(selector, shallow);
  const form = useForm();
  const { control, handleSubmit, reset } = form;
  const { organization } = useOrganization();

  const { mutate: updateStorage } = useUpdateStorage();

  const [updateError, setUpdateError] = useState<string | null>(null);
  const { data: storage, isLoading, error } = useStorage(modalProps.storageUid);

  const storageType = storage
    ? storageTypes.find((t) => t.name === storage.config.type.toLowerCase())!
    : null;

  const { mutate: scan } = useStorageScan();

  useEffect(() => {
    if (storage) {
      reset({
        name: storage.name,
        readOnly: storage.read_only,
        typeLabel: storageType ? storageType.label : '',
        ...storage.config,
      });
    }
  }, [storage, reset, storageType]);

  const onUpdate = useCallback(
    (data: any) => {
      const storageConfig = storageType!.makeConfig(data);
      updateStorage(
        { storageUid: modalProps.storageUid, update: storageConfig },
        {
          onSuccess: () => {
            setModalProps({ action: 'hidden' });
          },
          onError: (error) => {
            if (error.response?.status == 424) {
              setUpdateError(error.message);
            }
          },
        }
      );
    },
    [modalProps.storageUid, setModalProps, storageType, updateStorage]
  );

  const checkboxType = (field: ControllerRenderProps<any, 'readOnly'>) => {
    return (
      <Checkbox disabled={true} name="readOnly" isChecked={field.value}>
        Read-Only
      </Checkbox>
    );
  };

  const scanStorage = useCallback(
    (storage: CloudStorage) => {
      scan({ storageUid: storage.uid, organizationUid: organization!.uid });
    },
    [scan, organization]
  );

  let storageError: ReactElement | null = null;
  if (storage?.last_scan_error) {
    storageError = (
      <>
        Failure <DateDisplay date={storage?.last_scan_at!} />. {storage.last_scan_error}
      </>
    );
  } else if (modalProps.error) {
    storageError = <>{modalProps.error}</>;
  }

  return (
    <ModalComponent
      size="xl"
      showFooter={false}
      title={'Database Details'}
      onClose={() => setModalProps({ action: 'hidden' })}
    >
      {updateError && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{updateError}</AlertDescription>
        </Alert>
      )}

      {!!error && (
        <Center>
          <P1 my={8}>{(error as any)?.message || 'Error loading storage'}</P1>
        </Center>
      )}
      {(isLoading || !storage) && (
        <Center>
          <P1 my={8}>Loading</P1>
        </Center>
      )}

      {storage && (
        <>
          <HStack>
            <Box p={4}>
              {storageError && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>{storageError}</AlertDescription>
                </Alert>
              )}
              {!storageError && (
                <>
                  {storage.scan_requested_at && (
                    <Stat>
                      <StatLabel>Table Scan</StatLabel>
                      <StatNumber>
                        <CircularProgress isIndeterminate />
                      </StatNumber>
                      <StatHelpText>
                        {storage.scan_started_at && (
                          <>
                            Scan started <DateDisplay date={storage.scan_started_at} />
                          </>
                        )}
                        {!storage.scan_started_at && (
                          <>
                            Requested <DateDisplay date={storage.scan_requested_at} />
                          </>
                        )}
                      </StatHelpText>
                    </Stat>
                  )}

                  {!storage.scan_requested_at && (
                    <Stat>
                      <StatLabel>Table Scan</StatLabel>
                      <StatNumber>
                        {!storage.scan_requested_at && <>{storage.table_count || 0} tables</>}
                      </StatNumber>
                      <StatHelpText>
                        Last scanned{' '}
                        {storage.last_scan_at ? (
                          <DateDisplay date={storage.last_scan_at} />
                        ) : (
                          ': never'
                        )}
                      </StatHelpText>
                    </Stat>
                  )}
                </>
              )}
            </Box>
            <Box>
              <Button disabled={!!storage.scan_requested_at} onClick={() => scanStorage(storage)}>
                Scan tables
              </Button>
            </Box>
          </HStack>
          <form onSubmit={handleSubmit(onUpdate)}>
            <TFormInput name="name" disabled={true} maxLength={32} form={form} isRequired={false} />

            <TForm form={form} name="readOnly" label="Access" isRequired={false}>
              <Controller
                name="readOnly"
                control={control}
                render={({ field }) => checkboxType(field)}
              />
            </TForm>

            <TFormInput
              name="typeLabel"
              label="Type"
              disabled={true}
              maxLength={32}
              form={form}
              isRequired={false}
            />

            {storageType && storageType.element(form, storage.managed ? 'show_managed' : 'edit')}

            {!storage.managed && (
              <Button
                type="submit"
                size="md"
                w="100%"
                mt={4}
                mb={2}
                disabled={!form.formState.isDirty}
              >
                Update Database
              </Button>
            )}
          </form>
        </>
      )}
    </ModalComponent>
  );
}

import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';

import config from 'config';
import { useAuth } from 'contexts/AuthContext';
import useStore from 'state';
import { AppState } from 'types';

const selector = (s: AppState) => ({
  setOrganizationUID: s.setOrganizationUID,
  organizationUID: s.organizationUID,
});

function useOrganization() {
  const { setOrganizationUID, organizationUID } = useStore(selector, shallow);
  const { account } = useAuth();

  const organizations = useMemo(() => {
    return account?.organizations || null;
  }, [account?.organizations]);

  const setOrganization = useCallback(
    (newOrgUID: string | null) => {
      if (
        typeof window !== 'undefined' &&
        window.localStorage &&
        newOrgUID &&
        organizations &&
        Object.keys(organizations).includes(newOrgUID)
      ) {
        window.localStorage.setItem(config.localstorage.organizationUID, newOrgUID);
      }
      setOrganizationUID(newOrgUID);
    },
    [setOrganizationUID, organizations]
  );

  const organization = useMemo(() => {
    if (organizationUID && organizations && Object.keys(organizations).includes(organizationUID)) {
      return organizations[organizationUID];
    } else {
      return null;
    }
  }, [organizations, organizationUID]);

  return { setOrganization, organization, organizationUID };
}

function useOrganizationAppEffects() {
  const { setOrganization, organizationUID } = useOrganization();
  const { account } = useAuth();
  const router = useRouter();

  const organizations = useMemo(() => {
    return account?.organizations || null;
  }, [account?.organizations]);

  useEffect(() => {
    // only do this if we're not on the graph page
    if (!router.pathname.startsWith('/graph')) {
      // when we load orgs, we check if there is a valid org in localstorage
      // otherwise we pick the first one to be the current org
      if (organizations && organizationUID === null) {
        let newOrgUID = null;
        if (typeof window !== 'undefined' && window.localStorage) {
          const localStorageOrgUID = window.localStorage.getItem(
            config.localstorage.organizationUID
          );
          // check if the org uid is a valid org for the account
          if (localStorageOrgUID && Object.keys(organizations).includes(localStorageOrgUID)) {
            newOrgUID = localStorageOrgUID;
          }
        }
        if (!newOrgUID && Object.keys(organizations).length > 0) {
          newOrgUID = Object.keys(organizations)[0];
        }
        if (newOrgUID) {
          setOrganization(newOrgUID);
        }
      }
    }
  }, [setOrganization, organizations, organizationUID, router.pathname]);

  useEffect(() => {
    // if there's a new_org query param, then update the organization and remove the query param
    if (organizations && router.query.new_org) {
      const orgUid = router.query.new_org as string;
      if (Object.keys(organizations).includes(orgUid)) {
        setOrganization(orgUid);
        delete router.query.new_org;
        router.replace(
          {
            pathname: router.pathname,
            query: router.query,
          },
          undefined,
          { shallow: true }
        );
      }
    }
  }, [organizations, router, setOrganization]);
}

const orgUIDSelector = (s: AppState) => s.organizationUID;

function useOrganizationUID(): string | null {
  return useStore(orgUIDSelector);
}

export default useOrganization;

export { useOrganizationAppEffects, useOrganizationUID };

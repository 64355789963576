import { ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import { sourceCode } from 'styles/fonts';

type DarkTextWrapperProps = {
  children: ReactNode;
  isIcon?: boolean;
};

export default function DarkTextWrapper({
  children,
  isIcon = false,
  ...rest
}: DarkTextWrapperProps & BoxProps) {
  return (
    <Box
      as="span"
      color="white"
      borderRadius={isIcon ? '3px' : '2px'}
      bg={colors.dark.bg1}
      paddingX="4px"
      className={sourceCode.className}
      paddingY={isIcon ? '1px' : 0}
      fontSize={isIcon ? '12px' : '12px'}
      fontWeight={isIcon ? 800 : 600}
      {...rest}
    >
      {children}
    </Box>
  );
}

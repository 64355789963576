import { useMemo } from 'react';
import { Flex } from '@chakra-ui/react';

import CustomIcon from 'components/Icon';
import { NodeData } from 'types';
import { getIconForNodeType, isComponentNodeType } from 'utils/nodes';

type NodeIconProps = {
  nodeData: NodeData;
  nodeId: string;
  color: string;
  position?: 'relative' | 'absolute';
  offset?: string;
};

function NodeIcon({
  nodeId,
  nodeData,
  color = 'blue.600',
  position = 'absolute',
  offset = '0px',
}: NodeIconProps) {
  const IconComponent = getIconForNodeType(nodeData);

  const componentString = useMemo(() => {
    return isComponentNodeType(nodeData.type) ? nodeData.fromComponent : undefined;
  }, [nodeData]);

  const iconURL = nodeData?.icon_url;

  return (
    <Flex
      position={position}
      alignItems="center"
      justifyContent="center"
      color={color}
      zIndex={3}
      data-iconid={nodeId}
      ml={offset}
    >
      {iconURL && (
        <CustomIcon
          iconURL={iconURL}
          name={componentString || nodeId || ''}
          width={22}
          height={22}
        />
      )}
      {/* Overflow set to visible to fix issue with edges of SVGs being cut off in Firefox */}
      {!iconURL && IconComponent && <IconComponent width="20px" height="20px" overflow="visible" />}
    </Flex>
  );
}

export default NodeIcon;

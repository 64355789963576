import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function PeopleDataLabsAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your People Data Labs Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="API key" form={form}>
          <FormHelperText>
            The API key can be found in the{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://dashboard.peopledatalabs.com/api-keys"
            >
              API Keys
            </a>{' '}
            section on the People Data Labs dashboard.
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const PeopleDataLabs: AuthFormDef = {
  connectionType: 'peopledatalabs',
  element: PeopleDataLabsAuthFormElement,
};

export default PeopleDataLabs;

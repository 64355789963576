import { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import CloneGraphModal from 'components/CloneGraphModal';
import CreateOrganizationModal from 'components/CreateOrganizationModal';
import LinkDatabaseModal from 'components/DatabaseModal';
import DatabaseDetailsModal from 'components/DatabaseModal/DatabaseDetailsModal';
import SecretsModal from 'components/SecretsModal';
import { useAuth } from 'contexts/AuthContext';
import useStore from 'state';
import { AppState } from 'types';
import {
  AddConnectionComponent,
  DeleteConnectionConfirmation,
  EditConnectionModal,
  NewConnectionModal,
  ReconnectModal,
} from 'views/Connections/Modals';
import ViewConnectionComponents from 'views/Connections/Modals/ViewConnectionComponents';
import DeleteVariableConfirmation from 'views/Secrets/DeleteVariableConfirmation';

const AppModals = () => {
  const [
    secretsModalProps,
    connectionsModalProps,
    databaseModalProps,
    showCreateOrgModal,
    showCreateAppModal,
    connectionModalProps,
    secretsToDelete,
  ] = useStore(
    (state) => [
      state.secretsModalProps,
      state.connectionsModalProps,
      state.databaseModalProps,
      state.showCreateOrgModal,
      state.showCreateAppModal,
      state.connectionModalProps,
      state.secretsToDelete,
    ],
    shallow
  );
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) return <></>;

  return (
    <Flex
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      {secretsModalProps.show && <SecretsModal {...secretsModalProps} />}
      {showCreateOrgModal && <CreateOrganizationModal />}
      {connectionsModalProps.show && <NewConnectionModal {...connectionsModalProps} />}
      {databaseModalProps.action === 'create' && <LinkDatabaseModal />}
      {databaseModalProps.action === 'edit' && <DatabaseDetailsModal {...databaseModalProps} />}
      {showCreateAppModal && <CloneGraphModal cloneData={showCreateAppModal} />}
      {connectionModalProps.action === 'delete' && <DeleteConnectionConfirmation />}
      {connectionModalProps.action === 'edit' && <EditConnectionModal />}
      {connectionModalProps.action === 'reconnect' && <ReconnectModal />}
      {connectionModalProps.action === 'add_component' && <AddConnectionComponent />}
      {connectionModalProps.action === 'view_components' && <ViewConnectionComponents />}
      {secretsToDelete.length > 0 && <DeleteVariableConfirmation />}
    </Flex>
  );
};

export default AppModals;

const setSelector = (state: AppState) => ({
  setSecretsModalProps: state.setSecretsModalProps,
  setConnectionsModalProps: state.setConnectionsModalProps,
  setDatabaseModalProps: state.setDatabaseModalProps,
  setShowCreateOrgModal: state.setShowCreateOrgModal,
  setShowCreateAppModal: state.setShowCreateAppModal,
});

const useCloseAllAppModals = () => {
  const {
    setSecretsModalProps,
    setConnectionsModalProps,
    setDatabaseModalProps,
    setShowCreateOrgModal,
    setShowCreateAppModal,
  } = useStore(setSelector, shallow);
  return useCallback(() => {
    setSecretsModalProps({ show: false });
    setConnectionsModalProps({ show: false });
    setDatabaseModalProps({ action: 'hidden' });
    setShowCreateOrgModal(false);
    setShowCreateAppModal(null);
  }, [
    setSecretsModalProps,
    setConnectionsModalProps,
    setDatabaseModalProps,
    setShowCreateOrgModal,
    setShowCreateAppModal,
  ]);
};

export { useCloseAllAppModals };

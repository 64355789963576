import { useToast } from '@chakra-ui/react';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { Variable, VariableData } from 'types/api';
import { useVariablesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (s: AppState) => s.organizationUID;

function useCreateVariable(options: UseMutationOptions<Variable, unknown, VariableData> = {}) {
  const organizationUID = useStore(selector);
  const toast = useToast();
  const queryClient = useQueryClient();
  const url = useVariablesURL(organizationUID);

  return useMutation<Variable, unknown, VariableData>(
    async ({ name, value, secret, description }: VariableData) => {
      const res = await Axios.post<Variable>(url, {
        name,
        value,
        secret,
        description,
      });

      await queryClient.invalidateQueries(['variables']);

      toast({
        status: 'success',
        title: 'Secret Created',
        description: `You have successfully created variable ${res?.data.name}`,
        duration: 3000,
        isClosable: true,
      });

      return res?.data;
    },
    options
  );
}

export default useCreateVariable;

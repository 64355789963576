import { IconType } from 'react-icons';
import { Box, Flex } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import Markdown from 'components/Markdown';
import PageTitle from 'components/PageTitle';
import useMarketplaceRelatedItems from 'hooks/api/useMarketplaceRelatedItems';
import useAppStore from 'state';
import Section from 'views/Marketplace/Section';

type ConnectionComponentsProps = {
  title: string;
  icon?: IconType;
  onClick?: (uid: string | null) => void;
};

const ConnectionComponents = ({ title, icon, onClick }: ConnectionComponentsProps) => {
  const [connectionModalProps] = useAppStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { data: relatedItems, isLoading: isLoadingRelated } = useMarketplaceRelatedItems(
    'connections',
    connectionModalProps.connection?.connection_type
  );

  const items = relatedItems?.components.results || [];

  const connectionTypeSnake = connectionModalProps.connection?.connection_type.replaceAll('-', '_');
  const markdownExample = `
\`\`\`python
# Use a Parameter to access your ${connectionModalProps.connection?.connection_type_description} connection
${connectionTypeSnake} = Parameter(
  '${connectionTypeSnake}',
  type=Connection("${connectionTypeSnake}")
)
  
# Non-working example for demonstration only
access_token = ${connectionTypeSnake}.get("access_token")
instance_url = ${connectionTypeSnake}.get("instance_url")

headers = {"Authorization": f"Bearer {access_token}", "Content-Type": "application/json"}

result = requests.get(
    url=f'{instance_url}/services',
    headers={"Authorization": f"Bearer {access_token}", "Content-Type": "application/json"}
)
\`\`\`
`;

  return (
    <>
      <Flex flexDirection="column">
        <Box py={3}>
          <PageTitle title={title} icon={icon} />
        </Box>

        {items.length <= 0 && (
          <Markdown>
            {`There are no available components for ${connectionModalProps.connection?.connection_type_description} but you can still access this connection through code
${markdownExample}`}
          </Markdown>
        )}

        {items.length > 0 && (
          <Flex direction="column" gap={5}>
            <Section
              category={'components'}
              title={'Components using this connection'}
              items={relatedItems?.components?.results || []}
              isLoading={isLoadingRelated}
              setItemUID={(uid) => onClick?.(uid)}
            />

            <Markdown>
              {`#### Or access ${connectionModalProps.connection?.connection_type_description} through code 
${markdownExample}`}
            </Markdown>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default ConnectionComponents;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Link, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { P2 } from 'components/Typography';
import { AuthFormDef } from 'views/Connections/Extra';

function OpenAIAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <P2 fontSize={14}>
        This connection will require Open AI{' '}
        <Link target="_blank" href="https://platform.openai.com/account/usage">
          credits
        </Link>
      </P2>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="API key" form={form} placeholder="sk-###">
          <FormHelperText>
            The API key can be found in the{' '}
            <Link target="_blank" href="https://beta.openai.com/account/api-keys">
              API Keys
            </Link>{' '}
            section on the Open AI dashboard.
          </FormHelperText>
        </TFormInputPassword>
        <TFormInputPassword
          name="openai_organization"
          label="Organization ID"
          form={form}
          placeholder="org-###"
        >
          <FormHelperText>
            The Organization ID can be found in the{' '}
            <Link target="_blank" href="https://beta.openai.com/account/org-settings">
              Settings
            </Link>{' '}
            section on the Open AI dashboard.
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const OpenAIAuthForm: AuthFormDef = {
  connectionType: 'openai',
  element: OpenAIAuthFormElement,
};

export default OpenAIAuthForm;

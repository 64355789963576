import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiKey } from 'react-icons/fi';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Switch,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import Modal from 'components/Modal';
import PageTitle from 'components/PageTitle';
import { P1, P2 } from 'components/Typography';
import useCreateVariable from 'hooks/api/useCreateVariable';
import useEditVariable from 'hooks/api/useEditVariable';
import useVariables from 'hooks/api/useVariables';
import useStore from 'state';
import { AppState, SecretsModalProps } from 'types';
import { VariableData } from 'types/api';
import { isNumeric } from 'utils';

const selector = (state: AppState) => ({
  setModalProps: state.setSecretsModalProps,
});

const SecretsModal = (modalProps: SecretsModalProps) => {
  const { setModalProps } = useStore(selector, shallow);
  const { name, value, isSecret = true, description, onSuccess, isEditMode = false } = modalProps;

  const { data: variables, isLoading: isLoadingVariables } = useVariables();

  const {
    mutate: createVariable,
    isLoading: isLoadingCreate,
    error: errorCreate,
  } = useCreateVariable({
    onSuccess: (secret) => {
      handleModalClose();
      onSuccess?.(secret);
    },
  });

  const {
    mutate: editVariable,
    isLoading: isLoadingUpdate,
    error: errorEdit,
  } = useEditVariable({
    onSuccess: (secret) => {
      handleModalClose();
      onSuccess?.(secret);
    },
  });

  const isSubmitting = isEditMode ? isLoadingUpdate : isLoadingCreate;
  const submissionLabel = isEditMode ? 'Update' : 'Create';

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<VariableData>({
    defaultValues: {
      name: name,
      value: value,
      secret: isSecret,
      description: description,
    },
  });

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const tableData = useMemo(() => {
    if (typeof variables !== 'undefined' && isNumeric(variables.count)) {
      return variables.results.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
    } else {
      return [];
    }
  }, [variables]);

  const handleModalClose = () => {
    setModalProps({ show: false });
  };

  const errorMessage = useMemo(() => {
    if (errorCreate instanceof Error) {
      return errorCreate.message;
    } else if (errorEdit instanceof Error) {
      return errorEdit.message;
    }
    return null;
  }, [errorCreate, errorEdit]);

  const _secret = watch('secret');
  const _name = watch('name');
  const _value = watch('value');
  const nameIsNotUnique = !isEditMode && tableData.some((row) => row.name === _name);

  // if we're creating the secret, then show sensitive text
  // if we're editing the secret, then only show sensitive text if the secret isn't sensitive
  const [showSensitiveText, setShowSensitiveText] = useState(!isEditMode ? true : !_secret);
  const [showHide, setShowHide] = useState(false);

  return (
    <Modal isOpen={modalProps.show} onClose={handleModalClose} showFooter={false}>
      <Box py={3}>
        <PageTitle title={isEditMode ? 'Edit Secret' : 'Create Secret'} icon={FiKey} />
      </Box>
      <P1>Nodes from any app in your organization can access parameter values set here.</P1>
      <br />
      <P1>
        Sensitive parameter values like API keys can be hidden from other members of the
        organization with the secret setting. Once entered, these values are not retrievable. Keep a
        copy.
      </P1>

      <form
        onSubmit={handleSubmit((data) => {
          data.name = data.name.trim();
          isEditMode ? editVariable(data) : createVariable(data);
        })}
      >
        <Flex flexDirection="column" gap={3}>
          <FormControl id="name" isInvalid={!!errors?.name} isRequired isDisabled={!!isEditMode}>
            <FormLabel>Name</FormLabel>
            <InputGroup size="md">
              <Input
                isInvalid={nameIsNotUnique}
                errorBorderColor="crimson"
                focusBorderColor={nameIsNotUnique ? 'crimson' : ''}
                placeholder="name (letters, numbers, and underscores)"
                data-testid="variable-name-input"
                maxLength={128}
                {...register('name', { required: true })}
              />
              {nameIsNotUnique && (
                <InputRightAddon>
                  <Tooltip
                    shouldWrapChildren
                    placement="top-end"
                    label="A secret with this name already exists"
                  >
                    <Icon
                      color="crimson"
                      as={RiErrorWarningLine}
                      width="20px"
                      height="20px"
                      mt={1}
                    />
                  </Tooltip>
                </InputRightAddon>
              )}
            </InputGroup>
            {errorMessage && (
              <P2 mt={2} color="red.600">
                {errorMessage}
              </P2>
            )}
          </FormControl>

          <FormControl id="value" isInvalid={!!errors?.value} isRequired>
            <FormLabel>Value</FormLabel>
            <InputGroup size="md" alignItems="center" justifyContent="center">
              <Input
                type={showSensitiveText ? 'text' : 'password'}
                onClick={() => {
                  if (_secret && _value === '********') {
                    setValue('value', '');
                    setShowHide(true);
                  }
                }}
                data-testid="variable-value-input"
                placeholder="abcd1234"
                autoComplete="abcd1234"
                maxLength={4096}
                {...register('value', { required: true })}
              />
              {_secret && showHide && (
                <InputRightElement width="4.5rem" alignItems="center" justifyContent="center">
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => setShowSensitiveText(!showSensitiveText)}
                  >
                    {showSensitiveText ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>

          <FormControl
            isInvalid={!!errors.secret}
            isDisabled={isEditMode}
            display="flex"
            gap={2}
            id="is-secret"
          >
            <Controller
              name="secret"
              control={control}
              render={({ field }) => (
                <Switch
                  isChecked={field.value}
                  data-testid="variable-secret-switch"
                  {...field}
                  value={typeof field.value === 'boolean' ? field.value.toString() : field.value}
                />
              )}
            />
            <FormLabel>Is sensitive?</FormLabel>
          </FormControl>

          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="This is an API key to pull transactions that provides access to pull data from our billing accounts."
              data-testid="variable-description-input"
              {...register('description', { required: false })}
            />
          </FormControl>

          <Button
            aria-label={submissionLabel.toLocaleLowerCase()}
            data-testid="org-submit"
            type="submit"
            size="md"
            w="100%"
            isLoading={isLoadingVariables && isSubmitting}
            mt={4}
            mb={2}
          >
            {submissionLabel}
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default SecretsModal;

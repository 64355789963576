import { useCallback } from 'react';
import { useRouter } from 'next/router';

import useGraph from 'hooks/api/useGraph';
import {
  EditorWindowTab,
  OrgCreationParamsType,
  PreviousGraphVersionParamsType,
  QueryParams,
} from 'types';

export function removeUndefinedFields(obj: any): Object {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

function useSetQueryParams() {
  const router = useRouter();
  const { refetch } = useGraph();

  // this should only be used when you need to set query params for multiple categories
  // at the same time. (need to use this then since making simultaneous query param updates
  // can lead to unexpected behavior)
  const setQueryParams = useCallback(
    async (params: QueryParams, replace?: boolean) => {
      let query: QueryParams = {};

      params = removeUndefinedFields(params);

      // Removes nulls
      Object.keys(params || {}).forEach((key) => {
        if (params?.[key as keyof QueryParams] === null) {
          delete router.query[key];
          delete params[key as keyof QueryParams];
        }
      });

      if (params) {
        query = {
          ...(router.query as QueryParams),
          ...params,
        };
      }

      // Replace will modify the current history entry and prevent a page re-render
      if (replace) {
        const queryParams = Object.keys(query)
          // Hardcoding these query parameters but would eventually be better to do this dynamically
          .filter((param) => !['id', 'slug'].includes(param))
          .map((key) => {
            const queryParamValue = query[key as keyof QueryParams];
            const normalizedValue =
              typeof queryParamValue === 'boolean' ? queryParamValue.toString() : queryParamValue;

            return `${encodeURIComponent(key)}=${encodeURIComponent(normalizedValue || '')}`;
          });

        const routerPath = router.asPath.split('?')[0];
        const newUrl =
          queryParams && queryParams.length > 0
            ? `${routerPath}?${queryParams.join('&')}`
            : routerPath;

        // https://github.com/vercel/next.js/discussions/18072
        window.history.replaceState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      } else {
        await router.push({
          pathname: router.pathname,
          query,
        });
      }
    },
    [router]
  );

  const setOrgCreationQueryParams = useCallback(
    async (params: OrgCreationParamsType, replace?: boolean) => {
      await setQueryParams(params, replace);
    },
    [setQueryParams]
  );

  const setPreviousGraphVersionParams = useCallback(
    async (params: PreviousGraphVersionParamsType, replace?: boolean) => {
      await setQueryParams(params, replace);
      await refetch();
    },

    [setQueryParams, refetch]
  );

  const setGraphNodeParams = useCallback(
    async (tab: EditorWindowTab | null, replace?: boolean) => {
      const params = {
        node: tab?.name ? encodeURIComponent(tab?.name) : null,
        topTab: tab?.topTab || null,
        // don't need to set bottom tab is console since that's the default
        bottomTab: tab?.bottomTab === 'Console' ? null : tab?.bottomTab || null,
      };
      await setQueryParams(params, replace);
    },
    [setQueryParams]
  );

  return {
    setQueryParams,
    setOrgCreationQueryParams,
    setPreviousGraphVersionParams,
    setGraphNodeParams,
  };
}

export default useSetQueryParams;

import { useCallback, useEffect, useState } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import Loader from 'components/Loader';
import ModalComponent from 'components/Modal';
import useStore from 'state';
import { ConnectionType } from 'types/api';
import { AuthFormValues } from 'views/Connections//hooks/useCreateConnection';
import AuthForm from 'views/Connections/AuthForm';
import ConnectingTo from 'views/Connections/ConnectingTo';
import { hasAuthForm } from 'views/Connections/Extra';
import useConnectionAuthorize from 'views/Connections/hooks/useConnectionAuthorize';
import useConnectionType from 'views/Connections/hooks/useConnectionType';

import useCreateCredentialsConnection from '../hooks/useCreateCredentialsConnection';

type LoadingTypeState = {
  state: 'loadingType';
};

type AuthFormState = {
  state: 'authForm';
  connectionType: ConnectionType;
};

type ConnectingToState = {
  state: 'connectingTo';
  connectionType: ConnectionType;
};

type SuccessState = {
  state: 'success';
};

type FailState = {
  state: 'fail';
  error: string;
};

type State = LoadingTypeState | AuthFormState | ConnectingToState | SuccessState | FailState;

function ReconnectModal() {
  const [connectionModalProps, setConnectionModalProps] = useStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { connection } = connectionModalProps;

  const closeModal = useCallback(() => {
    setConnectionModalProps({});
  }, [setConnectionModalProps]);

  const [reconnectState, setReconnectState] = useState<State>({ state: 'loadingType' });
  const createCredentialsConnection = useCreateCredentialsConnection();
  const authPopup = useConnectionAuthorize();

  const onConnect = useCallback(
    (connectionType: ConnectionType, formValues: AuthFormValues) => {
      setReconnectState({ state: 'connectingTo', connectionType });
      if (connectionType.auth_mechanism === 'creds') {
        if (!formValues) return;
        createCredentialsConnection.mutate(
          {
            connectionType,
            params: formValues,
            connectionUid: connection?.uid,
          },
          {
            onSuccess: () => {
              setReconnectState({ state: 'success' });
            },
            onError: (error) => {
              setReconnectState({ state: 'fail', error: error.message });
            },
          }
        );
      } else {
        authPopup({
          connectionType,
          formValues,
          connectionUid: connection?.uid,
          onSuccess: () => {
            setReconnectState({ state: 'success' });
          },
          onError: (error: string) => {
            setReconnectState({ state: 'fail', error });
          },
        });
      }
    },
    [authPopup, connection?.uid, createCredentialsConnection]
  );

  const { data: connectionData, isLoading: isLoadingConnectionType } = useConnectionType(
    connection?.connection_type
  );

  useEffect(() => {
    if (connectionData) {
      if (reconnectState.state === 'loadingType') {
        if (hasAuthForm(connectionData)) {
          setReconnectState({ connectionType: connectionData, state: 'authForm' });
        } else {
          onConnect(connectionData, {});
        }
      }
    }
  }, [connection, connectionData, onConnect, reconnectState.state]);

  if (!connection) return <></>;

  if (isLoadingConnectionType) return <Loader />;

  return (
    <ModalComponent
      size="xl"
      showFooter={false}
      title={`Reconnecting ${connection.name}`}
      onClose={closeModal}
      blockScrollOnMount={false}
    >
      <Box>
        {reconnectState.state === 'loadingType' && <Loader />}
        {reconnectState.state === 'authForm' && (
          <AuthForm
            connectionType={reconnectState.connectionType}
            onSubmit={(formValues) => {
              onConnect(reconnectState.connectionType, formValues);
            }}
          />
        )}
        {reconnectState.state === 'connectingTo' && (
          <ConnectingTo connectionType={reconnectState.connectionType} />
        )}
        {reconnectState.state === 'success' && (
          <Alert status="success">
            <AlertIcon />
            <AlertTitle>Success!</AlertTitle>
          </Alert>
        )}
        {reconnectState.state === 'fail' && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{reconnectState.error}</AlertTitle>
            <AlertDescription>Please retry</AlertDescription>
          </Alert>
        )}
      </Box>
    </ModalComponent>
  );
}

export default ReconnectModal;

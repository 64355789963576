type Id = string | null | undefined;

export const GraphStatusQueryKey = (): [string, string] => {
  return ['graph', 'status'];
};

export const ExecutionRunEventsQueryKey = (
  executionUID?: string | null
): [string, string, Id, string] => {
  return ['graph', 'executions', executionUID, 'runEvents'];
};

export const NodeRunEventsQueryKey = (nodeID?: string | null): [string, string, Id, string] => {
  return ['graph', 'nodes', nodeID, 'runEvents'];
};

export const StoreNodeDataQueryKey = (nodeID?: string | null): [string, string, Id, string] => {
  return ['graph', 'nodes', nodeID, 'storeData'];
};

export const StoreNodeMetadataQueryKey = (nodeID?: string | null): [string, string, Id, string] => {
  return ['graph', 'nodes', nodeID, 'metadata'];
};

export const ChartNodeDataQueryKey = (nodeID?: string | null): [string, string, Id, string] => {
  return ['graph', 'nodes', nodeID, 'chartData'];
};

export const NodeStateQueryKey = (nodeID?: string | null): [string, string, Id, string] => {
  return ['graph', 'nodes', nodeID, 'nodeState'];
};

export const NodeExecutionsQueryKey = (nodeID?: string | null): [string, string, Id, string] => {
  return ['graph', 'nodes', nodeID, 'executions'];
};

export const NodeExecutionsPlanQueryKey = (
  nodeID?: string | null
): [string, string, Id, string, string] => {
  return ['graph', 'nodes', nodeID, 'executions', 'plan'];
};

export const StorageHierarchyQueryKey = (): [string, string] => {
  return ['graph', 'storageHierarchy'];
};

export const StorageQueryKey = (storageUid: string): [string, string] => {
  return ['storage', storageUid];
};

export const StoragesQueryKey = (): [string] => {
  return ['storages'];
};

export const GraphQueryKey = (
  graphUID?: string | null,
  graphVersionUID?: string | null
): [string] | [string, Id] | [string, Id, Id] => {
  if (graphVersionUID && graphUID) {
    return ['graph', graphUID, graphVersionUID];
  } else if (graphUID) {
    return ['graph', graphUID];
  } else {
    return ['graph'];
  }
};

export const GraphVersionsQueryKey = (
  graphUID: string | null,
  page: number
): [string, Id, string, number] => {
  return ['graph', graphUID, 'versions', page];
};

export const GraphStorageQueryKey = (): [string, string] => {
  return ['graph', 'defaultStorage'];
};

export const WebhooksQueryKey = (nodeId: string): [string, string, string] => {
  return ['graph', 'webhooks', nodeId];
};

export const DashboardsQueryKey = (dashboardId: string): [string, string, string] => {
  return ['graph', 'dashboards', dashboardId];
};

export const DashboardsItemsQueryKey = (
  dashboardId: string,
  itemId: string
): [string, string, string, string] => {
  return ['graph', 'dashboards', dashboardId, itemId];
};

export const MarketplaceGraphQueryKey = (graphUID?: string): [string] | [string, Id] => {
  if (graphUID) {
    return ['marketplace-graph', graphUID];
  } else {
    return ['marketplace-graph'];
  }
};

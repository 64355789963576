import { FieldValues, UseFormReturn } from 'react-hook-form';

import { CloudStorageConfig } from 'types/api';

import { BigQueryStorageType } from './BigQueryDatabase';
import { MySqlStorageType, PostgresStorageType } from './SqlDatabase';

export type DatabaseFormMode = 'create' | 'edit' | 'show_managed';

export type StorageType = {
  name: string;
  label: string;
  tease_production: boolean;
  element(form: UseFormReturn<FieldValues, object>, mode: DatabaseFormMode): JSX.Element;
  makeConfig(formValues: any): CloudStorageConfig;
};

export const storageTypes: StorageType[] = [
  PostgresStorageType,
  MySqlStorageType,
  BigQueryStorageType,
] as StorageType[];

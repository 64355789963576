import { useMemo } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';

import { P1 } from 'components/Typography';
import { useAuth } from 'contexts/AuthContext';

function ErrorModal() {
  const { authError } = useAuth();

  const errorModal = useMemo(() => {
    if (authError) {
      Sentry.captureException(authError);
      const message =
        'Error authenticating. Please refresh the page or contact Patterns support. Error message: "' +
        authError.message +
        '"';
      return <ErrorModalInner message={message} />;
    } else {
      return null;
    }
  }, [authError]);

  return errorModal;
}

type ErrorModalInner = {
  message: string;
};

function ErrorModalInner({ message }: ErrorModalInner) {
  return (
    <Modal isOpen onClose={() => {}}>
      <ModalOverlay />
      <ModalContent minW="80%">
        <ModalCloseButton />
        <ModalBody m={2}>
          <P1>{message}</P1>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ErrorModal;

import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Center, Select, Spinner } from '@chakra-ui/react';

import { selectStyles } from 'components/CloneGraphModal/common';
import useStorages from 'hooks/api/useStorages';

function DatabaseSelect({ orgUID }: { orgUID: string }) {
  const { data: allStorages } = useStorages(orgUID);

  const writableStorages = useMemo(() => {
    if (allStorages) {
      allStorages.results = allStorages.results
        .filter((storage) => !storage.read_only)
        .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1));
    }
    return allStorages;
  }, [allStorages]);

  const { register, watch, setValue } = useFormContext();

  const dbValue = watch('database');

  useEffect(() => {
    if (!dbValue && writableStorages) {
      const defaultDB = writableStorages.results.find(
        (storage) => storage.name.toLowerCase() === 'default'
      );
      const managedDB = writableStorages.results.find((storage) => storage.managed);
      if (defaultDB) {
        setValue('database', defaultDB.uid);
      } else if (managedDB) {
        setValue('database', managedDB.uid);
      } else if (writableStorages.results.length > 0) {
        setValue('database', writableStorages.results[0].uid);
      }
    }
  }, [dbValue, writableStorages, setValue]);

  if (!writableStorages) {
    return (
      <Center>
        <Spinner h={8} w={8} />
        <input hidden {...register('database', { required: true })} />
      </Center>
    );
  }

  return (
    <Select
      {...selectStyles}
      {...register('database', {
        required: true,
      })}
    >
      {writableStorages?.results.map((storage) => (
        <option key={storage.uid} value={storage.uid}>
          {storage.name}
        </option>
      ))}
    </Select>
  );
}

export default DatabaseSelect;

import { useCallback, useMemo } from 'react';
import Router, { useRouter } from 'next/router';

import { MarketplaceState, QueryParams } from 'types';

import { removeUndefinedFields } from './useSetQueryParams';

function useMarketplaceQueryParams(customPath?: string) {
  const { pathname: currentPath, query, push } = useRouter();
  const pathname = customPath || currentPath;

  const openMarketplace = useCallback(
    async (marketplaceParams: MarketplaceState) => {
      let newQuery: any = { ...query, marketplace: true, ...marketplaceParams };
      newQuery = removeUndefinedFields(newQuery);
      await push({ pathname: pathname, query: newQuery });
    },
    [pathname, query, push]
  );

  const showMarketplace = useMemo(
    () => !!query.marketplace || pathname.startsWith('/marketplace'),
    [pathname, query.marketplace]
  );

  const closeMarketplace = useCallback(async () => {
    let newQuery = {
      ...query,
      search: undefined,
      category: undefined,
      itemType: undefined,
      itemUID: undefined,
      marketplace: undefined,
    } as QueryParams;
    newQuery = removeUndefinedFields(newQuery);
    await Router.push({ pathname: pathname, query: newQuery });
  }, [query, pathname]);

  const updateMarketplaceParams = useCallback(
    async (newValues: MarketplaceState) => {
      let newQuery = query;
      if (typeof newValues?.search !== 'undefined') {
        newQuery.search = newValues?.search || undefined;
      }
      if (typeof newValues?.category !== 'undefined') {
        newQuery.category = newValues?.category || undefined;
      }
      if (typeof newValues?.itemType !== 'undefined') {
        newQuery.itemType = newValues?.itemType || undefined;
      }
      if (typeof newValues?.itemUID !== 'undefined') {
        newQuery.itemUID = newValues?.itemUID || undefined;
      }
      const newQuery2 = removeUndefinedFields(newQuery) as QueryParams;
      await push({ pathname: pathname, query: newQuery2 });
    },
    [query, pathname, push]
  );

  return { updateMarketplaceParams, closeMarketplace, showMarketplace, openMarketplace };
}

export default useMarketplaceQueryParams;

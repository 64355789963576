// Copied from @patterns/content
import { BillingPlanType } from 'types/api';

type BasePlan = {
  name: string;
  price: number | string;
  gradient: string;
  color: string;
  link: string;
  creatable: boolean;
};

const BASE_PLAN: { [x in BillingPlanType]: BasePlan } = {
  personal: {
    name: 'personal',
    price: 0,
    gradient: '#141414',
    color: '#141414',
    link: 'https://studio.patterns.app/auth/login',
    creatable: false,
  },
  free: {
    name: 'free',
    price: 0,
    gradient: '#141414',
    color: '#141414',
    link: 'https://studio.patterns.app/auth/login',
    creatable: true,
  },
  pro: {
    name: 'pro',
    price: 800,
    gradient: 'linear-gradient(95.37deg, #1345F3 12.44%, #7819F1 46%)',
    color: '#1445F4',
    link: 'https://studio.patterns.app/auth/login',
    creatable: true,
  },
  enterprise: {
    name: 'enterprise',
    price: 'Tailored pricing for your business',
    gradient: 'linear-gradient(93.46deg, #FF470D 7.92%, #C7036D 84.08%);',
    color: '#FF470D',
    link: 'https://www.patterns.app/contact',
    creatable: false,
  },
};

export type Plan = BasePlan & {
  description: string;
  features: {
    title: string;
    description?: string;
    list?: string[];
  };
};

export const PLANS: Plan[] = [
  {
    ...BASE_PLAN.personal,
    description: 'A personal organization for trying out ideas and private projects',
    features: {
      title: 'Everything you need to get started',
      list: [
        'Shared infrastructure',
        '2GB Postgres DB',
        '100 CPU minutes/mo',
        'Unlimited Apps',
        '5 Users',
        '2 Connections',
        'SOC-2 compliant',
      ],
    },
  },
  {
    ...BASE_PLAN.free,
    description: 'For learning data science and running light workloads',
    features: {
      title: 'Everything you need to get started',
      list: [
        'Shared infrastructure',
        '2GB Postgres DB',
        '100 CPU minutes/mo',
        'Unlimited Apps',
        '5 Users',
        '2 Connections',
        'SOC-2 compliant',
      ],
    },
  },
  {
    ...BASE_PLAN.pro,
    description: 'Fully cloud managed infra for serious data jobs',
    features: {
      title: 'More compute + storage and features for collaboration',
      list: [
        'Isolated infrastructure',
        '10GB Postgres DB',
        '500 CPU minutes/mo',
        'Unlimited Apps',
        '10 Users',
        '5 Connections',
        'SOC-2 compliant',
        'Private Components',
      ],
    },
  },
  {
    ...BASE_PLAN.enterprise,
    description: 'On-premise or custom cloud deployments for companies with big teams and big data',
    features: {
      title: 'All team features',
      description:
        'Everything in Team with custom deployments to any cloud infra and custom pricing',
    },
  },
];

import { useCallback } from 'react';

import useStore from 'state';
import { AppState } from 'types';
import { Connection, ConnectionType } from 'types/api';
import useCreateConnection, { AuthFormValues } from 'views/Connections/hooks/useCreateConnection';

const selector = (s: AppState) => s.organizationUID;

function useConnectionAuthorize(): (x: {
  connectionType: ConnectionType;
  formValues?: AuthFormValues;
  connectionUid?: string;
  onSuccess: (completionResult?: Connection) => void;
  onError: (message: string) => void;
}) => void {
  const { mutate: createConnection } = useCreateConnection();
  const orgUid = useStore(selector) as string;

  return useCallback(
    (opts) => {
      createConnection(
        {
          connectionType: opts.connectionType,
          orgUid,
          formValues: opts.formValues,
          connectionUid: opts.connectionUid,
        },
        {
          onSuccess: (data) => {
            const { popupResult, completionResult } = data;
            switch (popupResult.type) {
              case 'success':
                opts.onSuccess(completionResult);
                break;
              case 'failure':
                opts.onError(popupResult.message);
                break;
            }
          },
          onError: (error) => {
            if (Array.isArray(error.message)) {
              opts.onError(
                error.message.length > 0 ? error.message[0].msg : 'Something went wrong'
              );
            } else {
              opts.onError(error.message || 'Something went wrong');
            }
          },
        }
      );
    },
    [createConnection, orgUid]
  );
}

export default useConnectionAuthorize;

import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import JSZip from 'jszip';
import { useRouter } from 'next/router';

import { VegaTheme } from 'types';
import { Graph } from 'types/api';
import { GraphQueryKey } from 'utils/queryKeys';
import { parseGraphManifest } from 'views/Graph/hooks/useParseGraphManifest';
import { dictToArray } from 'views/Graph/modules/GraphView/utils/manifest';
import useStore from 'views/Graph/state';

type graph_files = {
  [key: string]: string;
};

const parseGraphZip = async (base64: string | null): Promise<{ [key: string]: string }> => {
  if (!base64) {
    return {};
  }
  try {
    const binaryString = Buffer.from(base64, 'base64');
    var newZip = new JSZip();
    const zip = await newZip.loadAsync(binaryString);
    const files: { [key: string]: string } = {};

    for (const [relativePath, file] of Object.entries(zip.files)) {
      const fileContents = await file.async('string');
      files[relativePath] = fileContents;
    }
    return files;
  } catch (e) {
    console.error('parseGraphZip error: ', e);
    return {};
  }
};

function useParseGraphZip() {
  const queryClient = useQueryClient();

  const router = useRouter();

  const parseGraphVersion = useCallback(
    async (response: AxiosResponse<any>) => {
      const parse = async (zipFiles: string, graph?: Graph): Promise<graph_files> => {
        const graphFiles = await parseGraphZip(zipFiles);
        if (typeof window !== 'undefined' && graph && response.data.uid === router.query.id) {
          const { nodes, edges } = await parseGraphManifest(graph.manifest, 'root');
          const { setNodes, setEdges, setVegaTheme } = useStore.getState();
          setNodes(nodes);
          setEdges(edges);
          setVegaTheme(graph.theme as VegaTheme);
        }
        return graphFiles;
      };

      // Instead of invalidating the graph after a graph operation occurs, we automatically update the cache to avoid making an extra to call to our backend
      const updateCache = (newGraph: Graph) => {
        const queryKey = GraphQueryKey(router?.query?.id as string);

        queryClient.setQueryData(queryKey, () => {
          return newGraph;
        });
      };

      if (response?.data?.manifest?.nodes_by_id) {
        response.data.manifest.nodes = dictToArray('id', response.data.manifest.nodes_by_id);
      }

      if (response?.data?.zipped_graph) {
        response.data.graph_files = await parse(response.data.zipped_graph, response.data);
        // Only update cache if url matches graph uid in response. This will avoid updating the cache for marketplace related content
        if (response.data.uid === router.query.id) {
          updateCache(response.data);
        }
      }
      return response;
    },
    [queryClient, router?.query?.id]
  );

  return { parseGraphVersion };
}

export default useParseGraphZip;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { MarketplaceConnectionPaginated } from 'types/api';
import { useMarketplaceSearchConnectionsUrl } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const url = useMarketplaceSearchConnectionsUrl();

export default function useMarketplaceSearchConnections(
  searchSlug: string | null = null,
  tags: String[] | null = null,
  pageSize: number = 250,
  page: number = 1,
  options?: UseQueryOptions<MarketplaceConnectionPaginated>
) {
  async function getPage(): Promise<MarketplaceConnectionPaginated> {
    let params = {
      page_size: pageSize,
      search_slug: searchSlug,
      tags: tags || undefined,
      page: page,
    };
    if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog) {
      posthog.capture('marketplace-search-connections', params);
    }
    const res = await Axios.get<MarketplaceConnectionPaginated>(url, { params: params });
    return res.data;
  }

  return useQuery<MarketplaceConnectionPaginated>(
    ['marketplace', 'connections', 'search', pageSize, searchSlug, tags],
    getPage,
    options
  );
}

import { Grid } from '@chakra-ui/react';

import GraphCard from 'components/GraphCard';
import { MarketplaceAppTemplate } from 'types/api';

type AppsProps = {
  apps: MarketplaceAppTemplate[];
  onClick?: (c: string | null) => void;
};

function AppsList({ apps, onClick }: AppsProps) {
  return (
    <Grid
      templateColumns={{
        base: 'repeat(1, minmax(0, 1fr))',
        lg: 'repeat(2, minmax(0, 1fr))',
        xl: 'repeat(3, minmax(0, 1fr))',
      }}
      columnGap={4}
      rowGap={8}
    >
      {apps.map(
        ({ uid, graph_uid: graphUID, title, slug, manifest, short_description: description }) => {
          return (
            <GraphCard
              key={graphUID}
              uid={graphUID}
              title={title || slug || ''}
              slug={slug || ''}
              readOnly={true}
              manifest={manifest}
              description={description || ''}
              onClick={() => {
                onClick?.(uid);
              }}
            />
          );
        }
      )}
    </Grid>
  );
}

export default AppsList;

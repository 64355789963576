import { useToast } from '@chakra-ui/react';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Router from 'next/router';

import { Organization } from 'types/api';
import { useOrganizationURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

import { CreateOrganizationData } from './useCreatePaidOrganization';

type CreateOrgResponse = Organization;

function useCreateFreeOrganization(
  options?: UseMutationOptions<CreateOrgResponse, AxiosError, CreateOrganizationData>
): UseMutationResult<CreateOrgResponse, AxiosError, CreateOrganizationData, unknown> {
  const queryClient = useQueryClient();
  const url = useOrganizationURL();
  const toast = useToast();

  const mutation = useMutation(async ({ slug, description }: CreateOrganizationData) => {
    const result = await Axios.post<CreateOrgResponse>(url, {
      slug,
      description,
    });

    await queryClient.invalidateQueries(['account']);

    await queryClient.invalidateQueries(['account']);
    // push the new org_uid as a query param so we can load it after we reload the account
    Router.push({
      pathname: `/home`,
      query: { new_org: result?.data?.uid },
    });

    setTimeout(() => {
      // add 1-second delay so toast isn't closed on page navigation
      toast({
        status: 'success',
        title: 'Organization Created',
        description: `You have successfully created organization: ${result.data.slug} `,
        duration: 3000,
        isClosable: true,
      });
    }, 1000);

    return result?.data;
  }, options);

  return mutation;
}

export default useCreateFreeOrganization;

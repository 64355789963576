import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { colors } from 'styles/colors';

export interface IconButtonMinimalProps extends ButtonProps {
  label: string;
  Icon?: IconType;
  icon?: ReactNode;
  tooltip?: ReactNode;
  color?: string;
}

const IconButtonMinimal = ({
  label,
  Icon,
  icon,
  color = colors.gray[600],
  tooltip,
  ...rest
}: IconButtonMinimalProps) => {
  return (
    <Tooltip label={tooltip || label} placement="top" borderRadius={5}>
      <Button variant="unstyled" bg="white" aria-label={label} style={{ padding: 5 }} {...rest}>
        {Icon && <Icon color={color} />}
        {icon && icon}
      </Button>
    </Tooltip>
  );
};

export default IconButtonMinimal;

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Progress } from '@chakra-ui/react';

import { ConnectionType } from 'types/api';

export type connectingToProps = {
  connectionType: ConnectionType;
  failure?: string;
};

function ConnectingTo({ connectionType, failure }: connectingToProps) {
  if (failure) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error Connecting to {connectionType.description}</AlertTitle>
          <AlertDescription>{failure}</AlertDescription>
        </Alert>
        <br />
        <p>You can close this dialog and try again.</p>
      </Box>
    );
  }
  return (
    <Box>
      <Alert status="info">
        <AlertIcon />
        <AlertTitle>Connecting to: {connectionType.description}</AlertTitle>
      </Alert>
      <Progress size="xs" isIndeterminate />
    </Box>
  );
}

export default ConnectingTo;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { ConnectionType } from 'types/api';
import { useConnectionTypeURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (state: AppState) => state.organizationUID;

function useConnectionType(connectionTypeName?: string, options?: UseQueryOptions<ConnectionType>) {
  const organizationUID = useStore(selector);
  const url = useConnectionTypeURL(organizationUID, connectionTypeName);

  return useQuery<ConnectionType>(
    ['connectionType', organizationUID, connectionTypeName],
    async () => {
      const res = await Axios.get<ConnectionType>(url);
      return res.data;
    },
    {
      enabled: !!organizationUID || !!connectionTypeName,
      ...options,
    }
  );
}

export default useConnectionType;

import { ShapeProps } from './common';
import CustomPath from './CustomPath';

function Hexagon({ width, height = 50, ...rest }: ShapeProps) {
  const borderRadius = 5;
  const sideLength = width / 2;
  const insideAngle = Math.PI / 3; // 60 degrees in radians

  const midPoint = height / 2;

  const length = borderRadius / Math.tan(insideAngle);

  const x1 = sideLength * Math.cos(insideAngle);
  const y1 = sideLength * Math.sin(insideAngle);

  const x2 = length * Math.cos(insideAngle);
  const y2 = length * Math.sin(insideAngle);

  // M = start, 0 0 => x y
  // L = line, 0 0 => x y
  // A = arc
  // Z = end
  const pathData = `
    M ${x2} ${midPoint + y2}
    L ${x1 - x2} ${midPoint + y1 - y2}
    A ${borderRadius} ${borderRadius} 0 0 0 ${x1 + length} ${midPoint + y1}
    L ${sideLength + x1 - length} ${midPoint + y1} 
    A ${borderRadius} ${borderRadius} 0 0 0 ${x1 + sideLength + x2} ${midPoint + y1 - y2}
    L ${width - x2} ${midPoint + y2}
    A ${borderRadius} ${borderRadius} 0 0 0 ${width - x2} ${midPoint - y2}
    L ${sideLength + x1 + x2} ${midPoint - y1 + y2}
    A ${borderRadius} ${borderRadius} 0 0 0 ${x1 + sideLength - length} ${midPoint - y1}
    L ${x1 + x2} ${midPoint - y1}
    A ${borderRadius} ${borderRadius} 0 0 0 ${x1 - x2} ${midPoint - y1 + y2}
    L ${x2} ${midPoint - y2}
    A ${borderRadius} ${borderRadius} 0 0 0 ${x2} ${midPoint + y2}
    Z
    `;

  return <CustomPath {...{ pathData, ...rest }} />;
}

export default Hexagon;

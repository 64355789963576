import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Progress,
  Tooltip,
} from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import Modal from 'components/Modal';
import useEditConnection from 'hooks/api/useEditConnection';
import useTestConnection from 'hooks/api/useTestConnection';
import useStore from 'state';
import { delay } from 'utils';

import useConnectionType from '../hooks/useConnectionType';

type EditConnectionForm = {
  name: string;
};

type TestingState = 'testing' | 'success' | 'error';

function EditConnectionModal() {
  const [connectionModalProps, setConnectionModalProps] = useStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { connection, error } = connectionModalProps;

  const { data: connectionType, isLoading: isConnectionTypeLoading } = useConnectionType(
    connection?.connection_type
  );

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<EditConnectionForm>({
    defaultValues: {
      name: connection?.name || '',
    },
  });

  const closeModal = useCallback(() => {
    setConnectionModalProps({});
  }, [setConnectionModalProps]);

  const { mutate: editConnection, error: saveError } = useEditConnection({
    onSuccess: closeModal,
  });

  const onSubmit = (formData: EditConnectionForm) => {
    if (!connection) return;
    editConnection({ connectionUid: connection.uid, name: formData.name });
  };

  const [testingState, setTestingState] = useState<{ state: TestingState; error?: string }>({
    state: connection?.failed_msg ? 'error' : 'success',
    error: connection?.failed_msg,
  });

  useEffect(() => {
    if (saveError) {
      setConnectionModalProps({ error: saveError.message });
    }
  }, [saveError, setConnectionModalProps]);

  const { mutate: testConnection } = useTestConnection();

  const test = useCallback(async () => {
    if (!connection) return;
    setTestingState({ state: 'testing' });
    // Artificial delay for UX purposes
    await delay(1000);
    return testConnection(connection.uid, {
      onSuccess: (connectionResponse) => {
        if (connectionResponse.failed_msg) {
          setTestingState({ state: 'error', error: connectionResponse.failed_msg });
        } else {
          setTestingState({ state: 'success' });
        }
      },
      onError: (e) => {
        const msg = e['message'] || 'Something went wrong';
        setTestingState({ state: 'error', error: msg });
        console.error('Failed to test connection', e);
      },
    });
  }, [connection, testConnection]);

  if (!connection) return <></>;

  return (
    <Modal
      isOpen={true}
      onClose={closeModal}
      size={'2xl'}
      title={`Edit ${connection.connection_type_description} Connection`}
      customFooter={
        <Flex justify="flex-end" w="100%" gap={2}>
          <Button
            size="sm"
            variant="outline"
            isLoading={testingState.state === 'testing'}
            onClick={test}
            aria-label="test connection"
          >
            Test
          </Button>
          <Tooltip
            label={
              testingState.error &&
              `Please reconnect to fix the following error: ${testingState.error}`
            }
          >
            <Button
              size="sm"
              variant="outline"
              colorScheme={testingState.state === 'error' ? 'red' : 'blue'}
              // isLoading={testingState.testing}
              onClick={() =>
                setConnectionModalProps({
                  ...connectionModalProps,
                  action: 'reconnect',
                })
              }
              leftIcon={testingState.state === 'error' ? <WarningTwoIcon /> : undefined}
              aria-label="reconnect connection"
            >
              Reconnect
            </Button>
          </Tooltip>
          <Button size="sm" onClick={handleSubmit(onSubmit)} aria-label="update connection">
            Update
          </Button>
        </Flex>
      }
      blockScrollOnMount={false}
    >
      {testingState.state === 'success' && (
        <Alert status="success">
          <AlertIcon />
          <AlertTitle>Authorized</AlertTitle>
          <AlertDescription>Connection is ready to use</AlertDescription>
        </Alert>
      )}

      {testingState.state === 'error' && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{testingState.error}</AlertTitle>
          <AlertDescription>Try reconnecting to resolve the connection issue</AlertDescription>
        </Alert>
      )}

      {testingState.state === 'testing' && (
        <>
          <Alert status="info">
            <AlertIcon />
            <AlertTitle>Connecting to: {connection.connection_type_description}</AlertTitle>
          </Alert>
          <Progress size="xs" isIndeterminate />
        </>
      )}

      <Box py={3}>
        <FormControl key="name" isRequired isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <Input
            size="md"
            placeholder="some_connection_name"
            autoComplete="off"
            autoCorrect="off"
            {...register('name', {
              required: `Names must match a-zA-Z0-9_`,
              pattern: /^[a-zA-Z0-9_]+$/i,
              maxLength: 128,
            })}
          />
          {errors?.name?.type === 'pattern' && (
            <FormErrorMessage>Names can only contain letters and underscores</FormErrorMessage>
          )}
          {errors?.name?.type === 'maxLength' && (
            <FormErrorMessage>Names cannot exceed 128 characters</FormErrorMessage>
          )}
        </FormControl>
      </Box>
    </Modal>
  );
}

export default EditConnectionModal;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { MarketplaceRelatedItems } from 'types/api';
import {
  useMarketplaceAppURL,
  useMarketplaceComponentURL,
  useMarketplaceConnectionURL,
} from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useMarketplaceRelatedItems(
  type: 'apps' | 'connections' | 'components',
  uid?: string,
  options?: UseQueryOptions<MarketplaceRelatedItems>
) {
  const result = useQuery<MarketplaceRelatedItems>(
    ['marketplace', 'related', type, uid],
    async () => {
      let url = '';
      if (type === 'apps') {
        url = useMarketplaceAppURL(uid || '');
      } else if (type === 'components') {
        url = useMarketplaceComponentURL(uid || '');
      } else if (type === 'connections') {
        url = useMarketplaceConnectionURL(uid || '');
      }
      url += '/related';

      const res = await Axios.get<MarketplaceRelatedItems>(url);
      return res.data;
    },
    { ...options, enabled: !!type && !!uid }
  );

  return result;
}

export default useMarketplaceRelatedItems;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Account } from 'types/api';
import { useAccountsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

type ErrorResponse = {
  detail: string;
};

export type AccountError = AxiosError<ErrorResponse>;

// THIS CAN ONLY BE CALLED BY AUTHCONTEXT!!!
// CALLING THIS IN ANOTHER PLACE CAN CAUSE SOME SERIOUS PROBLEMS
// IF YOU WANT TO ACCESS THE ACCOUNT, USE THE AUTHCONTEXT
// const { account } = useAuth();

function useAccount(options?: UseQueryOptions<Account, AccountError>) {
  const url = useAccountsURL();
  const result = useQuery<Account, AccountError>(
    ['account'],
    async () => {
      const res = await Axios.get<Account>(url);
      return res?.data;
    },
    {
      ...options,
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        // don't retry if we received a response status
        return error?.response?.status ? false : true;
      },
    }
  );

  return result;
}

export default useAccount;

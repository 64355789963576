import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CloudStorage, CloudStorageConfig, CloudStorageUpdate } from 'types/api';
import { useStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StorageHierarchyQueryKey, StorageQueryKey, StoragesQueryKey } from 'utils/queryKeys';

export type StorageUpdateProps = {
  storageUid: string;
  update: CloudStorageConfig;
};

function useUpdateStorage() {
  const queryClient = useQueryClient();

  return useMutation<CloudStorage, AxiosError, StorageUpdateProps>(
    async (props: StorageUpdateProps) => {
      const url = useStorageURL(props.storageUid);

      const update: CloudStorageUpdate = {
        storage_config: props.update,
      };

      const res = await Axios.put<CloudStorage>(url, update);
      queryClient.invalidateQueries(StorageQueryKey(props.storageUid));
      queryClient.invalidateQueries(StoragesQueryKey());
      queryClient.invalidateQueries(StorageHierarchyQueryKey());

      return res.data;
    }
  );
}

export default useUpdateStorage;

import { useCallback, useState } from 'react';
import { Controller, ControllerRenderProps, useForm, useWatch } from 'react-hook-form';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import shallow from 'zustand/shallow';

import ModalComponent from 'components/Modal';
import { TForm, TFormInput } from 'components/TForm';
import { isProduction } from 'config';
import useLinkStorage from 'hooks/api/useLinkStorage';
import useOrganization from 'hooks/useOrganization';
import useStore from 'state';
import { AppState } from 'types';
import { CloudStorageLink } from 'types/api';

import { storageTypes } from './DatabaseTypes';

const selector = (state: AppState) => ({
  setModalProps: state.setDatabaseModalProps,
});

export default function LinkDatabaseModal() {
  const { setModalProps } = useStore(selector, shallow);
  const form = useForm();
  const { control, handleSubmit } = form;
  const { mutate } = useLinkStorage();

  const { organization } = useOrganization();
  const typeName = useWatch({ control, name: 'type' });
  const storageType = storageTypes.find((t) => t.name === typeName?.toLowerCase()) || null;
  const [linkError, setLinkError] = useState<string | null>(null);

  const onSubmit = useCallback(
    (data: any) => {
      if (!organization?.uid) {
        return;
      }
      setLinkError(null);
      let linkData: CloudStorageLink = {
        organization_uid: organization.uid,
        name: data.name,
        read_only: data.readOnly,
        storage_config: storageType!.makeConfig(data),
      };
      mutate(linkData, {
        onSuccess: () => {
          setModalProps({ action: 'hidden' });
        },
        onError: (error) => {
          if (error.response?.status == 424) {
            setLinkError(error.message);
          }
        },
      });
    },
    [organization?.uid, storageType, mutate, setModalProps]
  );

  const selectType = (field: ControllerRenderProps<any, 'type'>) => {
    return (
      <Select
        onChange={(e: any) => field.onChange(e.value)}
        name="storage-types"
        options={storageTypes.map((type) => ({ label: type.label, value: type.name }))}
        placeholder="Select a storage type..."
        closeMenuOnSelect={true}
        size="sm"
      />
    );
  };

  const checkboxType = (field: ControllerRenderProps<any, 'readOnly'>) => {
    return (
      <Checkbox onChange={field.onChange} name="readOnly">
        Read-Only
      </Checkbox>
    );
  };

  return (
    <ModalComponent
      size="xl"
      showFooter={false}
      title={'Link Database'}
      onClose={() => setModalProps({ action: 'hidden' })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TFormInput
          name="name"
          maxLength={32}
          form={form}
          pattern={{
            value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
            message: 'The name must only contains letters, numbers, and underscores.',
          }}
        />

        <TForm form={form} name="readOnly" label="Access" isRequired={false}>
          <Controller
            name="readOnly"
            defaultValue={false}
            control={control}
            render={({ field }) => checkboxType(field)}
          />
        </TForm>

        <TForm form={form} name="type" label="Type">
          <Controller
            name="type"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => selectType(field)}
          />
        </TForm>

        {storageType && storageType.tease_production && isProduction && (
          <Box p={4}>
            <Alert status={'info'}>
              <AlertTitle>Coming Soon</AlertTitle>
              <AlertIcon />
              <AlertDescription>
                We're currently putting the final touches on this storage type, and it will be
                available soon!
              </AlertDescription>
            </Alert>
          </Box>
        )}
        {storageType &&
          !(storageType.tease_production && isProduction) &&
          storageType.element(form, 'create')}

        {linkError && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{linkError}</AlertDescription>
          </Alert>
        )}

        <Button
          disabled={storageType != null && storageType.tease_production && isProduction}
          type="submit"
          size="md"
          w="100%"
          mt={4}
          mb={2}
        >
          Link Database
        </Button>
      </form>
    </ModalComponent>
  );
}

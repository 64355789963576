import { MouseEvent, useCallback, useMemo } from 'react';
import shallow from 'zustand/shallow';

import ConfirmationModal from 'components/ConfirmationModal';
import useDeleteVariable from 'hooks/api/useDeleteVariable';
import useStore from 'state';

function DeleteVariableConfirmation() {
  const [secretsToDelete, setSecretsToDelete] = useStore(
    (state) => [state.secretsToDelete, state.setSecretsToDelete],
    shallow
  );

  const { mutate, isLoading } = useDeleteVariable({
    onSuccess: () => {
      setSecretsToDelete([]);
    },
  });

  const name = useMemo(() => {
    if (secretsToDelete.length < 0) return '';
    const [name] = secretsToDelete;
    return name;
  }, [secretsToDelete]);

  const onDelete = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      if (!name) return;
      mutate(name);
    },
    [mutate, name]
  );

  return (
    <ConfirmationModal
      isOpen={secretsToDelete.length > 0}
      onClose={() => setSecretsToDelete([])}
      title={`Delete Secret: ${name}`}
      body="Are you sure? You can't undo this action."
      isLoading={isLoading}
      onConfirm={onDelete}
      confirmButtonProps={{
        'data-testid': 'button-confirm-delete-variable',
      }}
    />
  );
}

export default DeleteVariableConfirmation;

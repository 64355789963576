import { ChakraTheme, extendTheme, ThemeComponentProps, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { colors, customColorNames } from 'styles/colors';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      'body, html, #__next': {
        height: '100%',
      },
      'a': {
        cursor: 'pointer',
        color: 'link',
      },
      'a:focus': {
        outline: 'none',
      },
      'a:hover': {
        color: 'linkHover',
      },
      'a:active': {
        color: 'linkPressed',
      },
      'input': {
        fontSize: '13px',
      },
      'div.spaces-resize-handle': {
        'zIndex': 1000,
        '&:hover': {
          background: 'blue.50',
        },
      },
      // @TODO: check why this color is not set by the editor
      '.suggest-widget': {
        background: 'white',
      },
      ':root': customColorNames.reduce((res, colorName) => {
        // @ts-ignore
        res[`--${colorName}`] = mode(colors.light[colorName], colors.dark[colorName])(props);

        return res;
      }, {}),
    }),
  },
  colors,
  fontSizes: {
    'xs': '0.75rem',
    'sm': '0.855rem',
    'md': '1rem',
    'xl': '1.5rem',
    '4xl': '2rem',
  },
  textStyles: {
    p1: {
      fontSize: 'md',
    },
    p2: {
      fontSize: 'sm',
    },
    label: {
      fontSize: 'xs',
    },
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontWeight: '600',
        fontSize: 'sm',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 500,
        borderRadius: 3,
      },
      defaultProps: {
        size: 'xs',
        colorScheme: 'blue',
      },
      variants: {
        solid: (props: ThemeComponentProps<ChakraTheme>) => {
          if (props.colorScheme) {
            return {
              color: 'white',
              bg: `${props.colorScheme}.600`,
              _hover: {
                bg: `${props.colorScheme}.500`,
              },
              _active: {
                bg: `${props.colorScheme}.800`,
              },
            };
          }
          return {};
        },
        ghost: (props: ThemeComponentProps<ChakraTheme>) => {
          if (props.colorScheme === 'blue') {
            return {
              color: 'blue.600',
              bg: 'transparent',
              _hover: {
                bg: 'blue.100',
              },
              _active: {
                bg: 'blue.200',
              },
            };
          }

          return {};
        },
        medium: (props: ThemeComponentProps<ChakraTheme>) => {
          return {
            color: 'white',
            bg: `${props.colorScheme}.600`,
            ...theme.components.Button.sizes.md,

            fontWeight: 'normal',
            fontSize: 'sm',
          };
        },
      },
    },
    Input: {
      defaultProps: {
        size: 'sm',
      },
      sizes: {
        sm: {
          field: {
            borderRadius: 4,
            px: 4,
            py: 4,
          },
        },
      },
    },
    Code: {
      variants: {
        subtle: (props: ThemeComponentProps<ChakraTheme>) => {
          return {
            bg: `${props.colorMode}.editorBg`,
            color: `${props.colorMode}.text3`,
          };
        },
      },
      baseStyle: {
        overscrollBehavior: 'contain',
      },
    },
    Menu: {
      baseStyle: {
        menu: {},
        list: { padding: 0 },
        item: {},
      },
    },
    Link: {
      baseStyle: {
        color: 'link',
        _hover: {
          color: 'linkHover',
        },
        _active: {
          color: 'linkPressed',
        },
      },
    },
  },
});

export default theme;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';

import usePreviousGraphVersion from 'hooks/usePreviousGraphVersion';
import { GraphState } from 'types';
import { Graph } from 'types/api';
import { getLatestGraphVersion, getSpecificGraphVersion } from 'utils';
import { GraphQueryKey } from 'utils/queryKeys';
import useStore from 'views/Graph/state';

const selector = (s: GraphState) => ({ readOnly: s.readOnly, setReadOnly: s.setReadOnly });

function useGraph<T = Graph>(options: UseQueryOptions<Graph, unknown, T> = {}) {
  const router = useRouter();
  const graphUID = router.query.id as string;

  const { readOnly, setReadOnly } = useStore(selector, shallow);

  const previousGraphVersion = usePreviousGraphVersion();

  const graphEnabled = !!graphUID && router.pathname.startsWith('/graph');

  return useQuery<Graph, unknown, T>(
    GraphQueryKey(graphUID, previousGraphVersion),
    async () => {
      let graph = await getLatestGraphVersion(graphUID);
      // manually set this in case we override it for previous graph versions
      const latestVersionUID = graph.version_uid;

      if (previousGraphVersion) {
        graph = await getSpecificGraphVersion(previousGraphVersion);
        graph.latest_graph_version_uid = latestVersionUID;
      }

      const newReadOnly = previousGraphVersion ? true : graph?.read_only || false;
      if (newReadOnly !== readOnly) {
        setReadOnly(newReadOnly);
      }

      return graph;
    },
    {
      enabled: graphEnabled,
      retry: (failureCount, error: any) => {
        // don't retry on 404 or 403
        return error?.response?.status === 404 || error?.response?.status === 403 ? false : true;
      },
      staleTime: 60 * 1000,
      keepPreviousData: true,
      ...options,
    }
  );
}

export function useGraphUid(): string {
  const { data: graphUid } = useGraph({
    select: (graph) => graph.uid,
  });
  return graphUid!;
}

export function useGraphAddedNodeId(): string | undefined {
  const { data: addedNodeId } = useGraph({
    select: (graph) => graph.added_node_id,
  });
  return addedNodeId;
}

export function useParameter(nodeId: string, parameterName: string) {
  const { data: parameter } = useGraph({
    select: (graph) => {
      const node = graph.manifest.nodes.find((n) => n.id === nodeId);
      if (!node) return;
      const inSubNode = !!node.parent_node_id;
      const parameters = inSubNode ? node.resolved_parameter_values : node.parameter_values;
      if (!parameters) return;
      return parameters[parameterName];
    },
  });
  return parameter;
}

export default useGraph;

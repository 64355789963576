import useGraph from 'hooks/api/useGraph';

///////////////////////////
// Account Endpoints
export function useAccountsURL(): string {
  return '/accounts/me';
}

export function useCheckUsernameAvailableURL(): string {
  return `/accounts/check_available_name`;
}

export function useSetupAccountURL(): string {
  return `/accounts/setup`;
}

export function useResendVerificationEmailURL(): string {
  return '/accounts/resend_verification_email';
}

///////////////////////////
// Organization Endpoints
export function useConnectionsURL(organizationUID: string | null): string {
  return `/organizations/${organizationUID}/connections`;
}

export function useConnectionTypeURL(
  organizationUID: string | null,
  connectionTypeName?: string
): string {
  return `/organizations/${organizationUID}/connections/types/${connectionTypeName}`;
}

export function useConnectionTypesURL(organizationUID: string | null): string {
  return `/organizations/${organizationUID}/connections/types`;
}

export function useGetNewOrganizationURL(sessionID: string | null): string {
  return `/organizations/billing/session/${sessionID}`;
}

export function useOrganizationURL(): string {
  return `/organizations`;
}

export function useCheckOrgSlugAvailableURL(): string {
  return `/organizations/check_available_name`;
}

export function useCreateOrganizationURL(): string {
  return `/organizations/billing`;
}

export function useOrganizationInvitationsURL(organizationUID: string | null): string {
  return `/organizations/${organizationUID}/invitations`;
}

export function useVariablesURL(organizationUID?: string | null): string {
  return `/organizations/${organizationUID}/variables`;
}

export function useOrganizationUsageLimitURL(organizationUID: string): string {
  return `/organizations/${organizationUID}/usage`;
}

export function useOrganizationMembersURL(organizationUID: string | null): string {
  return `/organizations/${organizationUID}/members`;
}

export function useGraphsURL(organizationUID: string | null): string {
  return `/organizations/${organizationUID}/graphs`;
}

export function useGraphsThumbnailsURL(organizationUID: string | null): string {
  return `/organizations/${organizationUID}/graphs/thumbnail`;
}

export function useStripeBillingPortalURL(organizationUID?: string | null): string {
  return `/organizations/${organizationUID}/billing/portal`;
}

export function useVariableURL(organizationUID: string | null, name: string): string {
  return `/organizations/${organizationUID}/variables/${name}`;
}

export function useAlertsURL(organizationUID?: string | null): string {
  return `/organizations/${organizationUID}/alerts`;
}

export function useRemoveOrganizationMemberURL(
  organizationUID: string | null,
  uid: string
): string {
  return `/organizations/${organizationUID}/members/${uid}`;
}

///////////////////////////
// Graph Endpoints
export function useCreateGraphsURL(): string {
  return '/graphs';
}

export function useGraphURL(graphUID: string): string {
  return `/graphs/${graphUID}`;
}

export function useDuplicateGraphURL(): string {
  return `/graphs/duplicate`;
}

export function useGraphVersionsURL(graphUID: string): string {
  return `/graphs/${graphUID}/graph_versions`;
}

export function useSetLatestGraphVersionURL(graphUID?: string): string {
  return `/graphs/${graphUID}/set_version`;
}

export function useUpdateGraphThumbnailURL(graphUID?: string | null): string {
  return `/graphs/${graphUID}/thumbnail`;
}

export function useCancelAllExecutionsURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/executions/cancel` : null;
}

export function useGraphStatusURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/status` : null;
}

export function useGraphThumbnailURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/thumbnail` : null;
}

export function useGraphWebSocketURL(): string | null {
  const protocol = (window?.location?.protocol === 'https:' ? 'wss' : 'ws') + '://';
  const WebsocketPrefix = 'api/ws';
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });

  function removeHttp(url: string) {
    return url.replace(/^https?:\/\//, '');
  }

  const WebSocketURL = protocol + removeHttp(process.env.NEXT_PUBLIC_API_URL + WebsocketPrefix);

  return `${WebSocketURL}/graphs/${graphUID}`;
}

export function useNodeExecutionsURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/executions` : null;
}

export function useNodeExecutionsPlanURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/executions/plan` : null;
}

export function useNodeRunEventsURL(nodeID?: string): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID && nodeID ? `/graphs/${graphUID}/nodes/${nodeID}/events` : null;
}

export function useNodeStateURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/state` : null;
}

export function useResetNodeStateURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/state/reset` : null;
}

export function useResetStoreNodeDataURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/store/reset` : null;
}

export function useStoreMetadataURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/store/metadata` : null;
}

export function useStoreNodeDataURL(graphUID?: string | null): string | null {
  return graphUID ? `/graphs/${graphUID}/store/data` : null;
}

export function useUpdateGraphPublicURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/public` : null;
}

export function useToggleGraphTriggersURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/triggers/enable` : null;
}

export function useTriggerGraphURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/triggers` : null;
}

export function useWebhooksURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/webhooks` : null;
}

export function useLatestExecutionURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/latest_execution?node_id=` : null;
}

///////////////////////////
// Graph Version Endpoints
export function useAddFileURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/add_file`;
}

export function useAddNodeDescriptionURL(graphVersionUID: string | null, nodeID: string): string {
  return `/graph_versions/${graphVersionUID}/node/${nodeID}/description`;
}

export function useAddNodeFromComponentURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/add_node_from_component`;
}

export function useAddReadOnlyTableURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/read_only_table`;
}

export function useAddStoreNodeURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/store`;
}

export function useAddWebhookURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/webhook_node`;
}

export function useAssignPortURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/set_edge`;
}

export function useDeleteFileURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/delete_file`;
}

export function useDeleteNodesURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/delete_nodes`;
}

export function useAutoLayoutNodesURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/layout`;
}

export function useDuplicateNodeURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/duplicate_node`;
}

export function useGraphPreviousVersionURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}`;
}

export function useGraphVersionZipURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/zip`;
}

export function useMovefileURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/move_file`;
}

export function useUpdateFileURL(versionUID?: string | null): string {
  return `/graph_versions/${versionUID}/edit_file`;
}

export function useUpdateNodesURL(graphVersionUID?: string | null): string {
  return `/graph_versions/${graphVersionUID}/nodes`;
}

export function useGenerateNodeTextURL(graphVersionUID?: string | null): string {
  return `/ai/completions/graph_versions/${graphVersionUID}`;
}

///////////////////////////
// Dashboard Endpoints
export function useCreateDashboardURL(versionUID: string | null): string {
  return `/graph_versions/${versionUID}/dashboards`;
}

export function useDashboardURL(versionUID: string | null, dashboardId: string | null): string {
  return `/graph_versions/${versionUID}/dashboards/${dashboardId}`;
}

export function useCreateDashboardItemURL(
  versionUID: string | null,
  dashboardId: string | null
): string {
  return `/graph_versions/${versionUID}/dashboards/${dashboardId}/items`;
}

export function useDashboardItemURL(
  versionUID: string | null,
  dashboardId: string | null,
  itemID: string | null
): string {
  return `/graph_versions/${versionUID}/dashboards/${dashboardId}/items/${itemID}`;
}

///////////////////////////
// Marketplace Endpoints
export function useComponentURL(componentVersionString?: string): string {
  if (!componentVersionString) return '';
  const org = componentVersionString.split('/')[0];
  const component = componentVersionString.split('/')[1].split('@')[0];
  const version = componentVersionString.split('@')[1];
  return `/components/versions?organization=${org}&component=${component}&version=${version}`;
}

export function useMarketplaceComponentURL(uid: string): string {
  return `/marketplace/components/${uid}`;
}

export function useMarketplaceAppURL(uid: string): string {
  return `/marketplace/apps/${uid}`;
}
export function useMarketplaceConnectionURL(uid: string): string {
  return `/marketplace/connections/${uid}`;
}

export function useMarketplaceAppsByCategoryUrl(): string {
  return `/marketplace/templates`;
}

export function useMarketplaceSearchAppsUrl(): string {
  return `/marketplace/apps`;
}

export function useMarketplaceSearchComponentsUrl(): string {
  return `/marketplace/components`;
}

export function useMarketplaceSearchConnectionsUrl(): string {
  return `/marketplace/connections`;
}

///////////////////////////
// Execution Endpoints
export function useExecutionGraphVersionURL(executionUID?: string | null): string {
  return `/executions/${executionUID}/graph_version`;
}

export function useExecutionRunEventsURL(executionUID?: string): string {
  return `/executions/${executionUID}/events`;
}

export function useCancelExecutionURL(executionUID: string): string {
  return `/executions/${executionUID}/cancel`;
}

///////////////////////////
// Connection Endpoints
export function useConnectionReconnectURL(): string {
  return '/connections/reconnect';
}

export function useConnectionCompleteURL(): string {
  return '/connections/complete';
}

export function useConnectionURL(connectionUid: string): string {
  return `/connections/${connectionUid}`;
}

export function useTestConnectionURL(connectionUid: string): string {
  return `/connections/${connectionUid}/test`;
}

///////////////////////////
// Invitation Endpoints
export function useJoinOrganizationURL(inviteUID: string): string {
  return `/invitations/${inviteUID}/join`;
}

export function useOrganizationInviteURL(uid: string | null): string {
  return `/invitations/${uid}`;
}

export function useResendOrganizationInviteURL(uid: string): string {
  return `/invitations/${uid}/resend`;
}

///////////////////////////
// Storage Endpoints
export function useStorageURL(storageUid: string): string {
  return `/storage/${storageUid}`;
}

export function useStorageScanURL(storageUid: string): string {
  return `/storage/${storageUid}/scan`;
}

export function useLinkStorageURL(): string {
  return `/storage`;
}

export function useStoragesURL(organizationUID?: string | null): string {
  return `/storage?organization_uid=${organizationUID}`;
}

///////////////////////////
// Storage Hierarchy Endpoints

export function useStorageHierarchyURL(): string | null {
  const { data: graphUID } = useGraph({
    select: (data) => data.uid,
  });
  return graphUID ? `/graphs/${graphUID}/storage_hierarchy` : null;
}

export function useDefaultStorageURL(graphUID?: string): string | null {
  return graphUID ? `/graphs/${graphUID}/storage` : null;
}

///////////////////////////
// Misc Endpoints
export function useSlackConnectURL(): string {
  return 'https://api-production.patterns.app/api/app/webhooks/utei8rq8mc8ljtnvcpbc';
}

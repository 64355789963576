import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';

import { AuthFormDef } from 'views/Connections/Extra';

function ShopifyAuthFormElement({
  register,
  formState: { errors },
}: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure your Shopify Account</Heading>
      <Box mt={5}>
        Patterns needs additional information to connect to your Shopify account. Please enter your
        Shopify shop name as you would use in the URL below:
      </Box>

      <FormControl isInvalid={!!errors?.shopName}>
        <Flex justify="center" alignItems="center" mt={5}>
          <InputGroup>
            <InputLeftAddon>https://</InputLeftAddon>
            <Input
              {...register('shopName', {
                required: true,
              })}
              placeholder="my-shop-name"
              width="20em"
              type="text"
            />
            <InputRightAddon>.myshopify.com</InputRightAddon>
          </InputGroup>
        </Flex>
        <ErrorMessage errors={errors} name="shopName" as={FormErrorMessage} />
      </FormControl>
    </>
  );
}

const ShopifyAuth: AuthFormDef = {
  connectionType: 'shopify',
  element: ShopifyAuthFormElement,
};

export default ShopifyAuth;

import { ParsedUrlQuery } from 'querystring';

import { EdgePath, PortType, VegaTheme } from 'types';

export type Paginated<T> = {
  count: number | null;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type Account = {
  uid: string;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  organizations: { [uid: string]: Organization };
};

export type CloudStorageLite = {
  name: string;
  created_at: string;
  uid: string;
  type: string;
  status: string;
  managed: boolean;
  read_only: boolean;
  fail_error: string | null;
  scan_requested_at?: string | null;
  last_scan_error?: string | null;
  last_scan_at?: string | null;
  scan_started_at?: string | null;
};

export type CloudStorage = CloudStorageLite & {
  config: CloudStorageConfig;
  table_count: number;
};

export type CloudStorageListItemPaginated = Paginated<CloudStorageLite>;

export type StorageHierarchy = {
  roots: StorageHierarchyNode[];
  storages: CloudStorageLite[];
};

export type StorageHierarchyNodeType =
  | 'storage'
  | 'namespace'
  | 'managed_node'
  | 'managed_table'
  | 'unmanaged_table';

export type StorageHierarchyNode = {
  name: string;
  type: StorageHierarchyNodeType;
  children: StorageHierarchyNode[];
  error?: string;
  in_graph_as_node_id?: string;
  table_path?: string[];
};

export type SqlCloudStorageConfig = {
  host: string;
  port: number;
  username: string;
  password?: string;
  database: string;
};

export type PostgresCloudStorageConfig = {
  type: 'postgres';
} & SqlCloudStorageConfig;

export type MysqlCloudStorageConfig = {
  type: 'mysql';
} & SqlCloudStorageConfig;

export type BigQueryCloudStorageConfig = {
  type: 'bigquery';
  project_name: string;
  dataset_name: string;
  private_key_json?: string;
};

export type CloudStorageConfig =
  | PostgresCloudStorageConfig
  | MysqlCloudStorageConfig
  | BigQueryCloudStorageConfig;

export type CloudStorageLink = {
  organization_uid: string;
  name: string;
  read_only: boolean;
  storage_config: CloudStorageConfig;
};

export type CloudStorageUpdate = {
  storage_config: CloudStorageConfig;
};

export type ConnectionEditData = {
  name: string | null;
  connectionUid: string;
};

export type Connection = {
  uid: string;
  name: string;
  connection_type: string;
  connection_type_description: string;
  subject_email: string;
  created_at: string;
  updated_at: string;
  failed_msg?: string;
  failed_at?: string;
};

export type ConnectionPaginated = Paginated<Connection>;

export type ConnectionTypeAuthMechanism = 'creds' | 'oauth';

export type ConnectionType = {
  connection_type: string;
  description: string;
  auth_mechanism: ConnectionTypeAuthMechanism;
  auth_url?: string;
};

export type ConnectionTypePaginated = Paginated<ConnectionType>;

export type ConnectionPopupMessageResult = {
  messageType: 'connectionPopupMessage';
  type: 'result';
  query: ParsedUrlQuery;
};

export type ConnectionPopupMessageError = {
  messageType: 'connectionPopupMessage';
  type: 'error';
  error: string;
};

export type ConnectionPopupMessage = ConnectionPopupMessageResult | ConnectionPopupMessageError;

export type ConnectionPopupSuccess = { type: 'success'; code: string; query: ParsedUrlQuery };
export type ConnectionPopupFailure = { type: 'failure'; message: string };

export type ConnectionPopupResult = ConnectionPopupSuccess | ConnectionPopupFailure;

export type BillingPlanType = 'personal' | 'free' | 'pro' | 'enterprise';
export type Organization = {
  uid: string;
  slug: string;
  description: null | string;
  billing_plan: BillingPlanType;
};

export type OrganizationMember = {
  uid: string;
  username: string;
  first_name: string | null;
  last_name: string | null;
  email: string;
  is_owner: boolean;
};

export type OrganizationMemberPaginated = Paginated<OrganizationMember>;

export type OrganizationInvitation = {
  uid: string;
  email: string;
  created: string;
  inviter: OrganizationMember;
  organization: Organization;
};

export type OrganizationInvitationPaginated = Paginated<OrganizationInvitation>;

export type OrganizationGraph = {
  uid: string;
  slug: string;
  title: null | string;
  organization_uid: string;
  updated_at: string;
  enabled: boolean;
  theme: null | string;
  manifest: GraphManifest;
};

export type OrganizationGraphsPaginated = Paginated<OrganizationGraph>;

export type OrganizationStorageUsageLimit = {
  name: string;
  uid: string;
  usage_current: number;
  usage_limit: number;
};

export type OrganizationUsageLimit = {
  total_executions_limit: number;
  total_executions_current: number;
  concurrent_executions_limit: number;
  concurrent_executions_current: number;
  storages: OrganizationStorageUsageLimit[];
};

export type Alert = {
  uid: string;
  priority: number;
  title: string;
  description: string;
  fix_url: string;
  since?: string;
};

export type Alerts = {
  alerts: Alert[];
};

// only used when listing versions for a graph
export type GraphVersion = {
  uid: string;
  title: null | string;
  created_at: string;
};

export type GraphVersionsPaginated = Paginated<GraphVersion>;

export type Thumbnail = {
  uid: string;
  url?: string | null;
};

export type GraphsThumbnailsPaginated = Paginated<Thumbnail>;

export type InputDefinition = {
  port_type: PortType;
  description?: string | null;
  schema_name?: string | null;
  required?: boolean;
  line_number?: number;
};

export type OutputDefinition = {
  port_type: PortType;
  description?: string | null;
  schema_name?: string | null;
  required?: boolean;
  line_number?: number;
  synthetic?: boolean;
};

export enum ParameterType {
  Text = 'text',
  Boolean = 'bool',
  Integer = 'int',
  Float = 'float',
  Date = 'date',
  DateTime = 'datetime',
  List = 'list',
  Schema = 'schema',
  Connection = 'connection',
}

export type ParameterDefinition = {
  parameter_type: ParameterType;
  description?: string;
  default?: any;
  required?: boolean;
  line_number?: number;
  connection_type?: string; // iff parameter_type is connection
};

export type ManifestNodeInterface = {
  inputs?: Record<string, InputDefinition>;
  outputs?: Record<string, OutputDefinition>;
  parameters?: Record<string, ParameterDefinition>;
  state?: Record<string, any> | null;
};

export type ComponentReferenceNodeType = 'component_ref';
export type SubgraphNodeType = 'graph';
export type MarkdownNodeType = 'markdown';
export type ChartNodeType = 'chart';
export type TableNodeType = 'table_store';
export type StreamNodeType = 'stream_store';
export type StoreNodeType = TableNodeType | StreamNodeType;
export type PythonNodeType = 'python';
export type SQLNodeType = 'sql';
export type FunctionNodeType =
  | PythonNodeType
  | SQLNodeType
  | ChartNodeType
  | 'webhook'
  | MarkdownNodeType;

export type NodeType =
  | StoreNodeType
  | FunctionNodeType
  | SubgraphNodeType
  | ComponentReferenceNodeType
  | 'loading';

export type Trigger = {
  type: string;
  value: string;
};

export type NodeDisplay = {
  x: number;
  y: number;
  width: number;
  height: number;
  color?: string;
  style?: string;
};

export type ManifestNode = {
  id?: string;
  description_path?: string | null;
  icon_url?: string | null;
  errors?: ManifestError[];
  exposing_declaration?: string | null;
  file_path_to_node_script_relative_to_root?: string | null;
  file_path?: string | null;
  from_component?: string;
  group?: string | null;
  interface?: ManifestNodeInterface;
  listed_id: string;
  local_output_edges?: Record<string, EdgePath[]>;
  node_type: NodeType;
  parent_node_id?: string | null;
  port_connections?: {
    inputs?: Record<string, string>;
    outputs?: Record<string, string>;
  };
  parameter_values?: Record<string, any>;
  resolved_output_edges?: Record<string, EdgePath[]>;
  resolved_parameter_values?: Record<string, any>;
  resolved_storage?: {
    definition: string;
    engines: string[];
    namespace_path: string[];
  };
  resolved_schemas?: Record<string, any>;
  storage_name?: string;
  title: string;
  triggers?: Trigger[];
  trigger?: string | null;
  display?: NodeDisplay;
  wait_for_response?: boolean;
};

export type ManifestError = {
  message: string;
  source:
    | 'parameter'
    | 'trigger'
    | 'input'
    | 'output'
    | 'schema'
    | 'syntax'
    | 'component'
    | 'storage'
    | 'other';
  source_name?: string;
  lineno?: number;
};

export type DashboardItemType = 'chart' | 'markdown' | 'data' | 'form';

export type DashboardItemPosition = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type DashboardItem = {
  type: DashboardItemType;
  node: string;
  position: DashboardItemPosition;
};

export type Dashboard = {
  title: string;
  items_by_id: Record<string, DashboardItem>;
};

export type GraphManifest = {
  nodes_by_id?: Record<string, ManifestNode>;
  dashboards_by_id?: Record<string, Dashboard>;

  nodes: ManifestNode[];
  manifest_version: number;
  graph_title: string;
  tags?: string[];
  versions?: string[];
  description_path?: string | null;
  resolved_schemas?: Record<string, string>;
  errors?: ManifestError[];
};

export type Graph = {
  uid: string;
  slug: string;
  version_uid: string;
  // only used when viewing old graph version
  latest_graph_version_uid: string | null;
  title: null | string;
  created_at: string;
  updated_at: string;
  organization_uid: string;
  organization_slug: string;
  read_only: boolean;
  public: boolean;
  enabled: boolean;
  theme?: VegaTheme | null;
  added_node_id?: string;

  manifest: GraphManifest;
  zipped_graph: string | null;
  graph_files: {
    [key: string]: string;
  };
};

export type GraphTemplateCategory = {
  title: string;
  index: number | null;
  is_starter: boolean;
  templates: MarketplaceAppTemplate[];
};

export type GraphTemplates = {
  categories: GraphTemplateCategory[];
};

export type NodeExecution = {
  uid: string;
  node_id: string;
  triggered: null | string;
  started: null | string;
  completed: null | string;
  killed: null | string;
  heartbeat: null | string;
  cancelled: null | string;
  captured_stdout: null | string;
  error_message: null | string;
  error_traceback: null | string;
  created_at: null | string;
};

export type NodeExecutionPaginated = Paginated<NodeExecution>;

export type NodeExecutionPlan = {
  nodes_to_execute: string[];
  root_nodes: string[];
};

export type GraphStatus = {
  executions: Record<string, NodeExecution>;
  run_events_updated: Record<string, string>;
  data_updated: Record<string, string>;
  state_updated: Record<string, string>;
};

export type MarketplaceAppTemplate = {
  uid: string;
  graph_uid: string;
  title: string;
  slug: string;
  tags: string[];
  short_description: string | null;
  description: string | null;
  organization_uid: string;
  organization_slug: string;
  version: string;
  manifest: GraphManifest;
  index: number | null;
};

export type MarketplaceComponent = {
  uid: string;
  title: string;
  tags: string[];
  description: string;
  organization_uid: string;
  organization_slug: string;
  icon_url?: string | null;
  version: string;
  graph_uid: string;
  graph_version_uid: string;
};

export type MarketplaceConnection = {
  title: string;
  description: string;
};

export type MarketplaceAppTemplatePaginated = Paginated<MarketplaceAppTemplate>;
export type MarketplaceComponentPaginated = Paginated<MarketplaceComponent>;
export type MarketplaceConnectionPaginated = Paginated<MarketplaceConnection>;

export type MarketplaceRelatedItems = {
  connections: MarketplaceConnectionPaginated;
  components: MarketplaceComponentPaginated;
  apps: MarketplaceAppTemplatePaginated;
};

export type RunEvent = {
  category: string;
  client_event_uid: string;
  message: string;
  node_id: string;
  run_uid: string;
  severity: string;
  timestamp: string;
};

export type RunEventPaginated = Paginated<RunEvent>;

export type Webhook = {
  node_id: string;
  webhook_url: string;
  last_ran_at?: string;
  num_run: number;
};

export type WebhookPaginated = Paginated<Webhook>;

export type VariableResponse = {
  count: number;
  results: Variable[];
};

export type Variable = {
  uid: string;
  name: string;
  value: string;
  description: string;
  created_at: string;
  updated_at: string;
  secret: boolean;
  organization_uid: string;
};

export type VariableData = {
  uid?: string;
  organization_uid?: string;
  created_at?: string;
  name: string;
  value: string;
  secret: boolean;
  description: string;
};

export type SchemaDict = {
  description: string;
  field_roles: Record<string, string[]>;
  fields: SchemaDictField[];
};

export type FieldType = {
  name: string;
  parameters: object;
};

export type SchemaDictField = {
  name: string;
  field_type: string | FieldType;
  nullable: boolean;
  json_schema?: SchemaDict & {
    immutable: boolean;
    name: string;
    unique_on: string[];
  };
};

export type StoreMetadata = {
  persisted_schema: {
    name: string;
    owner: {
      uid: string;
      slug: string;
      description: string | null;
    };
    schema_dict: SchemaDict;
  };
  rows: number;
  columns: number;
};

export type StoreData = Paginated<any[]>;

export type NodeState = {
  state: Record<string, any>;
};

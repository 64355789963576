import { HStack, useRadioGroup } from '@chakra-ui/react';

import RadioCard, { RadioCardProps, RadioCardValue } from 'components/RadioCard';

type RadioCardGroupProps = {
  name: string;
  options: RadioCardProps[];
  defaultValue?: RadioCardValue;
  onChange?: (value: RadioCardValue) => void;
};

const RadioCardGroup = ({ name, options, defaultValue, onChange }: RadioCardGroupProps) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map(({ value, children }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} value={value} {...radio}>
            {children}
          </RadioCard>
        );
      })}
    </HStack>
  );
};

export default RadioCardGroup;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Code, FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function StripeAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Stripe Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="API Key" form={form}>
          <FormHelperText>
            The API key can be found in your Stripe{' '}
            <a rel="noreferrer" target="_blank" href="https://dashboard.stripe.com/account/apikeys">
              Account
            </a>
          </FormHelperText>
          <FormHelperText>
            Note: Your API Key must have read access to the following scopes:
            <Code>charges, invoices, subscriptions</Code>
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const StripeAuth: AuthFormDef = {
  connectionType: 'stripe',
  element: StripeAuthFormElement,
};

export default StripeAuth;

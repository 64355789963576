import { IconType } from 'react-icons';
import { DiGoogleCloudPlatform } from 'react-icons/di';
import { SiMysql, SiPostgresql } from 'react-icons/si';
import { Edge } from 'reactflow';

import { AccumulatorNode, GraphNode, NodeData, NodeStatus, NodeTab } from 'types';
import {
  ChartNodeType,
  FunctionNodeType,
  ManifestNode,
  MarkdownNodeType,
  NodeExecution,
  NodeType,
  PythonNodeType,
  SQLNodeType,
  StoreNodeType,
  StreamNodeType,
  SubgraphNodeType,
  TableNodeType,
} from 'types/api';
import DarkTextWrapper from 'views/Graph/modules/GraphView/NodeTypes/components/DarkTextWrapper';

import ChartIcon from '../../public/node-chart-icon.svg';
import NodeIcon from '../../public/node-icon.svg';
import StreamIcon from '../../public/node-stream-icon.svg';
import SubgraphIcon from '../../public/node-subgraph-icon.svg';
import TableIcon from '../../public/node-table-icon.svg';
import WebhookIcon from '../../public/node-webhook-icon.svg';
import WebhookResponseIcon from '../../public/node-webhook-response-icon.svg';

const PythonIcon = () => {
  return <DarkTextWrapper>PY</DarkTextWrapper>;
};

const SQLIcon = () => {
  return <DarkTextWrapper>SQL</DarkTextWrapper>;
};

const MarkdownIcon = () => {
  return <DarkTextWrapper>M↓</DarkTextWrapper>;
};

type PartialNodeData = Pick<NodeData, 'type' | 'resolvedStorage'> & {
  wait_for_response?: boolean;
};

function getTableIcon(nodeData: PartialNodeData): IconType {
  if (nodeData.resolvedStorage) {
    const engine =
      nodeData.resolvedStorage.engines && nodeData.resolvedStorage.engines.length > 0
        ? nodeData.resolvedStorage.engines[0]
        : undefined;
    if (engine === 'postgres') {
      return SiPostgresql;
    } else if (engine === 'mysql') {
      return SiMysql;
    } else if (engine === 'bigquery') {
      return DiGoogleCloudPlatform;
    } else {
      return TableIcon;
    }
  } else {
    return TableIcon;
  }
}

export function getIconForNodeType(nodeData: PartialNodeData): IconType {
  switch (nodeData.type) {
    case 'python':
      return PythonIcon;
    case 'sql':
      return SQLIcon;
    case 'graph':
    case 'component_ref':
      return SubgraphIcon;
    case 'table_store':
      return getTableIcon(nodeData);
    case 'stream_store':
      return StreamIcon;
    case 'chart':
      return ChartIcon;
    case 'webhook':
      return nodeData.wait_for_response ? WebhookResponseIcon : WebhookIcon;
    case 'markdown':
      return MarkdownIcon;
    default:
      return NodeIcon;
  }
}

export function isTableNodeType(nodeType?: NodeType | null): nodeType is TableNodeType {
  return nodeType === 'table_store';
}

export function isStreamNodeType(nodeType?: NodeType | null): nodeType is StreamNodeType {
  return nodeType === 'stream_store';
}

export function isStoreNodeType(nodeType?: NodeType | null): nodeType is StoreNodeType {
  return isTableNodeType(nodeType) || isStreamNodeType(nodeType);
}

export function isPythonNodeType(nodeType?: NodeType | null): nodeType is PythonNodeType {
  return nodeType === 'python';
}

export function isSQLNodeType(nodeType?: NodeType | null): nodeType is SQLNodeType {
  return nodeType === 'sql';
}

export function isFunctionNodeType(nodeType?: NodeType | null): nodeType is FunctionNodeType {
  return isPythonNodeType(nodeType) || isSQLNodeType(nodeType);
}

export function isChartNodeType(nodeType?: NodeType | null): nodeType is ChartNodeType {
  return nodeType === 'chart';
}

export function isMarkdownNodeType(nodeType?: NodeType | null): nodeType is MarkdownNodeType {
  return nodeType === 'markdown';
}

export function isSubgraphNodeType(nodeType?: NodeType | null): nodeType is SubgraphNodeType {
  return nodeType === 'graph';
}

export function isComponentNodeType(nodeType?: NodeType | null): nodeType is SubgraphNodeType {
  return nodeType === 'component_ref';
}

export function isWebhookNodeType(nodeType?: NodeType | null): nodeType is FunctionNodeType {
  return nodeType === 'webhook';
}

export function isExecutableNodeType(nodeType?: NodeType | null) {
  return (
    isFunctionNodeType(nodeType) || isComponentNodeType(nodeType) || isSubgraphNodeType(nodeType)
  );
}

export const isLatest = (a: string | null | undefined, b: string | null | undefined) => {
  if (!a) return false;
  if (!b) return true;

  return new Date(a) > new Date(b);
};

export const isNodeRunning = (execution: NodeExecution): boolean => {
  if (execution.cancelled !== null || execution.completed !== null || execution.killed !== null)
    return false;
  return execution.started !== null;
};

export const isNodeQueued = (execution: NodeExecution): boolean => {
  if (
    execution.cancelled !== null ||
    execution.completed !== null ||
    execution.killed !== null ||
    execution.started !== null
  )
    return false;
  return true;
};

export const isNodeSuccess = (execution: NodeExecution): boolean => {
  if (execution.completed === null) return false;
  if (execution.error_message) return false;
  if (isLatest(execution.cancelled, execution.completed)) return false;
  if (isLatest(execution.killed, execution.completed)) return false;
  return true;
};

export const isNodeError = (execution: NodeExecution): boolean => {
  return execution.error_message !== null;
};

export const isNodeKilled = (execution: NodeExecution): boolean => {
  if (execution.cancelled !== null) return false;
  return execution.killed !== null;
};

export const isNodeCancelled = (execution: NodeExecution): boolean => {
  return execution.cancelled !== null;
};

export function getDownstreamEdges(edges: Edge[], nodeIds: string[], shallow?: boolean) {
  const downSteamEdges = edges.filter((edge) => nodeIds.includes(edge.source));

  if (shallow) return downSteamEdges.map((edge) => edge.id);

  // We assume any executing node will connect to a store of some kind
  const downstreamStores = downSteamEdges.map((edge) => edge.target);
  const downstreamStoreEdges = edges.filter((edge) => downstreamStores.includes(edge.source));

  return [...downSteamEdges, ...downstreamStoreEdges].map((edge) => edge.id);
}

export function getNodeStatus(execution?: NodeExecution | null): NodeStatus {
  if (!execution) return NodeStatus.None;
  if (isNodeRunning(execution)) return NodeStatus.Running;
  if (isNodeQueued(execution)) return NodeStatus.Queued;
  if (isNodeError(execution)) return NodeStatus.Error;
  if (isNodeSuccess(execution)) return NodeStatus.Success;
  if (isNodeKilled(execution)) return NodeStatus.Killed;
  if (isNodeCancelled(execution)) return NodeStatus.Cancelled;
  return NodeStatus.None;
}

export function isConfigured(nodeData?: NodeData, graphLevel?: string) {
  if (!nodeData || !graphLevel) return true;
  if (graphLevel !== 'root') return true;

  const {
    manifestErrors,
    parameters,
    local_input_edges: localInputEdges,
    local_output_edges: localOutputEdges,
    outputs,
    inputs,
    type,
  } = nodeData;

  if (
    !isFunctionNodeType(type) &&
    !isComponentNodeType(type) &&
    !isSubgraphNodeType(type) &&
    !isChartNodeType(type)
  )
    return true;

  // Unused parameters are omitted since they do not cause any configuration issues
  const errors = manifestErrors?.filter((error) => error.source !== 'parameter');

  const hasManifestErrors = (errors?.length || 0) > 0;
  const hasParameterErrors = parameters.some(
    (parameter) => parameter.value === null && parameter.required
  );
  const hasInputErrors = isFunctionNodeType(type) && inputs.length !== localInputEdges.length;
  const hasOutputErrors = isFunctionNodeType(type) && outputs.length !== localOutputEdges.length;

  return !hasManifestErrors && !hasParameterErrors && !hasInputErrors && !hasOutputErrors;
}

export function getTopTabsList(nodeType?: NodeType, readOnly = false): NodeTab[] {
  switch (nodeType) {
    case 'table_store':
      return ['Data', 'Settings', 'Schema'];
    case 'stream_store':
      return ['Data', 'Settings'];
    case 'webhook':
      return readOnly
        ? ['Readme', 'Settings', 'Executions']
        : ['Readme', 'Test', 'Settings', 'Executions'];
    case 'markdown':
      return ['Code', 'Settings'];
    case 'chart':
      return ['Code', 'Settings', 'Readme', 'Templates'];
    case 'graph':
    case 'component_ref':
      return ['Readme', 'Settings', 'Code'];
    default:
      return ['Code', 'Settings', 'Readme', 'Executions'];
  }
}
export function getBottomTabsList(nodeType?: NodeType): NodeTab[] {
  switch (nodeType) {
    case 'webhook':
      return ['Console', 'Output'];
    case 'chart':
      return ['Preview', 'Input'];
    case 'markdown':
      return ['Preview'];
    case 'graph':
    case 'component_ref':
      return ['Console', 'Input', 'Output'];
    default:
      return ['Console', 'Input', 'Output'];
  }
}

export function isOverlappingNode(nodeA: GraphNode, nodeB: GraphNode) {
  const leftMax = Math.max(nodeA.position.x, nodeB.position.x);
  const rightMin = Math.min(
    nodeA.position.x + (nodeA.width || 0),
    nodeB.position.x + (nodeB.width || 0)
  );
  const topMax = Math.max(nodeA.position.y, nodeB.position.y);
  const bottomMin = Math.min(
    nodeA.position.y + (nodeA.height || 0),
    nodeB.position.y + (nodeB.height || 0)
  );

  return leftMax < rightMin && topMax < bottomMin;
}

export function isOverlappingNodes(mainNode: GraphNode, nodes: GraphNode[]) {
  return nodes.some((node) => isOverlappingNode(mainNode, node));
}

export function isPerception(node: ManifestNode) {
  return !node.interface?.inputs && !!node.interface?.outputs;
}

export function isCognition(node: ManifestNode) {
  return !!node.interface?.inputs && !!node.interface?.outputs;
}

export function isAction(node: ManifestNode) {
  return !!node.interface?.inputs && !node.interface?.outputs;
}

export function getConnectionType(node: ManifestNode) {
  if (!node.interface?.parameters) return;
  const parameterKey = Object.keys(node.interface.parameters).find(
    (parameter) => !!node.interface!.parameters![parameter].connection_type
  );

  if (!parameterKey) return;

  return node.interface.parameters[parameterKey].connection_type;
}

export function accumulateNodes(nodes: ManifestNode[]): AccumulatorNode[] {
  if (nodes.length <= 0) return [];
  const accumulator: AccumulatorNode[] = [];

  function findAccumulatorNode(key: string): AccumulatorNode | undefined {
    return accumulator.find((a) => a.key === key);
  }

  nodes.forEach((node) => {
    const key = node.from_component || node.icon_url || node.node_type;
    const accumulatorNode = findAccumulatorNode(key);
    const position = (node.display?.x || 0) * 10 + (node.display?.y || 0);
    if (accumulatorNode) {
      accumulatorNode.count = accumulatorNode.count + 1;
      accumulatorNode.positions.push(position);
    } else {
      accumulator.push({
        key: key,
        type: node.from_component ? 'connection' : 'node',
        connection: node.from_component ? getConnectionType(node) : undefined,
        iconUrl: node.icon_url || undefined,
        count: 1,
        positions: [position],
      });
    }
  });

  function compareFn(a: AccumulatorNode, b: AccumulatorNode) {
    const aAverage = a.positions.reduce((a2, b2) => a2 + b2, 0) / a.positions.length;
    const bAverage = b.positions.reduce((a2, b2) => a2 + b2, 0) / b.positions.length;
    if (aAverage < bAverage) {
      return -1;
    }
    if (aAverage > bAverage) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }
  accumulator.sort(compareFn);
  return accumulator;
}

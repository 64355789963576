import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function CohereAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Cohere.ai Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="Cohere.ai API key" form={form}>
          <FormHelperText>
            The API key can be found in the{' '}
            <a rel="noreferrer" target="_blank" href="https://dashboard.cohere.ai/api-keys">
              API Keys
            </a>{' '}
            section on the Cohere dashboard.
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const CohereAuth: AuthFormDef = {
  connectionType: 'cohere',
  element: CohereAuthFormElement,
};

export default CohereAuth;

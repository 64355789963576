import { useCallback } from 'react';
import { Button, Wrap } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import Loader from 'components/Loader';
import ModalComponent from 'components/Modal';
import useStore from 'state';
import { AppState, ConnectionsModalProps } from 'types';
import AuthForm from 'views/Connections/AuthForm';
import ConnectingTo from 'views/Connections/ConnectingTo';
import { hasAuthForm } from 'views/Connections/Extra';
import useConnect from 'views/Connections/hooks/useConnect';
import useConnectionTypes from 'views/Connections/hooks/useConnectionTypes';
import ConnectionItem from 'views/Marketplace/Connections/ConnectionItem';

const selector = (state: AppState) => ({
  connectionsModalProps: state.connectionsModalProps,
  setConnectionsModalProps: state.setConnectionsModalProps,
});

function NewConnectionModal({ connectingTo, connectionForm }: ConnectionsModalProps) {
  const { connectionsModalProps, setConnectionsModalProps } = useStore(selector, shallow);
  const { data: connectionTypes, isLoading } = useConnectionTypes();

  const handleConnect = useConnect();

  const handleClose = useCallback(() => {
    setConnectionsModalProps({ show: false });
  }, [setConnectionsModalProps]);

  return (
    <>
      <ModalComponent
        size="xl"
        showFooter={false}
        title="New Connection"
        onClose={handleClose}
        customFooter={<Button onClick={handleClose}>Close</Button>}
        blockScrollOnMount={false}
      >
        {isLoading && <Loader />}
        {connectingTo && <ConnectingTo {...connectingTo} />}
        {connectionForm && (
          <AuthForm
            connectionType={connectionForm}
            onSubmit={(p) => {
              setConnectionsModalProps({
                ...connectionsModalProps,
                connectingTo: { connectionType: connectionForm },
                connectionForm: undefined,
              });
              handleConnect(connectionForm, {
                formValues: p,
                onSuccess: handleClose,
              });
            }}
          />
        )}
        {!isLoading && !connectingTo && !connectionForm && (
          <Wrap spacing={4}>
            {connectionTypes?.results.map((connectionType) => {
              return (
                <ConnectionItem
                  key={connectionType.description}
                  title={connectionType.connection_type}
                  description={connectionType.description}
                  onClick={() => {
                    if (hasAuthForm(connectionType)) {
                      setConnectionsModalProps({
                        ...connectionsModalProps,
                        connectionForm: connectionType,
                      });
                    } else {
                      setConnectionsModalProps({
                        ...connectionsModalProps,
                        connectingTo: { connectionType },
                      });
                      handleConnect(connectionType, { onSuccess: handleClose });
                    }
                  }}
                />
              );
            })}
          </Wrap>
        )}
      </ModalComponent>
    </>
  );
}

export default NewConnectionModal;

import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { colors } from 'styles/colors';

import DateDisplay from 'components/DateDisplay';
import DuplicateGraph from 'components/DuplicateGraph';
import IconButtonMinimal from 'components/IconButtonMinimal';
import { H4, P2 } from 'components/Typography';
import { AccumulatorNode, NodeData, VegaTheme } from 'types';
import { GraphManifest, NodeType } from 'types/api';
import { downloadGraphZip } from 'utils';
import { accumulateNodes } from 'utils/nodes';
import NodeIcon from 'views/Graph/modules/GraphView/NodeTypes/components/NodeIcon';

import { ComponentIcon } from './ComponentIcon';
import DeleteGraphMenuItem from './DeleteGraphMenuItem';
import EditGraphInfoModal, { EditGraphInfoModalProps } from './EditGraphInfoModal';

type NodeBreakdownProps = {
  nodes: AccumulatorNode[];
};

export const NodeBreakdown = ({ nodes }: NodeBreakdownProps) => {
  return (
    <Flex gap={2} flex={1} justifyContent="left" ml={2} mb={1} flexWrap="wrap">
      {nodes.map((node) => {
        let key = node.key;
        if (node.key.startsWith('https')) {
          const p = node.key.split('/');
          key = p[p.length - 1].split('.')[0];
        }
        return (
          <Tooltip
            key={node.key}
            label={`${node.count} ${key} node${node.count > 1 ? 's' : ''}`}
            placement="bottom"
          >
            <Flex position="relative" align="center">
              {node.type === 'node' && (
                <NodeIcon
                  nodeId="ghost"
                  position="relative"
                  nodeData={{ type: node.key as NodeType, icon_url: node.iconUrl } as NodeData}
                  color={colors.light.text1}
                />
              )}
              {node.type === 'connection' && (
                <ComponentIcon connection={node.connection} iconUrl={node.iconUrl} />
              )}
            </Flex>
          </Tooltip>
        );
      })}
    </Flex>
  );
};

type GraphCardProps = {
  uid: string;
  slug: string;
  title: string;
  theme?: VegaTheme;
  manifest?: GraphManifest;
  updatedAt?: string;
  readOnly?: boolean;
  onClick?: (event: MouseEvent) => void;
  description?: string;
  enabled?: boolean;
};

function GraphCard({
  uid,
  title,
  slug,
  theme,
  manifest,
  updatedAt,
  readOnly,
  onClick,
  description,
  enabled,
}: GraphCardProps) {
  const [graphToEdit, setGraphToEdit] = useState<EditGraphInfoModalProps | null>(null);

  const graphNodes = useMemo(() => {
    if (!manifest?.nodes_by_id) return [];
    return Object.keys(manifest.nodes_by_id).map((nodeId) => manifest.nodes_by_id![nodeId]);
  }, [manifest?.nodes_by_id]);

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const toast = useToast();

  const showErrorToast = useCallback(
    (description: string) => {
      toast({
        description,
        status: 'error',
        isClosable: true,
      });
    },
    [toast]
  );

  const downloadGraph = useCallback(
    async (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();
      const result = await downloadGraphZip(slug, null, uid!);
      if (!result) {
        showErrorToast('Error downloading app');
      }
    },
    [showErrorToast, uid, slug]
  );

  const editGraph = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.preventDefault();
      setGraphToEdit({
        uid: uid!,
        title: title || '',
        slug: slug || '',
        theme: theme,
      });
    },
    [uid, title, slug, theme]
  );

  const nodeAccumulator = useMemo(() => accumulateNodes(graphNodes), [graphNodes]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(event);
    },
    [onClick]
  );

  return (
    <>
      <Flex
        flexDir="column"
        flex={1}
        borderColor="border2"
        borderStyle="solid"
        borderWidth={1}
        borderRadius={12}
        paddingX={3}
        paddingY={3}
        cursor="pointer"
        justify="space-between"
        data-testid={'graph-preview-link'}
        onClick={handleClick}
        transition="box-shadow 0.3s ease-in-out"
        gap={3}
        _hover={{
          // #6 from https://getcssscan.com/css-box-shadow-examples
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        }}
      >
        <Flex justifyContent="space-between" alignItems="flex-start" flex={1} position="relative">
          <Flex direction="column" paddingX={2} flex={1} overflow="hidden">
            <Flex direction="row" justifyContent="space-between">
              <Tooltip label={title} placement="top-start">
                <Flex whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  <H4
                    className="truncate"
                    data-testid={'graph-preview-name'}
                    noOfLines={1}
                    lineHeight="28px"
                    fontSize="18px"
                    fontWeight="600"
                    display="block"
                  >
                    {title}
                  </H4>
                </Flex>
              </Tooltip>

              {!readOnly && (
                <Flex position="relative" mr={-2}>
                  <Menu
                    computePositionOnMount
                    autoSelect={false}
                    placement="bottom-end"
                    closeOnBlur={true}
                  >
                    <IconButtonMinimal
                      Icon={FiMoreVertical}
                      label="Graph actions"
                      as={MenuButton}
                      onClick={(event) => event.stopPropagation()}
                      _hover={{ bg: colors.gray[100] }}
                      style={{ padding: 6 }}
                    />

                    <Portal>
                      <MenuList zIndex={11} fontSize="sm" color="text2">
                        <MenuItem onClick={editGraph}>Edit App Info</MenuItem>

                        <MenuItem onClick={downloadGraph}>Download App Zip</MenuItem>

                        <MenuItem padding={0}>
                          <DuplicateGraph graphUID={uid} menuButton="menuItem" />
                        </MenuItem>

                        <DeleteGraphMenuItem uid={uid} title={title}>
                          Delete App
                        </DeleteGraphMenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                </Flex>
              )}
            </Flex>
            <Flex justify="space-between" align="center" gap={2}>
              {!!updatedAt ? (
                <P2 color={isDarkMode ? 'light.bg1' : 'dark.bg1'} fontSize="12px">
                  Last updated:{' '}
                  <DateDisplay
                    color={isDarkMode ? 'light.bg1' : 'dark.bg1'}
                    fontSize="12px"
                    date={updatedAt}
                    tooltipPlacement="bottom"
                  />
                </P2>
              ) : (
                <> </>
              )}
              {enabled !== undefined && !readOnly ? (
                <P2
                  fontWeight="600"
                  lineHeight="20px"
                  color={enabled ? colors.light.nodeSuccess : colors.light.nodeError}
                >
                  {enabled ? 'Active' : 'Disabled'}
                </P2>
              ) : (
                <> </>
              )}
            </Flex>
            {!!description && <P2 fontSize="14px">{description}</P2>}
          </Flex>
        </Flex>

        {manifest && <NodeBreakdown nodes={nodeAccumulator} />}
      </Flex>

      {graphToEdit && <EditGraphInfoModal {...graphToEdit} setCurrentEditGraph={setGraphToEdit} />}
    </>
  );
}

export default GraphCard;

import { useQuery } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { VariableResponse } from 'types/api';
import { useVariablesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (s: AppState) => s.organizationUID;

function useVariables() {
  const organizationUID = useStore(selector);

  const url = useVariablesURL(organizationUID);

  const result = useQuery<VariableResponse>(
    ['variables', organizationUID],
    async () => {
      const res = await Axios.get<VariableResponse>(url);
      return res?.data;
    },
    {
      enabled: !!organizationUID,
    }
  );

  return result;
}

export default useVariables;

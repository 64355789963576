import useMarketplaceAppsByCategory from 'hooks/api/useMarketplaceAppsByCategory';
import useMarketplaceSearchApps from 'hooks/api/useMarketplaceSearchApps';
import useMarketplaceSearchComponents from 'hooks/api/useMarketplaceSearchComponents';
import useMarketplaceSearchConnections from 'hooks/api/useMarketplaceSearchConnections';

export function PrefetchMarketplace() {
  // prefetch default marketplace search results
  useMarketplaceAppsByCategory();
  useMarketplaceSearchApps('');
  useMarketplaceSearchComponents('');
  useMarketplaceSearchConnections('');
  return null;
}

import { ReactNode } from 'react';
import { Link } from '@chakra-ui/react';

type NodeReferenceProps = {
  name?: string;
  props: any;
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const NodeReference = ({ name, props, children, onClick }: NodeReferenceProps) => {
  return (
    <span {...props.props}>
      {!name ? (
        <span style={{ fontWeight: 'bold', color: 'red' }}>Invalid node ID [{props.id}]</span>
      ) : (
        <Link
          {...props}
          fontWeight="bold"
          overflowWrap="anywhere"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick?.(e);
          }}
        >
          {!!children ? <>{children}</> : <>{name}</>}
        </Link>
      )}
    </span>
  );
};

export default NodeReference;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function SlackbotAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Slack Bot</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="token" label="Bot User OAuth Token" form={form}>
          <FormHelperText>
            The Bot User OAuth token can be found in the <b>OAuth &amp; Permissions</b> section of
            your{' '}
            <a rel="noreferrer" target="_blank" href="https://api.slack.com/apps">
              Slack app configuration.
            </a>
          </FormHelperText>
          <FormHelperText>
            More information on Bot Users can be found in the{' '}
            <a rel="noreferrer" target="_blank" href="https://api.slack.com/bot-users">
              Slack documentation
            </a>
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const SlackbotAuth: AuthFormDef = {
  connectionType: 'slackbot',
  element: SlackbotAuthFormElement,
};

export default SlackbotAuth;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  Connection,
  ConnectionPopupMessage,
  ConnectionPopupMessageError,
  ConnectionPopupResult,
  ConnectionType,
} from 'types/api';
import { useConnectionCompleteURL, useConnectionReconnectURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;

function generateState(): string {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40) as any;
  window.crypto.getRandomValues(array);
  array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
  return String.fromCharCode.apply(null, array);
}

const popup = (authUrl: string) => {
  const state = generateState();
  const origin = window.origin;

  const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;

  let popupWatcher: any | null;
  let popupWindow: Window | null = window.open(
    authUrl + '&state=' + state,
    'Patterns Connection',
    `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
  );

  function cleanup() {
    if (popupWatcher) {
      clearInterval(popupWatcher);
      popupWatcher = null;
    }

    if (popupWindow) {
      popupWindow.close();
      popupWindow = null;
    }
  }

  const getPopupMessage = new Promise<ConnectionPopupMessage>((resolve, _reject) => {
    popupWatcher = setInterval(() => {
      if (popupWindow && (!popupWindow.window || popupWindow.window.closed)) {
        cleanup();

        const message: ConnectionPopupMessageError = {
          messageType: 'connectionPopupMessage',
          type: 'error',
          error: 'Authorization popup window closed',
        };
        resolve(message);
      }
    }, 250);

    const messageCallback = async (event: MessageEvent) => {
      if (event.origin !== origin || event.data['messageType'] !== 'connectionPopupMessage') {
        return;
      }
      window.removeEventListener('message', messageCallback);
      const message: ConnectionPopupMessage = event.data;

      cleanup();
      resolve(message);
    };

    window.addEventListener('message', messageCallback);
  });

  const resultThenable: (message: ConnectionPopupMessage) => ConnectionPopupResult = (
    message: ConnectionPopupMessage
  ) => {
    switch (message.type) {
      case 'result':
        const query = message.query;
        const code = query.code ? `${query.code}` : null;
        const state = query.state ? `${query.state}` : null;

        if (!code) return { type: 'failure', message: 'No authorization code found in result' };
        if (state !== state) return { type: 'failure', message: 'State mismatch' };

        return { type: 'success', code, query };
      case 'error':
        return { type: 'failure', message: message.error };
    }
  };
  return getPopupMessage.then(resultThenable);
};

export type AuthFormValues = Record<string, string>;

export type ConnectionAuthorizeProps = {
  connectionType: ConnectionType;
  orgUid: string;
  connectionUid?: string;
  formValues?: AuthFormValues;
};

/**
 * Replace {formValues} in url with values from params
 * @param url         A url like: https://{token}.something.com
 * @param formValues  A map of tokens to values
 */
function substitute(url: string, formValues: AuthFormValues): string {
  for (const [key, value] of Object.entries(formValues)) {
    url = url.replaceAll(`{${key}}`, encodeURIComponent(value));
  }
  return url;
}

type CreateResponse = {
  popupResult: ConnectionPopupResult;
  completionResult?: Connection;
};

function useCreateConnection() {
  const queryClient = useQueryClient();
  const reconnectURL = useConnectionReconnectURL();
  const completeURL = useConnectionCompleteURL();

  return useMutation<CreateResponse, AxiosError, ConnectionAuthorizeProps>(
    async (props: ConnectionAuthorizeProps) => {
      const authUrl = props.connectionType.auth_url;
      if (!authUrl) {
        return Promise.reject({ type: 'failure', message: 'No auth_url found' });
      }
      const url = props.formValues ? substitute(authUrl, props.formValues) : authUrl;
      const popupResult = await popup(url);
      let completionResult: Connection;

      if (popupResult.type === 'success') {
        if (props.connectionUid) {
          const completionResponse = await Axios.post<Connection>(reconnectURL, {
            connection_uid: props.connectionUid,
            params: popupResult.query,
          });
          completionResult = completionResponse.data;
        } else {
          const completionResponse = await Axios.post<Connection>(completeURL, {
            connection_type: props.connectionType.connection_type,
            organization_uid: props.orgUid,
            params: popupResult.query,
          });
          completionResult = completionResponse.data;
        }
        await queryClient.invalidateQueries(['connections']);

        return { popupResult, completionResult };
      }

      return { popupResult };
    }
  );
}

export default useCreateConnection;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, Text, VStack } from '@chakra-ui/react';

import { TFormInput } from 'components/TForm';
import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function AirtableAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Airtable Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="API Key" form={form}>
          <FormHelperText>
            The API key can be found in your Airtable{' '}
            <a rel="noreferrer" target="_blank" href="https://airtable.com/account">
              Account
            </a>
          </FormHelperText>
        </TFormInputPassword>

        <TFormInput name="test_base_id" form={form} label="Test Base ID" placeholder="app.....">
          <FormHelperText>
            Enter an id for an Airtable base that can be used to test this connection. The
            connection can still be used to access any base. The base id can be found in an Airtable
            URL, and starts with <Text as="code">app</Text>.
          </FormHelperText>
        </TFormInput>

        <TFormInput name="test_table_id" form={form} label="Test Table ID" placeholder="MyTasks">
          <FormHelperText>
            The name of a table within the above Test Base. This is only used to test the
            connection.
          </FormHelperText>
        </TFormInput>
      </VStack>
    </>
  );
}

const AirtableAuth: AuthFormDef = {
  connectionType: 'airtable',
  element: AirtableAuthFormElement,
};

export default AirtableAuth;

import { Center, ChakraProps, Flex, Text } from '@chakra-ui/react';

import LoaderIcon from 'icons/loader-icon.svg';

function Loader(props: ChakraProps) {
  return (
    <Center data-testid={'loader'} width="100%" height="100vh" {...props}>
      <Flex direction="column" alignItems="center">
        <LoaderIcon />
        <Text pt={4} textStyle="p2" color="text3">
          Loading...
        </Text>
      </Flex>
    </Center>
  );
}

export default Loader;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInput, TFormSelect } from 'components/TForm';
import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function MailgunAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  const selectOptions = [
    { value: 'us', label: 'US' },
    { value: 'eu', label: 'EU' },
  ];
  return (
    <>
      <Heading>Configure Your Mailgun Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="API Key" form={form}>
          <FormHelperText>
            The API key can be found in{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://app.mailgun.com/app/account/security/api_keys"
            >
              Account / API Keys
            </a>
          </FormHelperText>
        </TFormInputPassword>

        <TFormInput name="domain_name" form={form} placeholder="mg.mydomain.com" label="Domain">
          <FormHelperText>
            The domain name can be found in{' '}
            <a rel="noreferrer" target="_blank" href="https://app.mailgun.com/app/sending/domains">
              Sending / Domains
            </a>
          </FormHelperText>
        </TFormInput>

        <TFormSelect name="region" form={form} options={selectOptions} defaultValue={'us'}>
          <FormHelperText>
            More information can be found in the{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://documentation.mailgun.com/en/latest/api-intro.html#mailgun-regions-1"
            >
              Regions
            </a>{' '}
            documentation.
          </FormHelperText>
        </TFormSelect>
      </VStack>
    </>
  );
}

const MailgunAuth: AuthFormDef = {
  connectionType: 'mailgun',
  element: MailgunAuthFormElement,
};

export default MailgunAuth;

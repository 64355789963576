import { useCallback } from 'react';
import { FiPlus } from 'react-icons/fi';
import shallow from 'zustand/shallow';

import Modal from 'components/Modal';
import useMarketplaceRelatedItems from 'hooks/api/useMarketplaceRelatedItems';
import useAppStore from 'state';
import useGraphStore from 'views/Graph/state';

import ConnectionComponents from './ConnectionComponents';

const AddConnectionComponent = () => {
  const [connectionModalProps, setConnectionModalProps] = useAppStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { data: relatedItems } = useMarketplaceRelatedItems(
    'connections',
    connectionModalProps.connection?.connection_type
  );

  const setNewNodeItem = useGraphStore((state) => state.setNewNodeItem);

  const closeModal = useCallback(() => {
    setConnectionModalProps({});
  }, [setConnectionModalProps]);

  const items = relatedItems?.components.results || [];

  return (
    <Modal isOpen={true} onClose={closeModal} size="xl" showFooter={false}>
      <ConnectionComponents
        title={`${connectionModalProps.connection?.connection_type_description} components`}
        icon={FiPlus}
        onClick={(uid) => {
          closeModal();
          const item = items.find((item) => item.uid === uid);
          if (!item) return;
          setNewNodeItem(item);
        }}
      />
    </Modal>
  );
};

export default AddConnectionComponent;

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { Graph } from 'types/api';
import { useGraphURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

export type UpdateGraphMetadataData = {
  uid: string;
  slug?: string;
  title?: string;
  tags?: string[];
  description?: string;
  enabled?: boolean;
  public?: boolean;
  theme?: string;
};

function useUpdateGraphMetadata(
  options?: UseMutationOptions<Graph, unknown, UpdateGraphMetadataData>
): UseMutationResult<Graph, unknown, UpdateGraphMetadataData, unknown> {
  const queryClient = useQueryClient();

  // All values in the request body are optional (so we only update the fields that are provided)
  const mutation = useMutation(async (editGraphData: UpdateGraphMetadataData) => {
    const url = useGraphURL(editGraphData.uid);
    const result = await Axios.put<Graph>(url, editGraphData);

    await queryClient.invalidateQueries(['graphs']);
    await queryClient.invalidateQueries(['graph']);

    if (result.data.version_uid) {
      // Keeping track of the latest graph version updates for shitty multiplayer support
      const { setUpdatedGraphVersionUID } = useStore.getState();
      setUpdatedGraphVersionUID(result.data.version_uid);
    }

    return result?.data;
  }, options);

  return mutation;
}

export default useUpdateGraphMetadata;

import { useQuery } from '@tanstack/react-query';

import { CloudStorage } from 'types/api';
import { useDefaultStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphStorageQueryKey } from 'utils/queryKeys';

const queryKey = GraphStorageQueryKey();

function useDefaultStorage(graphUID?: string) {
  const url = useDefaultStorageURL(graphUID);

  return useQuery<CloudStorage>(
    queryKey,
    async () => {
      const res = await Axios.get<CloudStorage>(url!);
      return res.data;
    },
    {
      enabled: !!url,
    }
  );
}

export default useDefaultStorage;

import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { FaDiscord } from 'react-icons/fa';
import {
  FiDatabase,
  FiFileText,
  FiKey,
  FiLink2,
  FiShoppingCart,
  FiSun,
  FiTool,
  FiUser,
} from 'react-icons/fi';
import { RiGlobalLine, RiShareLine } from 'react-icons/ri';

import { VegaTheme } from 'types';

import SlackMark from './icons/SlackMark.svg';

const isProduction = process.env.NEXT_PUBLIC_API_URL?.includes('api-production');

type Page = {
  title: string | null;
  pathname: string;
  icon: IconType | ReactNode | null;
};

export type NodeColor = {
  id: string;
  name: string;
  valueLight: string;
  valueDark: string;
};

type PatternsAppConfig = {
  defaultPathname: string;
  views: Record<string, Page>;
  localstorage: {
    organizationUID: string;
    organizations: string;
  };
  nodeColors: NodeColor[];
  graphGrid: {
    x: number;
    y: number;
  };
  graphNavbarHeight: number;
  graphSaveTimeout: number;
  ideSaveTimeout: number;
  logoHeight: number;
  docsUrl: string;
  vegaThemes: {
    label: string;
    value: VegaTheme;
  }[];
  graphActionLabels: {
    perception: string;
    cognition: string;
    action: string;
  };
};

const config: PatternsAppConfig = {
  defaultPathname: '/home',
  views: {
    graph: {
      title: null,
      pathname: '/graph',
      icon: null,
    },
    graphs: {
      title: 'Apps',
      pathname: '/home',
      icon: RiShareLine,
    },
    marketplace: {
      title: 'Marketplace',
      pathname: '/marketplace',
      icon: RiGlobalLine,
    },
    verify_email: {
      title: 'Verify your email',
      pathname: '/verify-email',
      icon: null,
    },
    join: {
      title: 'Join Organization',
      pathname: '/join',
      icon: RiGlobalLine,
    },
    account_setup: {
      title: 'Account Setup',
      pathname: '/account-setup',
      icon: null,
    },
    secrets: {
      title: 'Secrets',
      pathname: '/secrets',
      icon: FiKey,
    },
    storage: {
      title: 'Databases',
      pathname: '/databases',
      icon: FiDatabase,
    },
    connections: {
      title: 'Connections',
      pathname: '/connections',
      icon: FiLink2,
    },
    members: {
      title: 'Members',
      pathname: '/members',
      icon: FiUser,
    },
    billing: {
      title: 'Billing & Usage',
      pathname: '/billing',
      icon: FiShoppingCart,
    },
    settings: {
      title: 'Settings',
      pathname: '/settings',
      icon: FiTool,
    },
    slack_connect: {
      title: 'Chat on Slack',
      pathname: '/slack-connect',
      icon: SlackMark,
    },
    discord: {
      title: 'Join our Discord',
      pathname: '/discord',
      icon: FaDiscord,
    },
    colormode: {
      title: 'Color mode',
      pathname: '/colormode',
      icon: FiSun,
    },
    documentation: {
      title: 'Documentation',
      pathname: '/documentation',
      icon: FiFileText,
    },
  },
  localstorage: {
    organizationUID: 'basis:organization_uid',
    organizations: 'basis:organizations',
  },
  nodeColors: [
    { id: 'default', name: 'Default', valueLight: '#fefefe', valueDark: '#444' },
    {
      id: 'green',
      name: 'Green',
      valueLight: '#E6F8EC',
      valueDark: '#2F855A',
    },
    { id: 'yellow', name: 'Yellow', valueLight: '#F9F3D0', valueDark: '#B7791F' },
    {
      id: 'purple',
      name: 'Purple',
      valueLight: '#EFE5F5',
      valueDark: '#6B46C1',
    },
    {
      id: 'blue',
      name: 'Blue',
      valueLight: '#D5E5F4',
      valueDark: '#2B6CB0',
    },
    {
      id: 'orange',
      name: 'Orange',
      valueLight: '#F6E4DA',
      valueDark: '#DD6B20',
    },
    {
      id: 'red',
      name: 'Red',
      valueLight: '#FFE3E3',
      valueDark: '#9B2C2C',
    },
  ],
  graphGrid: {
    x: 100,
    y: 100,
  },
  graphNavbarHeight: 39.9999, // for some reason 40 doesn't work?
  graphSaveTimeout: 5000,
  ideSaveTimeout: 2000,
  logoHeight: 22,
  docsUrl: 'https://www.patterns.app/docs/',
  vegaThemes: [
    {
      label: 'Default (Patterns)',
      value: 'default',
    },
    {
      label: 'Dark',
      value: 'dark',
    },
    {
      label: 'Excel',
      value: 'excel',
    },
    {
      label: 'FiveThirtyEight',
      value: 'fivethirtyeight',
    },
    {
      label: 'ggplot2',
      value: 'ggplot2',
    },
    {
      label: 'Google Charts',
      value: 'googlecharts',
    },
    {
      label: 'LA Times',
      value: 'latimes',
    },
    {
      label: 'Power BI',
      value: 'powerbi',
    },
    {
      label: 'Quartz',
      value: 'quartz',
    },
    {
      label: 'Urban Institute',
      value: 'urbaninstitute',
    },
    {
      label: 'Vox',
      value: 'vox',
    },
  ],
  graphActionLabels: {
    perception: 'Input',
    cognition: 'Process',
    action: 'Output',
  },
};

export { isProduction };

export default config;

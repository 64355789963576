import { Flex, Image, Text } from '@chakra-ui/react';

import config from 'config';

type IconProps = {
  iconURL?: string | null;
  name: string;
  height?: number | string;
  width?: number | string;
};

const DEFAULT_SIZE = config.graphGrid.x / 4;

function Icon({ iconURL, name, height, width }: IconProps) {
  return (
    <Flex
      width={`${width || DEFAULT_SIZE}px`}
      height={`${height || DEFAULT_SIZE}px`}
      justify="center"
      align="center"
    >
      {iconURL ? (
        <Image
          src={iconURL}
          alt={name[0]}
          objectFit="contain"
          h="full"
          w="full"
          onError={({ currentTarget }) => {
            // if iconURL is invalid -> just show generic subgraph icon?
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `/node-subgraph-icon.svg`;
          }}
        />
      ) : (
        <Flex
          background="#1445F4"
          color="#fff"
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Text fontSize="12px" fontWeight={600}>
            {name.toUpperCase()[0]}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

export default Icon;

import { useState } from 'react';
import { Alert, AlertIcon, Button, Portal } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';

import { Label } from 'components/Typography';

import pkg from '../../../package.json';

type VersionResponse = {
  version: string;
};

function VersionCheck() {
  const [haveCheckedVersion, setHaveCheckedVersion] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);

  useQuery<VersionResponse>(
    ['version'],
    async () => {
      const res = await Axios.get<VersionResponse>(`${location.origin}/.netlify/functions/check`);
      if (res?.data?.version && pkg.version !== res?.data?.version) {
        setHasUpdate(true);
        if (!haveCheckedVersion) {
          location.reload(); // if this is the first time, reload the page
        }
      }
      setHaveCheckedVersion(true);
      return res?.data;
    },
    {
      retry: false,
      enabled: process.env.NODE_ENV !== 'development',
      refetchInterval: 5 * 60 * 1000,
    }
  );

  if (!hasUpdate) {
    return null;
  }

  return (
    <Portal>
      <Alert
        status="warning"
        size="xs"
        position="fixed"
        bottom={4}
        w="auto"
        zIndex={1001}
        left="50%"
        transform="translate(-50%, 0)"
      >
        <AlertIcon />
        <Label>A new version is available</Label>
        <Button ml={2} colorScheme="orange" onClick={() => location.reload()}>
          Reload
        </Button>
      </Alert>
    </Portal>
  );
}

export default VersionCheck;

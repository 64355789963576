import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { CheckIcon, CopyIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';

type CopyButtonProps = {
  code: string;
  children: ReactNode;
};

const CopyButton = ({ code, children }: CopyButtonProps) => {
  const [label, setLabel] = useState('Copy');
  const [show, setShow] = useState(false);

  const handleCopy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (code && typeof code === 'string') {
        navigator.clipboard.writeText(code);
        setLabel('Copied');
      }
    },
    [code]
  );

  return (
    <Flex
      flex={1}
      position="relative"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => {
        setShow(false);
        setLabel('Copy');
      }}
    >
      <Box className="copy-button" position="absolute" right={2} top={2}>
        {show && (
          <Tooltip label={label} placement="right">
            <IconButton
              variant="outline"
              colorScheme="gray"
              bg="editorBg"
              borderColor="gray.400"
              borderRadius="md"
              _hover={{ bg: 'white' }}
              _active={{ bg: 'white', color: 'green' }}
              isActive={label === 'Copied'}
              aria-label="Copy"
              size="xs"
              icon={label === 'Copy' ? <CopyIcon /> : <CheckIcon />}
              onClick={handleCopy}
            />
          </Tooltip>
        )}
      </Box>
      {children}
    </Flex>
  );
};

export default CopyButton;

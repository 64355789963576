import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Graph } from 'types/api';
import { getLatestGraphVersion } from 'utils';
import { MarketplaceGraphQueryKey } from 'utils/queryKeys';

function useMarketplaceGraph<T = Graph>(
  graphUId?: string,
  options: UseQueryOptions<Graph, AxiosError, T> = {}
) {
  return useQuery<Graph, AxiosError, T>(
    MarketplaceGraphQueryKey(graphUId),
    async () => {
      let graph = await getLatestGraphVersion(graphUId!);

      return graph;
    },
    {
      enabled: !!graphUId,
      retry: (failureCount, error: any) => {
        // don't retry on 404 or 403
        return error?.response?.status === 404 || error?.response?.status === 403 ? false : true;
      },
      staleTime: 60 * 1000,
      keepPreviousData: true,
      ...options,
    }
  );
}

export default useMarketplaceGraph;

import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { BillingPlanType } from 'types/api';
import { useCreateOrganizationURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

export type CreateOrganizationData = {
  slug: string;
  type: BillingPlanType;
  description?: string;
};

type StripeBillingResponse = {
  url: string;
};

function useCreatePaidOrganization(
  options?: UseMutationOptions<StripeBillingResponse, AxiosError, CreateOrganizationData>
): UseMutationResult<StripeBillingResponse, AxiosError, CreateOrganizationData, unknown> {
  const url = useCreateOrganizationURL();
  const mutation = useMutation(async ({ slug, description }: CreateOrganizationData) => {
    const result = await Axios.post<StripeBillingResponse>(url, {
      slug,
      description,
      price_id: process.env.NEXT_PUBLIC_STRIPE_BUSINESS_PRICE_ID,
    });

    return result.data;
  }, options);

  return mutation;
}

export default useCreatePaidOrganization;

import { ReactNode, useCallback } from 'react';

import useStore from 'views/Graph/state';

import NodeReference from './NodeReference';

type GraphNodeReferenceProps = {
  props: any;
  children: ReactNode;
};

const GraphNodeReference = ({ props, children }: GraphNodeReferenceProps) => {
  const openTab = useStore((state) => state.openTab);
  const name = useStore(
    useCallback((state) => state.nodes.find((node) => node.id === props.id)?.data.name, [props.id])
  );

  return <NodeReference {...{ name, props, children }} onClick={() => openTab(props.id)} />;
};

export default GraphNodeReference;

import { Box, Image, Text, WrapItem } from '@chakra-ui/react';

import { getSVGPath } from 'utils/connections';

type ConnectionItemProps = {
  title: string;
  description: string;
  onClick?: (uid: string) => void;
};

function ConnectionItem({ title, description, onClick }: ConnectionItemProps) {
  return (
    <Box
      as="a"
      _hover={{ opacity: 0.8 }}
      key={title}
      onClick={() => {
        onClick?.(title);
      }}
    >
      <WrapItem
        alignItems="center"
        border="1px"
        borderColor="#DEE2E6"
        borderRadius="20px"
        py={2.5}
        px={4}
      >
        <Image
          src={`/images/connections/${getSVGPath(title)}.svg`}
          alt={`${title} icon`}
          h={6}
          mr={2}
        />

        <Text fontSize="14px" lineHeight="1.5" letterSpacing="-0.001em" color="light.text1">
          {description}
        </Text>
      </WrapItem>
    </Box>
  );
}

export default ConnectionItem;

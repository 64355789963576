import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Connection, ConnectionType } from 'types/api';
import { useConnectionCompleteURL, useConnectionReconnectURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

export type CompleteConnectionProps = {
  connectionType: ConnectionType;
  params: object;
  organizationUid?: string;
  connectionUid?: string;
};

function useCreateCredentialsConnection(
  options: UseMutationOptions<Connection, AxiosError, CompleteConnectionProps> = {}
) {
  const completeUrl = useConnectionCompleteURL();
  const reconnectURL = useConnectionReconnectURL();
  const queryClient = useQueryClient();

  return useMutation<Connection, AxiosError, CompleteConnectionProps>(
    async ({ connectionType, organizationUid, params, connectionUid }) => {
      const res = connectionUid
        ? await Axios.post<Connection>(reconnectURL, {
            connection_uid: connectionUid,
            params: params,
          })
        : await Axios.post<Connection>(completeUrl, {
            connection_type: connectionType.connection_type,
            organization_uid: organizationUid,
            params: params,
          });

      await queryClient.invalidateQueries(['connections']);

      return res?.data;
    },
    options
  );
}

export default useCreateCredentialsConnection;

import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import { customAlphabet } from 'nanoid/non-secure';

import config from 'config';
import { Graph, ManifestNode } from 'types/api';
import Axios from 'utils/Axios';

import { useGraphPreviousVersionURL, useGraphURL, useGraphVersionZipURL } from './ApiEndpoints';
import JSONBig from './JSONBig';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);

export function formatDate(
  date: Date | string,
  includeSeconds = false,
  includeMillis = false
): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  const timeFormat = includeMillis ? 'HH:mm:ss:SS' : includeSeconds ? 'HH:mm:ss' : 'HH:mm';

  return format(dateObj, `d-MMM-yyyy ${timeFormat}`);
}

export function isNumeric(n: any): n is number {
  return !isNaN(n) && isFinite(n);
}

export function getId() {
  return nanoid();
}

export function makeGraphURL(graphUID: string, graphSlug?: string) {
  return `${config.views.graph.pathname}/${graphUID}${
    graphSlug ? `/${graphSlug.toLowerCase()}` : ''
  }`;
}

export function makePreviousVersionGraphURL(
  graphUID: string,
  graphVersionUID: string,
  graphSlug?: string
) {
  return `${config.views.graph.pathname}/${graphUID}${
    graphSlug ? `/${graphSlug.toLowerCase()}` : ''
  }?previousGraphVersion=${graphVersionUID}`;
}

export function makeDashboardURL(graphUID: string, graphSlug?: string, dashboardId?: string) {
  return `${makeGraphURL(graphUID, graphSlug)}/${dashboardId || ''}`;
}

export async function getLatestGraphVersion(graphUID: string): Promise<Graph> {
  const url = useGraphURL(graphUID);
  const res = await Axios.get<Graph>(url);
  return res.data;
}

export async function getSpecificGraphVersion(graphVersionUID: string): Promise<Graph> {
  const url = useGraphPreviousVersionURL(graphVersionUID);
  const res = await Axios.get<Graph>(url);
  return res.data;
}

export async function getGraphVersionZip(graphVersionUID: string): Promise<Blob> {
  const url = useGraphVersionZipURL(graphVersionUID);
  const res = await Axios.get(url, {
    responseType: 'blob',
  });
  return res.data;
}

export function getParentIds(id: string, nodes: ManifestNode[], parentIds: string[]): string[] {
  const node = nodes.find((n) => n.id === id);

  if (!node || !node.parent_node_id) {
    return parentIds;
  }

  return getParentIds(node.parent_node_id, nodes, [node.parent_node_id, ...parentIds]);
}

export async function downloadGraphZip(
  slug: string,
  graphVersionUID?: string | null,
  graphUID?: string | null
): Promise<Boolean> {
  try {
    if (!graphVersionUID && !graphUID) {
      throw new Error(`Must provide either graphUID or graphVersionUID`);
    } else if (!graphVersionUID && graphUID) {
      const graph = await getLatestGraphVersion(graphUID);
      graphVersionUID = graph.version_uid || '';
    }
    const zippedGraph = await getGraphVersionZip(graphVersionUID!);
    fileDownload(zippedGraph, `${slug}_${graphVersionUID}.zip`);
    return true;
  } catch (err) {
    return false;
  }
}

export function copyToClipboard(value: string | number | boolean): Promise<void> {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(value.toString());
  }
  return Promise.reject();
}

export function parseBigIntJSON(value: string) {
  const bigParse = JSONBig({
    useNativeBigInt: true,
    strict: true,
  }).parse(value);
  // Hack to give bigParse missing prototype functions such as hasOwnProperty
  return { ...Object(), ...bigParse };
}

export function stringifyBigIntJSON(record: object) {
  return JSONBig({
    useNativeBigInt: true,
    strict: true,
  }).stringify(record);
}

export function isJSON(value: string) {
  try {
    parseBigIntJSON(value);
  } catch (e) {
    return false;
  }
  return true;
}

export function capitalize(value: string) {
  return value[0].toUpperCase() + value.slice(1);
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isMac() {
  return navigator.platform.indexOf('Mac') > -1;
}

export function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}

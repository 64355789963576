import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function TwitterAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Twitter Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="Bearer Token" form={form}>
          <FormHelperText>
            To obtain an Bearer Token follow{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://developer.twitter.com/en/docs/authentication/oauth-2-0/bearer-tokens"
            >
              this guide
            </a>
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const TwitterAuth: AuthFormDef = {
  connectionType: 'twitter',
  element: TwitterAuthFormElement,
};

export default TwitterAuth;

import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Router from 'next/router';
import shallow from 'zustand/shallow';

import ModalComponent from 'components/Modal';
import { P2 } from 'components/Typography';
import config from 'config';
import useCreateGraph from 'hooks/api/useCreateGraph';
import useDuplicateGraph from 'hooks/api/useDuplicateGraph';
import useOrganization from 'hooks/useOrganization';
import useStore from 'state';
import { CreateAppModalProps, VegaTheme } from 'types';
import { Graph } from 'types/api';
import { getLatestGraphVersion, makeGraphURL } from 'utils';

import ComputeSelect from './ComputeSelect';
import DatabaseSelect from './DatabaseSelect';

type CreateGraphModalProps = {
  cloneData: CreateAppModalProps;
};

export type CreateGraphFormData = {
  title: string;
  database: string;
  compute: string;
  theme: VegaTheme;
};

function CloneGraphModal({ cloneData }: CreateGraphModalProps) {
  const [setShowCreateAppModal, setDatabaseModalProps] = useStore(
    (state) => [state.setShowCreateAppModal, state.setDatabaseModalProps],
    shallow
  );
  const { setOrganization } = useOrganization();

  const toast = useToast();

  const isCloneGraph = !!cloneData.graphVersionUID; // if we have a graphVersionUID, we're cloning a graph
  const isCloneTemplate = !!cloneData.graphUID; // if we have a graphUID we're cloning a template
  // if we have a componentUID, we're creating a new graph and adding in the component
  const isNewGraphWithComponent = !!cloneData.componentUID;

  const modalTitle = useMemo(() => {
    if (isCloneGraph) {
      return 'Duplicate App';
    } else if (isCloneTemplate) {
      return 'Create app from Template';
    } else if (isNewGraphWithComponent) {
      return 'Create app with component';
    }
  }, [isCloneGraph, isCloneTemplate, isNewGraphWithComponent]);

  const onClose = useCallback(() => {
    setShowCreateAppModal(null);
  }, [setShowCreateAppModal]);

  const onSuccess = useCallback(
    (graph: Graph) => {
      const graphUrl = makeGraphURL(graph.uid, graph.slug);
      Router.push({ pathname: graphUrl });
      onClose();
    },
    [onClose]
  );

  const {
    mutate: cloneGraph,
    error,
    isLoading: isCloneLoading,
  } = useDuplicateGraph({
    onMutate: () => {
      showToast(isCloneGraph ? 'Duplicating app' : 'Creating app from template', true);
    },
    onSuccess: onSuccess,
    onError: () => {
      showToast(isCloneGraph ? 'Error duplicating app' : 'Error creating app from template', false);
    },
  });

  const {
    mutate: createGraph,
    error: createGraphError,
    isLoading: isCreateLoading,
  } = useCreateGraph({
    onMutate: () => showToast('Creating app with component', true),
    onSuccess: onSuccess,
    onError: () => showToast('Error creating app with component', false),
  });

  const onSubmit = async (data: CreateGraphFormData) => {
    if (isNewGraphWithComponent) {
      createGraph({
        organization_uid: cloneData.orgUID,
        ...data,
        storage_uid: data.database,
        component_uid: cloneData.componentUID,
      });
    } else {
      let versionUID = cloneData.graphVersionUID;
      if (!versionUID) {
        const latestGraphVersion = await getLatestGraphVersion(cloneData.graphUID!);
        versionUID = latestGraphVersion.version_uid;
      }
      cloneGraph({
        graph_version_uid: versionUID,
        organization_uid: cloneData.orgUID,
        ...data,
        storage_uid: data.database,
      });
    }
  };

  const showToast = useCallback(
    (description: string, isSuccess: boolean) => {
      toast({
        description: description,
        status: isSuccess ? 'success' : 'error',
        isClosable: true,
        duration: isSuccess ? 3000 : undefined,
      });
    },
    [toast]
  );

  const formMethods = useForm<CreateGraphFormData>({
    defaultValues: {
      theme: cloneData.theme || 'default',
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = formMethods;

  useEffect(() => {
    if (cloneData) {
      setValue('title', cloneData.title || '');
    }
  }, [cloneData, setValue]);

  const errorMessage = useMemo(() => {
    if (error instanceof Error) {
      return error.message;
    } else if (createGraphError instanceof Error) {
      return createGraphError.message;
    }
    return null;
  }, [error, createGraphError]);

  const handleNewDatabase = async () => {
    setOrganization(cloneData.orgUID);
    Router.push('/databases').then(() => {
      setDatabaseModalProps({ action: 'create' });
    });
  };

  return (
    <ModalComponent
      size="xl"
      showFooter={false}
      title={modalTitle}
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <P2 color="text3" mb={4}>
        This app will be cloned into your workspace. Optionally rename the app, select a database
        and compute environment and get building!
      </P2>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl id="title" isInvalid={!!errors?.title}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="My App"
                data-testid="graph-name-input"
                {...register('title', { required: true })}
              />
              {errors.title && <FormErrorMessage>This field is required</FormErrorMessage>}
            </FormControl>

            <FormControl id="database" mb={4} isInvalid={!!errors?.database}>
              <FormLabel>Database</FormLabel>
              <FormHelperText mb={3}>
                The data for this app will be stored in the chosen database. You can still connect
                to and import/export data from external databases to this app.
              </FormHelperText>
              <DatabaseSelect orgUID={cloneData.orgUID} />
              <Button variant="link" onClick={handleNewDatabase}>
                Add a new database
              </Button>
              {errors.database && <FormErrorMessage>This field is required</FormErrorMessage>}
            </FormControl>

            <FormControl id="compute" mb={4} isInvalid={!!errors?.compute}>
              <FormLabel>Compute</FormLabel>
              <ComputeSelect />
              {errors.compute && <FormErrorMessage>This field is required</FormErrorMessage>}
            </FormControl>

            <FormControl>
              <FormLabel>Vega Theme</FormLabel>

              <Select size="sm" {...register('theme', { required: true })}>
                {config.vegaThemes.map((theme) => (
                  <option key={theme.value} value={theme.value}>
                    {theme.label}
                  </option>
                ))}
              </Select>
              <P2 fontSize={'xs'} my={2}>
                Choose which theme you would like to use with your charts.
              </P2>
            </FormControl>

            {errorMessage && (
              <P2 mt={2} color="red.600">
                {errorMessage}
              </P2>
            )}
          </VStack>
          <Button
            mt={6}
            mb={2}
            data-testid={'button-create-graph'}
            type="submit"
            size="md"
            w="100%"
            isLoading={isCloneLoading || isCreateLoading}
          >
            Create App
          </Button>
        </form>
      </FormProvider>
    </ModalComponent>
  );
}

export default CloneGraphModal;

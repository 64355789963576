import React from 'react';
import { Image } from '@chakra-ui/react';
import { colors } from 'styles/colors';

import { NodeData } from 'types';
import { getSVGPath } from 'utils/connections';
import NodeIcon from 'views/Graph/modules/GraphView/NodeTypes/components/NodeIcon';

type ComponentIconProps = {
  connection?: string;
  iconUrl?: string;
};

export const ComponentIcon = ({ connection, iconUrl }: ComponentIconProps) => {
  const iconSrc = connection ? `/images/connections/${getSVGPath(connection)}.svg` : iconUrl;

  if (!iconSrc) {
    return (
      <NodeIcon
        nodeId="ghost"
        position="relative"
        nodeData={{ type: 'component_ref' } as NodeData}
        color={colors.light.text1}
      />
    );
  }

  return <Image src={iconSrc || ''} height="20px" minWidth="20px" alt="icon" />;
};

import { Wrap } from '@chakra-ui/react';

import { MarketplaceConnection } from 'types/api';

import ConnectionItem from './ConnectionItem';

type ConnectionsProps = {
  connections: MarketplaceConnection[];
  onClick?: (uid: string) => void;
};

function ConnectionsList({ connections, onClick }: ConnectionsProps) {
  return (
    <Wrap spacing={4}>
      {connections.map((connection) => (
        <ConnectionItem
          key={connection.title}
          title={connection.title}
          description={connection.description}
          onClick={onClick}
        />
      ))}
    </Wrap>
  );
}

export default ConnectionsList;

import { BiCaretDown } from 'react-icons/bi';
import { chakra } from '@chakra-ui/system';

const CaretDownIcon = chakra(BiCaretDown);

export const selectStyles = {
  h: 8,
  color: 'gray.900',
  icon: <CaretDownIcon fontSize="18px" fill="gray.700" />,
};

export const defaultComputeValue = 'patterns-cloud';

import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import shallow from 'zustand/shallow';

import useStore from 'state';
import { AppState } from 'types';
import { Connection, ConnectionType } from 'types/api';
import useConnectionAuthorize from 'views/Connections/hooks/useConnectionAuthorize';
import { AuthFormValues } from 'views/Connections/hooks/useCreateConnection';
import useCreateCredentialsConnection from 'views/Connections/hooks/useCreateCredentialsConnection';

type ConnectionOptions = {
  formValues?: AuthFormValues;
  onSuccess?: (connectionResult?: Connection) => void;
  onError?: (error?: AxiosError | string) => void;
};

const selector = (state: AppState) => ({
  connectionsModalProps: state.connectionsModalProps,
  setConnectionsModalProps: state.setConnectionsModalProps,
});

export default function useConnect() {
  const { connectionsModalProps, setConnectionsModalProps } = useStore(selector, shallow);
  const authPopup = useConnectionAuthorize();
  const toast = useToast();
  const createCredentialsConnection = useCreateCredentialsConnection();

  const handleError = useCallback(
    (connectionType: ConnectionType, error: AxiosError | string) => {
      setConnectionsModalProps({
        ...connectionsModalProps,
        connectingTo: {
          connectionType,
          failure: typeof error === 'string' ? error : error.message,
        },
      });
    },
    [connectionsModalProps, setConnectionsModalProps]
  );

  const connect = useCallback(
    (connectionType: ConnectionType, { formValues, onSuccess, onError }: ConnectionOptions) => {
      if (connectionType.auth_mechanism === 'creds') {
        if (!formValues) return;
        const { organizationUID } = useStore.getState();
        if (!organizationUID) return;
        createCredentialsConnection.mutate(
          {
            connectionType,
            organizationUid: organizationUID,
            params: formValues,
          },
          {
            onSuccess: (connectionResult) => {
              onSuccess?.(connectionResult);
              connectionsModalProps?.onSuccess?.(connectionResult);
              setConnectionsModalProps({
                ...connectionsModalProps,
                show: false,
                connectingTo: undefined,
                onSuccess: undefined,
              });
              toast({
                description: `Successfully connected to ${connectionResult.connection_type_description}`,
                status: 'success',
                isClosable: true,
              });
            },
            onError: (error) => {
              onError?.(error);
              handleError(connectionType, error);
            },
          }
        );
      } else {
        authPopup({
          connectionType,
          formValues,
          onSuccess: (connectionResult) => {
            onSuccess?.(connectionResult);
            connectionsModalProps?.onSuccess?.(connectionResult);
            setConnectionsModalProps({
              ...connectionsModalProps,
              show: false,
              connectingTo: undefined,
              onSuccess: undefined,
            });
            toast({
              description: connectionResult?.connection_type_description
                ? `Successfully connected to ${connectionResult?.connection_type_description}`
                : 'Successfully connected',
              status: 'success',
              isClosable: true,
            });
          },
          onError: (error) => {
            onError?.(error);
            handleError(connectionType, error);
          },
        });
      }
    },
    [
      createCredentialsConnection,
      connectionsModalProps,
      setConnectionsModalProps,
      toast,
      handleError,
      authPopup,
    ]
  );

  return connect;
}

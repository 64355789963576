import { MouseEvent, ReactNode } from 'react';
import { As, ChakraProps, Heading, Text } from '@chakra-ui/react';

type TextProps = ChakraProps & {
  children: ReactNode;
  id?: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
};

type HeadingFactoryProps = ChakraProps & {
  as: As;
  size: string;
};

function getHeading({ as, size, ...factoryRest }: HeadingFactoryProps) {
  const HeadingWrapper = ({ children, ...rest }: TextProps) => {
    return (
      <Heading as={as} fontSize={size} {...factoryRest} {...rest}>
        {children}
      </Heading>
    );
  };

  return HeadingWrapper;
}

type TextFactoryProps = ChakraProps & {
  size: string;
};

function getText({ size, ...factoryRest }: TextFactoryProps) {
  const TextWrapper = ({ children, ...rest }: TextProps) => {
    return (
      <Text fontSize={size} {...factoryRest} {...rest}>
        {children}
      </Text>
    );
  };

  return TextWrapper;
}

const H1 = getHeading({ as: 'h1', size: '4xl' });
const H2 = getHeading({ as: 'h2', size: 'xl' });
const H3 = getHeading({ as: 'h3', size: 'lg' });
const H4 = getHeading({ as: 'h4', size: 'md' });
const H5 = getHeading({ as: 'h5', size: 'sm' });
const H6 = getHeading({ as: 'h6', size: 'xs', fontWeight: 600, textTransform: 'uppercase' });

const P1 = getText({ size: 'md' });
const P2 = getText({ size: 'sm' });
const Label = getText({ size: 'xs' });

export { H1, H2, H3, H4, H5, H6, P1, P2, Label };

import { useCallback, useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useBoolean,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  errorMessage?: string | undefined;
  resetErrorMessage: () => void;
};

function OldGraphVersionAlertDialog({ errorMessage, resetErrorMessage }: Props) {
  const [isOpen, setIsOpen] = useBoolean(false);
  const [isLoading, setIsLoading] = useBoolean(false);
  const cancelRef = useRef(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (errorMessage) {
      setIsLoading.off();
      setIsOpen.on();
    }
  }, [errorMessage, setIsOpen, setIsLoading]);

  const onReload = useCallback(async () => {
    setIsLoading.on();
    try {
      await queryClient.invalidateQueries(['graph']);
    } finally {
      resetErrorMessage();
      setIsOpen.off();
      setIsLoading.off();
    }
  }, [queryClient, setIsLoading, setIsOpen, resetErrorMessage]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={setIsOpen.off}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Old Graph Version Detected
          </AlertDialogHeader>

          <AlertDialogBody>
            We've detected that you have an outdated version of your graph. Please click the button
            to load the latest version.
          </AlertDialogBody>

          <AlertDialogFooter justifyContent="center">
            <Button onClick={onReload} isLoading={isLoading}>
              Reload
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default OldGraphVersionAlertDialog;

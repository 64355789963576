import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Flex } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import useStore from 'state';
import { ConnectionType } from 'types/api';
import { findAuthForm } from 'views/Connections/Extra';
import { AuthFormValues } from 'views/Connections/hooks/useCreateConnection';

type props = {
  connectionType: ConnectionType;
  onSubmit(p: AuthFormValues): void;
};

function AuthForm({ connectionType, onSubmit }: props) {
  const f = useForm();
  const authForm = useMemo(() => findAuthForm(connectionType), [connectionType])!;

  const [connectionModalProps, setConnectionModalProps] = useStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  return (
    <form onSubmit={f.handleSubmit(onSubmit)}>
      {authForm.element(f)}
      <Flex justify="flex-end" w="100%" gap={2} mt={5}>
        <Button
          variant="outline"
          size="sm"
          onClick={() =>
            setConnectionModalProps({
              ...connectionModalProps,
              action: 'edit',
            })
          }
        >
          Back
        </Button>
        <Button size="sm" type="submit">
          Connect
        </Button>
      </Flex>
    </form>
  );
}

export default AuthForm;

import create from 'zustand';

import {
  AppState,
  ConnectionModalProps,
  ConnectionsModalProps,
  CreateAppModalProps,
  DatabaseModalProps,
  SecretsModalProps,
  views,
} from 'types';

const useStore = create<AppState>((set, get) => ({
  organizationUID: null,
  showConnectionsModal: false,
  secretsModalProps: { show: false },
  connectionsModalProps: { show: false },
  connectionModalProps: {},
  databaseModalProps: { action: 'hidden' },
  showCreateOrgModal: false,
  showCreateAppModal: null,
  view: 'graph',
  secretsToDelete: [],

  setOrganizationUID: (organizationUID: string | null) => {
    set({
      organizationUID,
    });
  },

  setConnectionsModalProps: (connectionsModalProps: ConnectionsModalProps) => {
    //This will reset the connection prompt when hidden
    if (!connectionsModalProps.show) {
      set({
        connectionsModalProps: {
          show: false,
        },
      });
    } else {
      set({
        connectionsModalProps,
      });
    }
  },

  setConnectionModalProps: (connectionModalProps: ConnectionModalProps) => {
    set({
      connectionModalProps,
    });
  },

  setSecretsModalProps: (secretsModalProps: SecretsModalProps) => {
    set({
      secretsModalProps,
    });
  },

  setDatabaseModalProps: (databaseModalProps: DatabaseModalProps) => {
    set({
      databaseModalProps,
    });
  },

  setShowCreateOrgModal: (showCreateOrgModal: boolean) => {
    set({
      showCreateOrgModal,
    });
  },

  setShowCreateAppModal: (showCreateAppModal: CreateAppModalProps | null) => {
    set({
      showCreateAppModal,
    });
  },
  setView: (view: views) => {
    set({
      view,
    });
  },

  setSecretsToDelete: (secretsToDelete) => {
    set({
      secretsToDelete,
    });
  },
}));

export default useStore;

import { ReactNode, useState } from 'react';
import { FieldValues, RegisterOptions, UseFormReturn, ValidationRule } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';

import { selectStyles } from 'components/CloneGraphModal/common';

export type TFormProps = {
  name: string;
  children?: ReactNode;
  label?: string;
  showLabel?: boolean;
  isRequired?: boolean;
  form: UseFormReturn<FieldValues, object>;
};

export function TForm(props: TFormProps) {
  return (
    <FormControl
      isInvalid={props.name in props.form.formState.errors}
      isRequired={isRequired(props.isRequired)}
    >
      {props.showLabel === undefined || props.showLabel ? (
        <FormLabel>
          {props.label ?? props.name.charAt(0).toUpperCase() + props.name.slice(1)}
        </FormLabel>
      ) : null}
      {props.children}
      <ErrorMessage errors={props.form.formState.errors} name={props.name} as={FormErrorMessage} />
    </FormControl>
  );
}

export type TFormInputProps = {
  type?: string;
  placeholder?: string;
  pattern?: ValidationRule<RegExp>;
  maxLength?: number;
  disabled?: boolean;
} & TFormProps;

export function TFormInput(props: TFormInputProps) {
  const registerOptions: RegisterOptions<FieldValues, string> = {};
  if (isRequired(props.isRequired)) {
    registerOptions.required = 'This field is required';
  }
  if (props.pattern) {
    registerOptions.pattern = props.pattern;
  }
  if (props.maxLength !== undefined) {
    registerOptions.maxLength = {
      value: props.maxLength,
      message:
        props.name.charAt(0).toUpperCase() +
        props.name.slice(1) +
        ' must be ' +
        props.maxLength +
        ' characters or less',
    };
  }

  return (
    <TForm {...props}>
      <Input
        disabled={props.disabled ?? false}
        type={props.type ?? 'text'}
        placeholder={props.placeholder ?? ''}
        {...props.form.register(props.name, registerOptions)}
      />
      {props.children}
    </TForm>
  );
}

export function TFormInputPassword(props: TFormInputProps) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <TForm {...props}>
      <InputGroup size="md" alignItems="center" justifyContent="center">
        <Input
          disabled={props.disabled ?? false}
          type={show ? 'text' : 'password'}
          placeholder={props.placeholder ?? ''}
          {...props.form.register(props.name, {
            required: isRequired(props.isRequired),
          })}
        />
        <InputRightElement width="4.5rem" alignItems="center" justifyContent="center">
          <Button variant="outline" size="sm" onClick={handleClick} background="white">
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      </InputGroup>
      {props.children}
    </TForm>
  );
}

export type TFormSelectOption = {
  value: string;
  label: string;
};

export type TFormSelectProps = {
  defaultValue?: string;
  options: TFormSelectOption[];
} & TFormProps;

export function TFormSelect(props: TFormSelectProps) {
  return (
    <TForm {...props}>
      <Select
        {...selectStyles}
        {...props.form.register(props.name, { required: isRequired(props.isRequired) })}
        defaultValue={props.defaultValue}
      >
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {props.children}
    </TForm>
  );
}

function isRequired(isRequired?: boolean): boolean {
  return isRequired === undefined ? true : isRequired;
}

import { Box, Flex, HStack, SimpleGrid, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { MarketplaceComponent } from 'types/api';
import { capitalize } from 'utils';

import ComponentIcon from './ComponentIcon';

type ComponentsProps = {
  components: MarketplaceComponent[];
  columns?: number;
  setItemUID: (c: string | null) => void;
};

function ComponentsList({ components, columns = 1, setItemUID }: ComponentsProps) {
  return (
    <SimpleGrid rowGap={2} gridGap={4} columns={columns}>
      {components.map((component) => (
        <Box
          as="a"
          w="full"
          _hover={{ opacity: 0.8 }}
          key={component.uid}
          onClick={() => {
            setItemUID(component.uid);
          }}
        >
          <HStack alignItems="center" borderBottom="1px" borderBottomColor="gray.100" pb={1}>
            <ComponentIcon title={component.title} icon={component.icon_url} />

            <VStack spacing={1} align="flex-start" w="full" pl={1}>
              <Flex alignItems="baseline">
                <Text fontSize="16px" lineHeight="1.5" fontWeight={700} color="dark.bg1">
                  {component.title}
                </Text>

                <Text ml={2} fontSize="14px" lineHeight="1.5" fontWeight={500} color="light.text3">
                  {`v${component.version} ${
                    component.organization_slug !== 'patterns'
                      ? '- ' + capitalize(component.organization_slug)
                      : ''
                  }`}
                </Text>
              </Flex>

              <Wrap spacing={2} maxHeight={'48px'} overflow={'hidden'}>
                {component.tags.map((tag) => (
                  <WrapItem
                    key={tag}
                    borderRadius="full"
                    border="1px"
                    borderColor="border1"
                    color="text3"
                    px={3}
                    fontSize="12px"
                    _hover={{ cursor: 'pointer' }}
                  >
                    #{tag}
                  </WrapItem>
                ))}
              </Wrap>
            </VStack>
          </HStack>
        </Box>
      ))}
    </SimpleGrid>
  );
}

export default ComponentsList;

import { NodeStatus } from 'types';

import { getHoverProps, ShapeProps } from './common';

type CustomPathProps = Omit<ShapeProps, 'width'> & { pathData: string };

function CustomPath({
  pathData,
  fill,
  strokeColor,
  status,
  strokeWidth,
  showSuccessAnimation,
  setShowSuccessAnimation,
  hoverClass,
}: CustomPathProps) {
  return (
    <>
      <path
        d={pathData}
        visibility={[NodeStatus.Success, NodeStatus.Error].includes(status) ? 'visible' : 'hidden'}
        fill={strokeColor}
        className={showSuccessAnimation ? 'single-ping' : ''}
        onAnimationEnd={() => setShowSuccessAnimation?.(false)}
      />
      <path d={pathData} {...getHoverProps(hoverClass)} fill={strokeColor} className={hoverClass} />
      <path
        d={pathData}
        fill={strokeColor}
        className={status === NodeStatus.Running ? 'running' : ''}
      />
      <path
        d={pathData}
        stroke={strokeColor}
        className={status === NodeStatus.Running ? 'running' : ''}
        fill={fill}
        strokeWidth={strokeWidth}
      />
    </>
  );
}

export default CustomPath;

import { useCallback } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { Flex, Icon, Text } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import ConfirmationModal from 'components/ConfirmationModal';
import useDeleteConnection from 'hooks/api/useDeleteConnection';
import useStore from 'state';

function DeleteConnectionConfirmation() {
  const [connectionModalProps, setConnectionModalProps] = useStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { connection } = connectionModalProps;

  const closeModal = useCallback(() => {
    setConnectionModalProps({});
  }, [setConnectionModalProps]);

  const { mutate: deleteConnection, isLoading } = useDeleteConnection({
    onSuccess: closeModal,
  });

  const onConfirm = () => {
    if (!connection) return;
    deleteConnection(connection.uid);
  };

  if (!connection) return <></>;

  return (
    <ConfirmationModal
      onClose={closeModal}
      onConfirm={onConfirm}
      title="Delete connection"
      isLoading={isLoading}
      body={
        <>
          <Text>Are you sure you want to delete this connection?</Text>
          <Flex alignItems="center" mb={2} gap="5px">
            <Icon as={FiTrash2} color="nodeError" />
            {connection.name}
          </Flex>
          <Text>You can't undo this action.</Text>
        </>
      }
    />
  );
}

export default DeleteConnectionConfirmation;

import { ReactNode, useMemo, useState } from 'react';
import { createStandaloneToast } from '@chakra-ui/react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import OldGraphVersionAlertDialog from 'components/OldGraphVersionAlertDialog';
import { isProduction } from 'config';

type CustomQueryClientProviderProps = {
  children: ReactNode;
};

function CustomQueryClientProvider({ children }: CustomQueryClientProviderProps) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { toast } = createStandaloneToast();

  const queryClient = useMemo(() => {
    const toasty = (error: any) => {
      const errorMessage = String(error.message);
      // skip for incomplete setup and inverified email
      if (errorMessage === 'incomplete setup' || errorMessage === 'unverified email') {
        return;
      }

      // if we have an old graph version, then show an alert
      if (errorMessage?.startsWith('Attempted to update graph_version')) {
        setErrorMessage(error.message);
        return;
      }

      // skip for graph 404 or 403 errors
      if (
        error?.response?.config?.url?.startsWith('/graphs') &&
        (error?.response?.status === 404 || error?.response?.status === 403)
      ) {
        return;
      }

      // skip 422 errors from check username endpoint
      if (
        error?.response?.config?.url?.startsWith('/accounts/check_available_name') &&
        error?.response?.status === 422
      ) {
        return;
      }

      // skip 422 errors from check org slug endpoint
      if (
        error?.response?.config?.url?.startsWith('/organizations/check_available_name') &&
        error?.response?.status === 422
      ) {
        return;
      }

      // skip for storage connection errors
      if (error?.response?.config?.url?.startsWith('/storage') && error?.response?.status === 424) {
        return;
      }

      // skip for invalid invites since we already show an error message on the screen
      if (errorMessage.startsWith('OrganizationInvitation matching query does not exist')) {
        return;
      }

      let duration = null;
      if (errorMessage.startsWith('You can only resend an invitation email once every hour.')) {
        duration = 5000;
      }

      if (typeof error.message) {
        // if we're not on prod or we get a 402 (UsageLimitExceeded) then show an error toast
        if (!isProduction || error?.response?.status === 402) {
          const description = JSON.stringify(error.message) || 'An error occurred.';
          // prevent duplicate toasts
          if (!toast.isActive(description)) {
            toast({
              id: description,
              description: description,
              status: 'error',
              isClosable: true,
              duration: duration,
            });
          }
        }
      }
      console.error(error.message || 'An error occurred.');
    };
    return new QueryClient({
      queryCache: new QueryCache({
        onError: toasty,
      }),
      mutationCache: new MutationCache({
        onError: toasty,
      }),
      defaultOptions: {
        queries: {
          staleTime: Infinity,
        },
      },
    });
    // Important: don't put anything in the deps array. It's important that the query client only gets created once
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <OldGraphVersionAlertDialog
        errorMessage={errorMessage}
        resetErrorMessage={() => {
          setErrorMessage(undefined);
        }}
      />
    </QueryClientProvider>
  );
}

export default CustomQueryClientProvider;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Code, FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function SquareAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Square Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="API Key" form={form}>
          <FormHelperText>
            The API key can be created follow{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://developer.squareup.com/docs/build-basics/access-tokens"
            >
              this guide
            </a>
          </FormHelperText>
          <FormHelperText>
            Note: Your API Key should have read access to the following scopes:
            <Code>Loyalty, Orders, Customers, Payments, Catalog</Code>
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const SquareAuth: AuthFormDef = {
  connectionType: 'square',
  element: SquareAuthFormElement,
};

export default SquareAuth;

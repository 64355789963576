import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInput } from 'components/TForm';
import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function BigCommerceAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your BigCommerce Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="api_key" label="Access Token" form={form}>
          <FormHelperText>
            To obtain an access token follow{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://support.bigcommerce.com/s/article/Store-API-Accounts?language=en_US#creating"
            >
              this guide
            </a>
          </FormHelperText>
        </TFormInputPassword>

        <TFormInput
          name="test_store_hash"
          form={form}
          label="Test Store Hash"
          placeholder="store_hash"
        >
          <FormHelperText>
            This store hash is only used to test the connection.{' '}
            <a rel="noreferrer" target="_blank" href="https://www.atensoftware.com/p90.php?q=333">
              This article
            </a>{' '}
            explains where to find your store hash.
          </FormHelperText>
        </TFormInput>
      </VStack>
    </>
  );
}

const BigCommerceAuth: AuthFormDef = {
  connectionType: 'bigcommerce',
  element: BigCommerceAuthFormElement,
};

export default BigCommerceAuth;

import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { AxiosError, AxiosResponse } from 'axios';

import useParseGraphZip from 'hooks/api/useParseGraphZip';
import Axios, { AxiosApiNoAuth } from 'utils/Axios';

const TESTING = process.env.NEXT_PUBLIC_ENV === 'test';

// extends Axios headers with auth0 token
function AxiosExtend() {
  const { getAccessTokenSilently } = useAuth0();

  const { parseGraphVersion } = useParseGraphZip();

  useEffect(() => {
    const interceptor = Axios.interceptors.request.use(async (config) => {
      try {
        const token = await getAccessTokenSilently();
        config.headers = {
          ...(config.headers || {}),
          Authorization: `JWT ${token}`,
        };
      } catch (e: any) {
        console.error(e.message);
      }

      return config;
    });
    return () => {
      // clean up interceptor on code reload
      Axios.interceptors.request.eject(interceptor);
    };
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const onFulfilled = async (response: AxiosResponse) => {
      const parsedResponse = await parseGraphVersion(response);

      return Promise.resolve(parsedResponse);
    };

    const onError = async (error: AxiosError<any>) => {
      if (error?.response?.data?.detail) {
        try {
          if (typeof error.message === 'string') {
            error.message = error?.response?.data?.detail;
          } else {
            error.message = JSON.stringify(error?.response?.data?.detail);
          }
        } catch (e) {
          error.message = error?.response?.data?.detail;
        }
      }

      if (error.response?.status === 422) {
        // log 422 to sentry
        if (!TESTING) {
          Sentry.captureException(error);
        }
      }

      return Promise.reject(error);
    };

    const interceptor = Axios.interceptors.response.use(onFulfilled, onError);
    const interceptorNoAuth = AxiosApiNoAuth.interceptors.response.use(onFulfilled, onError);

    return () => {
      // clean up interceptor on code reload
      Axios.interceptors.response.eject(interceptor);
      AxiosApiNoAuth.interceptors.response.eject(interceptorNoAuth);
    };
  }, [parseGraphVersion]);

  return null;
}

export default AxiosExtend;

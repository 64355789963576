import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';

import useAccount, { AccountError } from 'hooks/api/useAccount';
import { Account } from 'types/api';

const AuthLogoutRedirectUri: string = process.env.NEXT_PUBLIC_AUTH_LOGOUT_REDIRECT_URI!;

type AuthContextValue = {
  account: Account | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  authError?: Error;
  accountError?: AccountError | null;
  logout: () => void;
};

const initialValue: AuthContextValue = {
  account: null,
  isAuthenticated: false,
  isLoading: false,
  logout: () => {},
};

const AuthContext = createContext<AuthContextValue>(initialValue);

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const queryClient = useQueryClient();
  const {
    isAuthenticated,
    logout: auth0Logout,
    error: authError,
    isLoading: isLoadingAuth,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  /** Refresh the auth0 token if email verification changes */
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({ ignoreCache: true });
    }
  }, [getAccessTokenSilently, user?.email_verified, isAuthenticated]);

  const {
    data: account,
    isInitialLoading: isLoadingAccount,
    error: accountError,
  } = useAccount({
    enabled: !!isAuthenticated,
  });

  const logout = useCallback(() => {
    auth0Logout({ returnTo: AuthLogoutRedirectUri });
    queryClient.getQueryCache().clear();
  }, [auth0Logout, queryClient]);

  const isLoading = useMemo(() => {
    return isLoadingAuth || isLoadingAccount;
  }, [isLoadingAuth, isLoadingAccount]);

  useEffect(() => {
    if (!isAuthenticated) {
      Sentry.configureScope((scope) => scope.setUser(null));
      return;
    }

    if (!account) return;

    // set the user for sentry
    Sentry.setUser({
      email: account.email || '',
      id: account.uid || '',
      username: account.username || '',
    });
  }, [account, isAuthenticated]);

  const value: AuthContextValue = useMemo(
    () => ({
      account: account || null,
      isAuthenticated,
      isLoading,
      logout,
      authError,
      accountError,
    }),
    [account, isAuthenticated, isLoading, logout, authError, accountError]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error('AuthContext must be wrapped with an AuthProvider');
  }
  return context;
}

export default AuthProvider;

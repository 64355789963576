import { useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { OrganizationGraphsPaginated } from 'types/api';
import { useGraphsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (s: AppState) => s.organizationUID;

function useGraphs(
  pageSize = 40,
  organizationUID: string | null = null,
  options?: UseInfiniteQueryOptions<OrganizationGraphsPaginated>
) {
  const organizationUIDstate = useStore(selector);

  const orgUID = organizationUID || organizationUIDstate;
  const url = useGraphsURL(orgUID);

  const result = useInfiniteQuery<OrganizationGraphsPaginated>(
    ['graphs', orgUID],
    async ({ pageParam = 1 }) => {
      const res = await Axios.get<OrganizationGraphsPaginated>(url, {
        params: { page_size: pageSize, page: pageParam },
      });

      return res.data;
    },
    {
      enabled: !!orgUID,
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        return !!lastPage.next && allPages.length + 1;
      },
      getPreviousPageParam: (firstPage, allPages) => {
        return firstPage.previous && allPages.length - 1;
      },
    }
  );

  return result;
}

export default useGraphs;

import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CloudStorage, CloudStorageLink } from 'types/api';
import { useLinkStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StorageHierarchyQueryKey, StoragesQueryKey } from 'utils/queryKeys';

function useLinkStorage() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const url = useLinkStorageURL();

  return useMutation<CloudStorage, AxiosError, CloudStorageLink>(async (data: CloudStorageLink) => {
    const res = await Axios.post<CloudStorage>(url, data);
    queryClient.invalidateQueries(StoragesQueryKey());
    queryClient.invalidateQueries(StorageHierarchyQueryKey());

    toast({
      description: 'Successfully linked database',
      status: 'success',
      isClosable: true,
      duration: 3000,
    });

    return res.data;
  });
}

export default useLinkStorage;

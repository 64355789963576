import { FieldValues, UseFormReturn } from 'react-hook-form';
import {
  FormHelperText,
  FormLabel,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
} from '@chakra-ui/react';

import { TForm, TFormInput } from 'components/TForm';
import { BigQueryCloudStorageConfig } from 'types/api';

import { DatabaseFormMode, StorageType } from './DatabaseTypes';

export function BigQueryElement(form: UseFormReturn<FieldValues, object>, mode: DatabaseFormMode) {
  return (
    <>
      <TFormInput name="bigQueryProjectName" form={form} />
      <TFormInput name="bigQueryDatasetName" form={form} />
      <TForm name={'bigQueryJson'} form={form} showLabel={false}>
        <FormLabel>
          Service Account Private Key (<Text as="samp">.json</Text>)
        </FormLabel>
        <Textarea
          placeholder='{"type": "service_account", "project_id": ...}'
          {...form.register('bigQueryJson', {
            required: 'This field is required',
            validate: (value) => {
              try {
                JSON.parse(value);
              } catch (e) {
                return 'Invalid JSON';
              }
            },
          })}
        />
        <FormHelperText>
          Paste the contents of your Service Account Private Key here. A Service Account Private Key
          is a .json file created in the Google Cloud Console which provides access to BigQuery. It
          can be created with
          <a href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#iam-service-account-keys-create-console">
            <Text as="em"> these instructions</Text>.
          </a>
          <br />
          <br />
          In addition, the service account must be granted the following roles:
          <UnorderedList>
            <ListItem>BigQuery Data Owner</ListItem>
            <ListItem>BigQuery Job User</ListItem>
            <ListItem>BigQuery Read Session Use</ListItem>
          </UnorderedList>
        </FormHelperText>
      </TForm>
    </>
  );
}

export function makeBigQueryConfig(formValues: any): BigQueryCloudStorageConfig {
  const { bigQueryJson, bigQueryProjectName, bigQueryDatasetName } = formValues;
  return {
    type: 'bigquery',
    project_name: bigQueryProjectName,
    dataset_name: bigQueryDatasetName,
    private_key_json: bigQueryJson,
  };
}

export const BigQueryStorageType: StorageType = {
  name: 'bigquery',
  label: 'BigQuery',
  element: BigQueryElement,
  makeConfig: makeBigQueryConfig,
  tease_production: true,
};

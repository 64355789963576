import { useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';

import { useAuth } from 'contexts/AuthContext';
import { useOrganizationAppEffects } from 'hooks/useOrganization';

import { PrefetchMarketplace } from './PrefetchMarketplace';

type AppEffectsProps = {
  pathname: string;
};

// put any effects that need to be run on every page here
export default function AppEffects({ pathname }: AppEffectsProps) {
  useOrganizationAppEffects();
  const { account, isAuthenticated } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  // if colorMode is set to dark, toggle it to light
  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  return (
    <>
      {account?.uid &&
        isAuthenticated &&
        (pathname.startsWith('/home') ||
          pathname.startsWith('/marketplace') ||
          pathname.startsWith('/graph')) && <PrefetchMarketplace />}
    </>
  );
}

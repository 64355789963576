import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { Graph } from 'types/api';
import { useCreateGraphsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

export type CreateGraphData = {
  title: string;
  storage_uid: string;
  compute: string;
  organization_uid: string;
  theme?: null | string;
  component_uid?: null | string;
};

const url = useCreateGraphsURL();

function useCreateGraph(
  options?: UseMutationOptions<Graph, unknown, CreateGraphData>
): UseMutationResult<Graph, unknown, CreateGraphData, unknown> {
  const queryClient = useQueryClient();

  const mutation = useMutation(async (data: CreateGraphData) => {
    const result = await Axios.post<Graph>(url, data);

    queryClient.invalidateQueries(['graphs']);

    if (result.data.version_uid) {
      // Keeping track of the latest graph version updates for shitty multiplayer support
      const { setUpdatedGraphVersionUID } = useStore.getState();
      setUpdatedGraphVersionUID(result.data.version_uid);
    }

    return result?.data;
  }, options);

  return mutation;
}

export default useCreateGraph;

import { Box, HStack, Spinner, Text } from '@chakra-ui/react';

import { MarketplaceAppTemplate, MarketplaceComponent, MarketplaceConnection } from 'types/api';
import AppsList from 'views/Marketplace/Apps/AppsList';
import ComponentsList from 'views/Marketplace/Components/ComponentsList';
import ConnectionsList from 'views/Marketplace/Connections/ConnectionsList';

type itemsType = MarketplaceAppTemplate[] | MarketplaceComponent[] | MarketplaceConnection[];

type SectionProps = {
  category: 'apps' | 'connections' | 'components';
  title?: string;
  items: itemsType;
  columns?: number;
  isLoading?: boolean;
  setCategory?: (category: string) => void;
  setItemUID: (uid: string | null) => void;
  itemShowCount?: number | null;
};

function Section({
  category,
  title,
  items,
  columns,
  isLoading = false,
  setCategory,
  setItemUID,
  itemShowCount = null,
}: SectionProps) {
  const renderItems = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (items.length === 0) {
      return (
        <Text color="dark.bg3" fontSize="sm" fontWeight={500}>
          No {(title || category).toLowerCase()} found
        </Text>
      );
    }

    const trimmedItems: itemsType = itemShowCount ? items.slice(0, itemShowCount) : items;

    switch (category) {
      case 'connections':
        return (
          <ConnectionsList
            connections={trimmedItems as MarketplaceConnection[]}
            onClick={(connectionUid) => setItemUID(connectionUid)}
          />
        );
      case 'apps':
        return <AppsList apps={trimmedItems as MarketplaceAppTemplate[]} onClick={setItemUID} />;
      case 'components':
        return (
          <ComponentsList
            components={trimmedItems as MarketplaceComponent[]}
            columns={columns}
            setItemUID={setItemUID}
          />
        );

      default:
        break;
    }
  };

  return (
    <Box w="full">
      {!!title && (
        <HStack borderBottom="1px" borderBottomColor="#E9ECEF" py={3} mb={4}>
          <Text fontSize="14px" fontWeight={700} lineHeight="20px" color="dark.bg1">
            {title}
            {items.length > 0 && (
              <Text as="span" color="light.text3">
                {' '}
                ({items.length})
              </Text>
            )}
          </Text>

          {itemShowCount && (
            <Box
              as="a"
              fontSize="11px"
              lineHeight="1.6"
              letterSpacing="0.5px"
              color="action"
              onClick={() => {
                setCategory && setCategory(category.toLowerCase());
              }}
            >
              See more
            </Box>
          )}
        </HStack>
      )}

      {renderItems()}
    </Box>
  );
}

export default Section;

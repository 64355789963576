import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CloudStorage } from 'types/api';
import { useStorageScanURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StorageHierarchyQueryKey, StorageQueryKey, StoragesQueryKey } from 'utils/queryKeys';

export type StorageScanProps = {
  storageUid: string;
  organizationUid: string;
};

function useStorageScan() {
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, StorageScanProps>(async (props: StorageScanProps) => {
    const res = await Axios.post<CloudStorage>(useStorageScanURL(props.storageUid));
    queryClient.invalidateQueries(StorageQueryKey(props.storageUid));
    queryClient.invalidateQueries(StoragesQueryKey());
    queryClient.invalidateQueries(StorageHierarchyQueryKey());
    return res.data;
  });
}

export default useStorageScan;

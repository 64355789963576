import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { useConnectionURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useDeleteConnection(options: UseMutationOptions<any, unknown, string> = {}) {
  const queryClient = useQueryClient();

  return useMutation(async (connectionUid: string) => {
    const url = useConnectionURL(connectionUid);
    const res = await Axios.delete(url);
    queryClient.invalidateQueries(['connections']);

    return res?.data;
  }, options);
}

export default useDeleteConnection;

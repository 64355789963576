import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

import ModalComponent from 'components/Modal';
import RadioCardGroup from 'components/RadioCardGroup';
import { P2 } from 'components/Typography';
import useCheckOrgSlugAvailable, {
  SlugAvailableResponse,
} from 'hooks/api/useCheckOrgSlugAvailable';
import useCreateFreeOrganization from 'hooks/api/useCreateFreeOrganization';
import useCreatePaidOrganization, {
  CreateOrganizationData,
} from 'hooks/api/useCreatePaidOrganization';
import useStore from 'state';

import PlanCard from './PlanCard';
import { PLANS } from './pricing';

function CreateOrganizationModal() {
  const [setShowCreateOrgModal, setOrganizationUID] = useStore((state) => [
    state.setShowCreateOrgModal,
    state.setOrganizationUID,
  ]);

  const [validationError, setValidationError] = useState<string>('');
  const [slugAvailable, setSlugAvailable] = useState('unknown'); // unknown, available, unavailable, checking

  const { mutate: checkSlugAvailable } = useCheckOrgSlugAvailable({
    onSuccess: (data: SlugAvailableResponse) => {
      setSlugAvailable(data.available === true ? 'available' : 'unavailable');
    },
    onError: (err) => {
      if (err.response?.status === 422) {
        setValidationError(typeof err.message === 'string' ? err.message : '');
        setSlugAvailable('invalid');
      } else {
        setSlugAvailable('unknown');
      }
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<CreateOrganizationData>();

  const {
    mutate: createPaidOrganization,
    error: paidOrgError,
    isLoading: isPaidLoading,
  } = useCreatePaidOrganization({
    onSuccess: (responseData) => {
      window.location.href = responseData.url;
    },
  });

  const {
    mutate: createFreeOrganization,
    error: freeOrgError,
    isLoading: isFreeLoading,
  } = useCreateFreeOrganization();

  const onSubmit = (data: CreateOrganizationData) => {
    if (data.type === 'free') {
      createFreeOrganization(data);
    } else if (data.type === 'pro') {
      createPaidOrganization(data);
    }
  };

  const errorMessage = useMemo(() => {
    if (freeOrgError || paidOrgError) {
      return (freeOrgError || paidOrgError)?.message;
    }
  }, [freeOrgError, paidOrgError]);

  useEffect(() => {
    reset();
  }, [reset]);

  const watchSlug = watch('slug');

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (watchSlug !== '') {
      setSlugAvailable('checking');
      timeoutId = setTimeout(() => {
        checkSlugAvailable(watchSlug);
      }, 500);
    } else {
      setSlugAvailable('unknown');
    }

    return () => {
      clearInterval(timeoutId);
    };
  }, [watchSlug, checkSlugAvailable]);

  const options = PLANS.filter((plan) => plan.creatable).map((plan) => {
    return {
      value: plan.name,
      children: <PlanCard {...plan} />,
    };
  });

  return (
    <ModalComponent
      isOpen
      size="3xl"
      onClose={() => setShowCreateOrgModal(false)}
      title="Create Organization"
      showFooter={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="slug" mb={4} isInvalid={!!errors?.slug} isRequired={true}>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="my-org-name"
            type=""
            data-testid="org-name-input"
            isInvalid={['unavailable', 'invalid'].includes(slugAvailable)}
            focusBorderColor={
              ['unavailable', 'invalid'].includes(slugAvailable) ? 'red' : undefined
            }
            {...register('slug', { required: true })}
          />
          {errors.slug && <FormErrorMessage>This field is required</FormErrorMessage>}
          {slugAvailable === 'invalid' && typeof validationError === 'string' && (
            <P2 mt={2} color="red.600">
              {validationError}
            </P2>
          )}
          {slugAvailable === 'unavailable' && (
            <P2 mt={2} color="red.600">
              Org name is already taken
            </P2>
          )}
        </FormControl>

        <FormControl id="description" mb={4} isInvalid={!!errors?.description}>
          <FormLabel>Description</FormLabel>
          <Input
            data-testid="org-description-input"
            placeholder="description"
            type=""
            {...register('description', { required: false })}
          />
        </FormControl>

        <FormControl id="type" isInvalid={!!errors.type} isRequired={true}>
          <FormLabel>Type</FormLabel>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioCardGroup options={options} name={field.name} onChange={field.onChange} />
            )}
          />
        </FormControl>

        {errorMessage && (
          <P2 mt={2} color="red.600">
            {errorMessage}
          </P2>
        )}

        <Button
          data-testid="org-submit"
          type="submit"
          size="md"
          w="100%"
          isLoading={isFreeLoading || isPaidLoading}
          isDisabled={isFreeLoading || isPaidLoading || slugAvailable !== 'available'}
          mt={4}
          mb={2}
        >
          Create
        </Button>
      </form>
    </ModalComponent>
  );
}

export default CreateOrganizationModal;

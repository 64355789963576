import { FieldValues, UseFormReturn } from 'react-hook-form';

import { ConnectionType } from 'types/api';
import AirtableAuthForm from 'views/Connections/AuthForms/AirtableAuthForm';
import BigCommerceAuthForm from 'views/Connections/AuthForms/BigCommerceAuthForm';
import CohereAuthForm from 'views/Connections/AuthForms/CohereAuthForm';
import CustomerioAuthForm from 'views/Connections/AuthForms/CustomerioAuthForm';
import MailgunAuthForm from 'views/Connections/AuthForms/MailgunAuthForm';
import OpenAIAuthForm from 'views/Connections/AuthForms/OpenAIAuthForm';
import PeopleDataLabsAuthForm from 'views/Connections/AuthForms/PeopleDataLabsAuthForm';
import SalesforceAuthForm from 'views/Connections/AuthForms/SalesforceAuthForm';
import ShopifyAuthForm from 'views/Connections/AuthForms/ShopifyAuthForm';
import SlackbotAuthForm from 'views/Connections/AuthForms/SlackbotAuthForm';
import SquareAuthForm from 'views/Connections/AuthForms/SquareAuthForm';
import StripeAuthForm from 'views/Connections/AuthForms/StripeAuthForm';
import TwitterAuthForm from 'views/Connections/AuthForms/TwitterAuthForm';

export type AuthFormDef = {
  connectionType: string;
  element(form: UseFormReturn<FieldValues, object>): JSX.Element;
};

const authFormDefs: AuthFormDef[] = [
  AirtableAuthForm,
  BigCommerceAuthForm,
  CohereAuthForm,
  CustomerioAuthForm,
  MailgunAuthForm,
  OpenAIAuthForm,
  PeopleDataLabsAuthForm,
  SalesforceAuthForm,
  ShopifyAuthForm,
  SlackbotAuthForm,
  SquareAuthForm,
  StripeAuthForm,
  TwitterAuthForm,
];

export function findAuthForm(connectionType: ConnectionType): AuthFormDef | null {
  return authFormDefs.find((t) => t.connectionType === connectionType.connection_type) || null;
}

export function hasAuthForm(connectionType: ConnectionType): boolean {
  return !!findAuthForm(connectionType);
}

import { MouseEvent, ReactNode, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
} from '@chakra-ui/react';

type ModalButtonProps = ButtonProps & {
  'data-testid'?: string;
};

type ConfirmationModalProps = {
  isOpen?: boolean;
  title: ReactNode;
  body: ReactNode;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClose: (event?: MouseEvent) => void;
  onConfirm: (event: MouseEvent) => void;
  cancelButtonProps?: ModalButtonProps;
  confirmButtonProps?: ModalButtonProps;
};

function ConfirmationModal({
  isOpen = true,
  title,
  body,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Delete',
  isLoading = false,
  isDisabled = false,
  onConfirm,
  onClose,
  cancelButtonProps,
  confirmButtonProps,
}: ConfirmationModalProps) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              aria-label="cancel"
              colorScheme="lightgray"
              onClick={onClose}
              {...cancelButtonProps}
            >
              {cancelButtonLabel}
            </Button>
            <Button
              onClick={onConfirm}
              aria-label="confirm"
              ml={3}
              isLoading={isLoading}
              disabled={isDisabled}
              {...confirmButtonProps}
            >
              {confirmButtonLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ConfirmationModal;

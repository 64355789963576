import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Box, Heading } from '@chakra-ui/react';

import { TFormSelect } from 'components/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function SalesforceAuthFormElement(form: UseFormReturn<FieldValues, object>) {
  const options = [
    { value: 'login', label: 'Production' },
    { value: 'test', label: 'Sandbox' },
  ];

  return (
    <>
      <Heading>Configure your Salesforce Account</Heading>
      <Box mt={5}>Patterns needs additional information to connect to your Salesforce account.</Box>

      <TFormSelect
        name="login_or_test"
        form={form}
        options={options}
        defaultValue={'login'}
        label="Environment"
      />
    </>
  );
}

const SalesforceAuth: AuthFormDef = {
  connectionType: 'salesforce',
  element: SalesforceAuthFormElement,
};

export default SalesforceAuth;

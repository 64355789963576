import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { CloudStorageListItemPaginated } from 'types/api';
import { useStoragesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StoragesQueryKey } from 'utils/queryKeys';

const selector = (s: AppState) => s.organizationUID;

function useStorages(
  orgUID?: string | null,
  options?: UseQueryOptions<CloudStorageListItemPaginated>
) {
  const queryClient = useQueryClient();
  let organizationUID = useStore(selector);
  if (orgUID) {
    organizationUID = orgUID;
  }

  const url = useStoragesURL(organizationUID);

  return useQuery<CloudStorageListItemPaginated>(
    ['storages', organizationUID],
    async () => {
      const res = await Axios.get<CloudStorageListItemPaginated>(url, {
        params: { page_size: 100, page: 1 },
      });

      const storages = res.data;
      let refresh = false;
      for (const storage of storages.results) {
        if (storage.scan_requested_at) {
          // The storage is currently being worked on.  Let's invalidate the query and have it rechecked
          refresh = true;
          break;
        }
      }

      if (refresh) {
        setTimeout(() => {
          queryClient.invalidateQueries(StoragesQueryKey());
        }, 3000);
      }

      return res.data;
    },
    {
      enabled: !!organizationUID,
      ...options,
    }
  );
}

export default useStorages;

import React, { MouseEvent, ReactNode, useCallback } from 'react';
import { MenuItem, MenuProps, useBoolean } from '@chakra-ui/react';

import ConfirmationModal from 'components/ConfirmationModal';
import useDeleteGraph from 'hooks/api/useDeleteGraph';

type DeleteGraphMenuItemProps = MenuProps & {
  uid: string;
  title: string;
  children: ReactNode;
};

function DeleteGraphMenuItem({ uid, title, children, ...rest }: DeleteGraphMenuItemProps) {
  const [isOpen, setIsOpen] = useBoolean(false);
  const { mutate, isLoading } = useDeleteGraph({
    onSuccess: () => {
      setIsOpen.off();
    },
  });

  const onDelete = useCallback(
    (evt?: MouseEvent) => {
      evt?.stopPropagation();
      evt?.preventDefault();
      mutate(uid);
    },
    [mutate, uid]
  );

  return (
    <>
      <MenuItem
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          setIsOpen.on();
        }}
        {...rest}
      >
        {children}
      </MenuItem>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={setIsOpen.off}
        isLoading={isLoading}
        title={`Delete App: ${title}`}
        body="Are you sure? You can't undo this action."
        onConfirm={onDelete}
      />
    </>
  );
}

export default DeleteGraphMenuItem;

import { useCallback, useEffect, useState } from 'react';
import { FiLink2 } from 'react-icons/fi';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Progress,
} from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import DateDisplay from 'components/DateDisplay';
import Modal from 'components/Modal';
import PageTitle from 'components/PageTitle';
import { P1 } from 'components/Typography';
import useTestConnection from 'hooks/api/useTestConnection';
import useStore from 'state';

type failedConnectionProps = {
  testOnLoad: boolean;
};

function DebugConnectionModal(props: failedConnectionProps) {
  const [connectionModalProps, setConnectionModalProps] = useStore(
    (state) => [state.connectionModalProps, state.setConnectionModalProps],
    shallow
  );

  const { mutate: testConnection, isLoading: isTesting } = useTestConnection();
  const { connection, error } = connectionModalProps;

  const [testOnLoad, setTestOnLoad] = useState(props.testOnLoad);

  const test = useCallback(() => {
    if (!connection) return;
    return testConnection(connection.uid, {
      onSuccess: (connection) => {
        setConnectionModalProps({
          ...connectionModalProps,
          connection,
          error: undefined,
        });
        setTestOnLoad(false);
      },
      onError: (e) => {
        const msg = e['message'] || 'Something went wrong';
        setConnectionModalProps({
          error: 'Error testing connection: ' + msg,
          connection,
        });
        console.error('Failed to test connection', e);
      },
    });
  }, [connection, connectionModalProps, setConnectionModalProps, testConnection]);

  useEffect(() => {
    if (testOnLoad) {
      test();
    }
  }, [testOnLoad, test]);

  if (!connection) return <></>;

  return (
    <Modal
      onClose={() => setConnectionModalProps({})}
      customFooter={
        <Flex justify="center" w="100%">
          <Button size="sm" onClick={test} mr={5} data-testid="test-connection-button">
            Test
          </Button>

          <Button
            size="sm"
            onClick={() =>
              setConnectionModalProps({
                ...connectionModalProps,
                action: 'reconnect',
              })
            }
            data-testid="fix-connection-button"
            variant="outline"
          >
            Reconnect
          </Button>
        </Flex>
      }
      blockScrollOnMount={false}
    >
      <Box py={3}>
        <PageTitle title={`Connection: ${connection.name}`} icon={FiLink2} />
      </Box>

      {isTesting && (
        <Box>
          <Alert status="info">
            <AlertIcon />
            <AlertTitle>Connecting to: {connection.connection_type_description}</AlertTitle>
          </Alert>
          <Progress size="xs" isIndeterminate />
        </Box>
      )}

      {!isTesting && !error && !connection.failed_msg && (
        <Alert status="success">
          <AlertIcon />
          <Flex flexDir="column">
            <AlertTitle>Good!</AlertTitle>
            <AlertDescription>
              The connection to {connection.connection_type_description} appears to be working
              normally.
            </AlertDescription>
          </Flex>
        </Alert>
      )}

      {!isTesting && !error && connection.failed_msg && (
        <Box>
          <Alert status="error">
            <AlertIcon />
            <Flex flexDir="column">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{connection.failed_msg}</AlertDescription>
            </Flex>
          </Alert>
          <P1>
            The connection to {connection.connection_type_description} failed{' '}
            {connection.failed_at && <DateDisplay date={connection?.failed_at} />}.
          </P1>
          <br />
          <P1>
            Apps using this connection will not work until it is fixed. Click below to reauthorize{' '}
            {connection.connection_type_description}.
          </P1>
        </Box>
      )}

      {error && (
        <Box>
          <Alert status="error">
            <AlertIcon />
            <Flex flexDir="column">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Flex>
          </Alert>
        </Box>
      )}
    </Modal>
  );
}

export default DebugConnectionModal;

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useCheckOrgSlugAvailableURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

export type SlugAvailableResponse = {
  name: string;
  available: boolean;
};

function useCheckOrgSlugAvailable(
  options?: UseMutationOptions<SlugAvailableResponse, AxiosError, string>
) {
  const url = useCheckOrgSlugAvailableURL();
  return useMutation(async (slug: string) => {
    const data = { name: slug };
    const res = await Axios.post<SlugAvailableResponse>(url, data);
    return res?.data;
  }, options);
}

export default useCheckOrgSlugAvailable;

import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { useAuth } from 'contexts/AuthContext';
import { checkInIframe } from 'hooks/useInIframe';

declare global {
  interface Window {
    Atlas: any;
  }
}

export default function Atlas() {
  const { account } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (typeof window === 'undefined' || !window.Atlas || checkInIframe()) {
      return;
    }

    (async () => {
      const displayName = `${account?.first_name || 'Unknown'} ${account?.last_name || 'Unknown'}`;

      if (window.Atlas.started) {
        await window.Atlas.shutdown();
      }

      await window.Atlas?.start({
        chat: {
          hideBubble: false,
          position: router.pathname.includes('/graph') ? 'bottomLeft' : 'bottomRight',
        },
      });

      if (account) {
        await window.Atlas.identify({
          userId: account.uid,
          name: account.username,
          email: account.email,
          fields: {
            accountName: displayName,
          },
        });
      }
    })();
  }, [account, router.pathname]);

  return (
    <Script
      strategy="afterInteractive"
      async={true}
      id="atlas-chat"
      dangerouslySetInnerHTML={{
        __html: `
          (()=>{"use strict";const t={appId:"${process.env.NEXT_PUBLIC_ATLAS_APP_ID}",v:2,q:[],start:function(o){this.autorun=o||!0},identify:function(t){this.q.push(["identify",t])}};window.Atlas=t;const e=document.createElement("script");e.async=!0,e.src="https://app.getatlas.io/client-js/atlas.bundle.js";const s=document.getElementsByTagName("script")[0];s.parentNode?.insertBefore(e,s)})();
        `,
      }}
    />
  );
}

import useWebhooks from 'hooks/api/useWebhooks';

import Markdown from './Markdown';

type WebhookUrlProps = {
  nodeId: string;
};

const WebhookUrl = ({ nodeId }: WebhookUrlProps) => {
  const { data: webhook, isLoading } = useWebhooks(nodeId);

  if (isLoading || !webhook?.results || webhook.results?.length <= 0) return <></>;

  return (
    <Markdown>{`
  \`\`\`bash
  ${webhook.results[0].webhook_url}
  \`\`\`
`}</Markdown>
  );
};

export default WebhookUrl;

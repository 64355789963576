import { SVGProps } from 'react';
import { colors } from 'styles/colors';

import { NodeStatus } from 'types';

export type ShapeProps = {
  width: number;
  height?: number;
  strokeColor: string;
  strokeWidth: number;
  fill: string;
  status: NodeStatus;
  showSuccessAnimation?: boolean;
  setShowSuccessAnimation?: (show: boolean) => void;
  hoverClass?: string;
  isForm?: boolean;
};

export const getHoverProps = (
  hoverClass?: string
): SVGProps<SVGRectElement & SVGCircleElement & SVGPathElement & SVGPolygonElement> => ({
  className: hoverClass,
  pointerEvents: 'none',
  stroke: colors.gray[500],
  strokeOpacity: 0.2,
});

export const getAnimatedShapeProps = (): SVGProps<
  SVGRectElement & SVGCircleElement & SVGPathElement & SVGPolygonElement
> => {
  return {
    stroke: 'none',
    fill: colors.light.nodeRunning,
    fillOpacity: 0.5,
  };
};

import config from 'config';
import { Port } from 'types';
import { ManifestNode, NodeType } from 'types/api';

export const NODE_WIDTH = 40;
export const NODE_HEIGHT = 40;

export function getDimensionByNodeType(nodeType: NodeType): {
  width: number | string;
  height: number | string;
} {
  switch (nodeType) {
    case 'python':
    case 'sql':
      // Makes our diamond look like the same shape as our square
      return { width: 60, height: 60 };
    case 'table_store':
    case 'stream_store':
    case 'chart':
      return { width: 40, height: 40 };
    case 'component_ref':
    case 'graph':
      return { width: 50, height: 40 };
    case 'webhook':
      return { width: 40, height: 55 };
    case 'markdown':
      return { width: 300, height: 'auto' };
    default:
      return { width: 40, height: 40 };
  }
}

// @TODO: Do we want to have title attributes for store nodes in the graph yml?
// If not, the backend should write the table name to the title field
export function getNodeName(node: ManifestNode, outputs: Port[] = []) {
  if (!node.node_type.includes('store')) {
    return node.title;
  }

  const portName = outputs?.[0]?.name;

  return portName || node.title;
}
export function hexToRgb(hex: string | null): null | number[] {
  if (!hex) {
    return null;
  }

  // @ts-ignore
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_: string, r: string, g: string, b: string) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x: string) => parseInt(x, 16));
}

export function clamp(val: number, min: number = 0, max: number = 1): number {
  return Math.min(Math.max(val, min), max);
}

export function getColorById(colorId: string | null, isDarkMode = false): string {
  let matchingColor = config.nodeColors.find((n) => n.id === colorId);

  if (!matchingColor) {
    matchingColor = config.nodeColors.find((n) => n.id === 'default');
  }

  return isDarkMode ? matchingColor!.valueDark : matchingColor!.valueLight;
}

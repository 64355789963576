import { CSSProperties } from 'react';
import { MonacoTheme } from 'monaco-editor';
import { colors } from 'styles/colors';

const backgroundColor = '#DBDBDC';
const backgroundColor2 = '#EAEAEB';

export const editorColors = {
  magenta: '#A626A4',
  grey: '#A0A1A7',
  green: '#50A14F',
  blue: '#0184BC',
  white: '#FAFAFA',
  dark: '#383A42',
};

export type SyntaxTheme = {
  [key: string]: CSSProperties;
};

export const syntaxLight: SyntaxTheme = {
  'hljs': {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: 'translucent',
    color: editorColors.dark,
  },
  'hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-selector-tag': {
    color: editorColors.magenta,
  },
  'hljs-literal': {
    color: editorColors.magenta,
  },
  'hljs-section': {
    color: editorColors.magenta,
  },
  'hljs-link': {
    color: editorColors.magenta,
  },
  'hljs-function .hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-subst': {
    color: editorColors.dark,
  },
  'hljs-string': {
    color: editorColors.green,
  },
  'hljs-title': {
    color: editorColors.dark,
  },
  'hljs-name': {
    color: editorColors.green,
  },
  'hljs-type': {
    color: editorColors.green,
  },
  'hljs-attribute': {
    color: editorColors.green,
  },
  'hljs-symbol': {
    color: editorColors.green,
  },
  'hljs-bullet': {
    color: editorColors.green,
  },
  'hljs-addition': {
    color: editorColors.green,
  },
  'hljs-variable': {
    color: editorColors.green,
  },
  'hljs-template-tag': {
    color: editorColors.green,
  },
  'hljs-template-variable': {
    color: editorColors.green,
  },
  'hljs-comment': {
    color: editorColors.grey,
  },
  'hljs-quote': {
    color: editorColors.green,
  },
  'hljs-deletion': {
    color: editorColors.green,
  },
  'hljs-meta': {
    color: editorColors.green,
  },
  'hljs-doctag': {
    fontWeight: 'bold',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
};

const theme: MonacoTheme = {
  inherit: false,
  base: 'vs',
  colors: {
    'activityBar.background': editorColors.white,
    'activityBar.foreground': '#121417',
    'activityBarBadge.background': '#526FFF',
    'activityBarBadge.foreground': '#FFFFFF',
    'button.background': '#5871EF',
    'button.foreground': '#FFFFFF',
    'button.hoverBackground': '#6B83ED',
    'diffEditor.insertedTextBackground': '#00809B33',
    'dropdown.background': '#FFFFFF',
    'dropdown.border': backgroundColor,
    'editorIndentGuide.activeBackground': '#626772',
    'editor.background': editorColors.white,
    'editor.foreground': editorColors.dark,
    'editor.lineHighlightBackground': '#383A420C',
    'editor.selectionBackground': '#E5E5E6',
    'editorCursor.foreground': '#526FFF',
    'editor.findMatchHighlightBackground': '#526FFF33',
    'editorGroup.background': backgroundColor2,
    'editorGroup.border': backgroundColor,
    'editorGroupHeader.tabsBackground': backgroundColor2,
    'editorIndentGuide.background': '#383A4233',
    'editorLineNumber.foreground': '#9D9D9F',
    'editorLineNumber.activeForeground': editorColors.dark,
    'editorWhitespace.foreground': '#383A4233',
    'editorRuler.foreground': '#383A4233',
    'editorHoverWidget.background': backgroundColor2,
    'editorHoverWidget.border': backgroundColor,
    'editorSuggestWidget.background': backgroundColor2,
    'editorSuggestWidget.border': backgroundColor,
    'editorSuggestWidget.selectedBackground': colors.light.action,
    'editorWidget.background': backgroundColor2,
    'editorWidget.border': '#E5E5E6',
    'input.background': '#FFFFFF',
    'input.border': backgroundColor,
    'focusBorder': '#526FFF',
    'list.activeSelectionBackground': backgroundColor,
    'list.activeSelectionForeground': colors.light.bg1,
    'list.focusBackground': backgroundColor,
    'list.hoverBackground': '#DBDBDC66',
    'list.highlightForeground': '#121417',
    'list.inactiveSelectionBackground': backgroundColor,
    'list.inactiveSelectionForeground': '#232324',
    'notification.background': '#333333',
    'pickerGroup.border': '#526FFF',
    'scrollbarSlider.background': '#4E566680',
    'scrollbarSlider.activeBackground': '#747D9180',
    'scrollbarSlider.hoverBackground': '#5A637580',
    'sideBar.background': backgroundColor2,
    'sideBarSectionHeader.background': editorColors.white,
    'statusBar.background': backgroundColor2,
    'statusBar.foreground': '#424243',
    'statusBarItem.hoverBackground': backgroundColor,
    'statusBar.noFolderBackground': backgroundColor2,
    'tab.activeBackground': editorColors.white,
    'tab.activeForeground': '#121417',
    'tab.border': backgroundColor,
    'tab.inactiveBackground': backgroundColor2,
    'titleBar.activeBackground': backgroundColor2,
    'titleBar.activeForeground': '#424243',
    'titleBar.inactiveBackground': backgroundColor2,
    'titleBar.inactiveForeground': '#424243',
    'statusBar.debuggingForeground': '#FFFFFF',
    'extensionButton.prominentBackground': '#3BBA54',
    'extensionButton.prominentHoverBackground': '#4CC263',
    'badge.background': '#526FFF',
    'badge.foreground': '#FFFFFF',
    'peekView.border': '#526FFF',
    'peekViewResult.background': backgroundColor2,
    'peekViewResult.selectionBackground': backgroundColor,
    'peekViewTitle.background': '#FFFFFF',
    'peekViewEditor.background': '#FFFFFF',
  },
  rules: [
    {
      foreground: editorColors.grey,
      fontStyle: 'italic',
      token: 'comment',
    },
    {
      foreground: editorColors.grey,
      token: 'comment markup.link',
    },
    {
      foreground: editorColors.blue,
      token: 'delimiter.jinja',
    },
    {
      foreground: '#C18401',
      token: 'entity.name.type',
    },
    {
      foreground: '#C18401',
      token: 'entity.other.inherited-class',
    },
    {
      foreground: editorColors.magenta,
      token: 'keyword',
    },
    {
      foreground: editorColors.magenta,
      token: 'keyword.control',
    },
    {
      foreground: editorColors.dark,
      token: 'keyword.operator',
    },
    {
      foreground: '#4078F2',
      token: 'keyword.other.special-method',
    },
    {
      foreground: '#986801',
      token: 'keyword.other.unit',
    },
    {
      foreground: editorColors.magenta,
      token: 'storage',
    },
    {
      foreground: editorColors.magenta,
      token: 'storage.type.annotation',
    },
    {
      foreground: editorColors.magenta,
      token: 'storage.type.primitive',
    },
    {
      foreground: editorColors.dark,
      token: 'storage.modifier.package',
    },
    {
      foreground: editorColors.dark,
      token: 'storage.modifier.import',
    },
    {
      foreground: '#986801',
      token: 'constant',
    },
    {
      foreground: '#986801',
      token: 'constant.variable',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.character.escape',
    },
    {
      foreground: '#986801',
      token: 'constant.numeric',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.other.color',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.other.symbol',
    },
    {
      foreground: '#E45649',
      token: 'variable',
    },
    {
      foreground: '#CA1243',
      token: 'variable.interpolation',
    },
    {
      foreground: editorColors.dark,
      token: 'variable.parameter',
    },
    {
      foreground: editorColors.green,
      token: 'string',
    },
    {
      foreground: editorColors.dark,
      token: 'string > source',
    },
    {
      foreground: editorColors.dark,
      token: 'string embedded',
    },
    {
      foreground: editorColors.blue,
      token: 'string.regexp',
    },
    {
      foreground: '#C18401',
      token: 'string.regexp source.ruby.embedded',
    },
    {
      foreground: '#E45649',
      token: 'string.other.link',
    },
    {
      foreground: editorColors.grey,
      token: 'punctuation.definition.comment',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.method-parameters',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.function-parameters',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.parameters',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.separator',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.seperator',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.array',
    },
    {
      foreground: '#4078F2',
      token: 'punctuation.definition.heading',
    },
    {
      foreground: '#4078F2',
      token: 'punctuation.definition.identity',
    },
    {
      foreground: '#C18401',
      fontStyle: 'bold',
      token: 'punctuation.definition.bold',
    },
    {
      foreground: editorColors.magenta,
      fontStyle: 'italic',
      token: 'punctuation.definition.italic',
    },
    {
      foreground: '#CA1243',
      token: 'punctuation.section.embedded',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.section.method',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.section.class',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.section.inner-class',
    },
    {
      foreground: '#C18401',
      token: 'support.class',
    },
    {
      foreground: editorColors.blue,
      token: 'support.type',
    },
    {
      foreground: editorColors.blue,
      token: 'support.function',
    },
    {
      foreground: '#4078F2',
      token: 'support.function.any-method',
    },
    {
      foreground: '#4078F2',
      token: 'entity.name.function',
    },
    {
      foreground: '#C18401',
      token: 'entity.name.class',
    },
    {
      foreground: '#C18401',
      token: 'entity.name.type.class',
    },
    {
      foreground: '#4078F2',
      token: 'entity.name.section',
    },
    {
      foreground: '#E45649',
      token: 'entity.name.tag',
    },
    {
      foreground: '#986801',
      token: 'entity.other.attribute-name',
    },
    {
      foreground: '#4078F2',
      token: 'entity.other.attribute-name.id',
    },
    {
      foreground: '#C18401',
      token: 'meta.class',
    },
    {
      foreground: editorColors.dark,
      token: 'meta.class.body',
    },
    {
      foreground: editorColors.dark,
      token: 'meta.method-call',
    },
    {
      foreground: editorColors.dark,
      token: 'meta.method',
    },
    {
      foreground: '#E45649',
      token: 'meta.definition.variable',
    },
    {
      foreground: '#986801',
      token: 'meta.link',
    },
    {
      foreground: '#4078F2',
      token: 'meta.require',
    },
    {
      foreground: editorColors.magenta,
      token: 'meta.selector',
    },
    {
      foreground: editorColors.dark,
      token: 'meta.separator',
    },
    {
      foreground: editorColors.dark,
      token: 'meta.tag',
    },
    {
      foreground: editorColors.dark,
      token: 'none',
    },
    {
      foreground: '#000000',
      background: '#F2A60D',
      token: 'invalid.deprecated',
    },
    {
      foreground: '#FFFFFF',
      background: '#FF1414',
      token: 'invalid.illegal',
    },
    {
      foreground: '#986801',
      fontStyle: 'bold',
      token: 'markup.bold',
    },
    {
      foreground: editorColors.magenta,
      token: 'markup.changed',
    },
    {
      foreground: '#E45649',
      token: 'markup.deleted',
    },
    {
      foreground: editorColors.magenta,
      fontStyle: 'italic',
      token: 'markup.italic',
    },
    {
      foreground: '#E45649',
      token: 'markup.heading',
    },
    {
      foreground: '#4078F2',
      token: 'markup.heading punctuation.definition.heading',
    },
    {
      foreground: editorColors.blue,
      token: 'markup.link',
    },
    {
      foreground: editorColors.green,
      token: 'markup.inserted',
    },
    {
      foreground: '#986801',
      token: 'markup.quote',
    },
    {
      foreground: editorColors.green,
      token: 'markup.raw',
    },
    {
      foreground: '#A626A4',
      token: 'source.c keyword.operator',
    },
    {
      foreground: '#A626A4',
      token: 'source.cpp keyword.operator',
    },
    {
      foreground: '#A626A4',
      token: 'source.cs keyword.operator',
    },
    {
      foreground: editorColors.blue,
      token: 'source.flow keyword.operator',
    },
    {
      foreground: '#E45649',
      token: 'source.json meta.structure.dictionary.json > string.quoted.json',
    },
    {
      foreground: '#E45649',
      token: 'source.json meta.structure.dictionary.json > string.quoted.json > punctuation.string',
    },
    {
      foreground: editorColors.green,
      token: 'source.json meta.structure.dictionary.json > value.json > string.quoted.json',
    },
    {
      foreground: editorColors.green,
      token: 'source.json meta.structure.array.json > value.json > string.quoted.json',
    },
    {
      foreground: editorColors.green,
      token:
        'source.json meta.structure.dictionary.json > value.json > string.quoted.json > punctuation',
    },
    {
      foreground: editorColors.green,
      token:
        'source.json meta.structure.array.json > value.json > string.quoted.json > punctuation',
    },
    {
      foreground: editorColors.blue,
      token: 'source.json meta.structure.dictionary.json > constant.language.json',
    },
    {
      foreground: editorColors.blue,
      token: 'source.json meta.structure.array.json > constant.language.json',
    },
    {
      foreground: '#A626A4',
      token: 'source.python keyword.operator.logical.python',
    },
    {
      foreground: '#986801',
      token: 'source.python variable.parameter',
    },
    {
      foreground: editorColors.dark,
      token: 'customrule',
    },
    {
      foreground: editorColors.dark,
      token: 'support.type.property-name',
    },
    {
      foreground: editorColors.green,
      token: 'string.quoted.double punctuation',
    },
    {
      foreground: '#986801',
      token: 'support.constant',
    },
    {
      foreground: '#E45649',
      token: 'support.type.property-name.json',
    },
    {
      foreground: '#E45649',
      token: 'support.type.property-name.json punctuation',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.language.json',
    },
    {
      foreground: editorColors.dark,
      token: 'source.python constant.other',
    },
    {
      foreground: '#986801',
      token: 'source.python constant',
    },
    {
      foreground: '#986801',
      token: 'constant.character.format.placeholder.other.python storage',
    },
    {
      foreground: '#E45649',
      token: 'support.variable.magic.python',
    },
    {
      foreground: '#986801',
      token: 'meta.function.parameters.python',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.separator.annotation.python',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.separator.parameters.python',
    },
    {
      foreground: '#E45649',
      token: 'entity.name.variable.field.cs',
    },
    {
      foreground: editorColors.dark,
      token: 'source.cs keyword.operator',
    },
    {
      foreground: editorColors.dark,
      token: 'variable.other.readwrite.cs',
    },
    {
      foreground: editorColors.dark,
      token: 'variable.other.object.cs',
    },
    {
      foreground: editorColors.dark,
      token: 'variable.other.object.property.cs',
    },
    {
      foreground: '#4078F2',
      token: 'entity.name.variable.property.cs',
    },
    {
      foreground: '#C18401',
      token: 'storage.type.cs',
    },
    {
      foreground: editorColors.dark,
      token: 'markup.raw.block.markdown',
    },
    {
      foreground: '#E45649',
      token: 'punctuation.definition.variable.shell',
    },
    {
      foreground: '#E45649',
      token: 'entity.name.section.markdown',
    },
    {
      foreground: '#E45649',
      token: 'punctuation.definition.heading.markdown',
    },
    {
      foreground: editorColors.dark,
      token: 'markup.heading.setext',
    },
    {
      foreground: '#986801',
      token: 'punctuation.definition.bold.markdown',
    },
    {
      foreground: editorColors.green,
      token: 'markup.inline.raw.markdown',
    },
    {
      foreground: '#E45649',
      token: 'beginning.punctuation.definition.list.markdown',
    },
    {
      foreground: editorColors.grey,
      fontStyle: 'italic',
      token: 'markup.quote.markdown',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.string.begin.markdown',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.string.end.markdown',
    },
    {
      foreground: editorColors.dark,
      token: 'punctuation.definition.metadata.markdown',
    },
    {
      foreground: '#A626A4',
      token: 'punctuation.definition.metadata.markdown',
    },
    {
      foreground: '#A626A4',
      token: 'markup.underline.link.markdown',
    },
    {
      foreground: '#A626A4',
      token: 'markup.underline.link.image.markdown',
    },
    {
      foreground: '#4078F2',
      token: 'string.other.link.title.markdown',
    },
    {
      foreground: '#4078F2',
      token: 'string.other.link.description.markdown',
    },
    {
      foreground: '#E45649',
      token: 'punctuation.definition.variable.php',
    },
    {
      foreground: editorColors.dark,
      token: 'meta.class.php',
    },
  ],
  encodedTokensColors: [],
};

export default theme;

import { useEffect, useRef } from 'react';
import { Edge } from 'reactflow';
import shallow from 'zustand/shallow';

import appConfig from 'config';
import useGraph from 'hooks/api/useGraph';
import { GraphNode, GraphState } from 'types';
import { GraphManifest } from 'types/api';
import { toggleEdgeVisibility } from 'views/Graph/modules/GraphView/utils/layout';
import { createGraphLookup } from 'views/Graph/modules/GraphView/utils/manifest';
import useStore from 'views/Graph/state';

const selector = (s: GraphState) => ({
  setNodes: s.setNodes,
  setEdges: s.setEdges,
  graphLevel: s.graphLevel,
  setManifest: s.setManifest,
  setParsedGraphVersionUID: s.setParsedGraphVersionUID,
});

export async function parseGraphManifest(
  manifest: GraphManifest,
  graphLevel: string,
  hideNodes: boolean = false
): Promise<{ nodes: GraphNode[]; edges: Edge[] }> {
  const graphLookup = createGraphLookup(manifest);
  const { nodes, edges: graphEdges } = graphLookup[graphLevel] || {
    nodes: [],
    edges: [],
  };

  nodes.forEach((node) => {
    node.position = {
      x: (node.data?.display?.x || 0) * appConfig.graphGrid.x,
      y: (node.data?.display?.y || 0) * appConfig.graphGrid.y,
    };
  });

  const edges = toggleEdgeVisibility(graphEdges, nodes);

  // We hide the nodes and edges when the user changes the tree level
  // in order prevent flickering when we do the fit view
  if (hideNodes) {
    nodes.forEach((node) => {
      node.style = { ...node.style, opacity: 0 };
    });
    edges.forEach((edge) => {
      edge.style = { ...edge.style, opacity: 0 };
    });
  }

  return { nodes, edges };
}

/**
 * Primary purpose of this hook is to re-parse the manifest when the graph level or graph UID changes
 */
function useParseGraphManifest() {
  const { data: graph } = useGraph({
    select: (g) => ({ manifest: g.manifest, graphUID: g.version_uid }),
  });

  const prevGraphUID = useRef<string | null>(null);
  const prevGraphLevel = useRef<string | null>(null);
  const isInitial = useRef<boolean>(true);

  const { setNodes, setEdges, graphLevel, setManifest, setParsedGraphVersionUID } = useStore(
    selector,
    shallow
  );

  useEffect(() => {
    if (!graph) return;
    const handleManifest = async (hideNodes: boolean) => {
      const storeNodes = useStore.getState().nodes;
      const { nodes, edges } = await parseGraphManifest(graph?.manifest, graphLevel, hideNodes);

      nodes.forEach((node) => {
        const storeNode = storeNodes.find((n) => n.id === node.id);
        // we don't want to lose the dimensions of the node
        if (storeNode?.width && storeNode?.height) {
          node.width = storeNode.width;
          node.height = storeNode.height;
        }
      });

      setNodes(nodes);
      setEdges(edges);
      setManifest(graph.manifest);
      setParsedGraphVersionUID(graph.graphUID);

      isInitial.current = false;
    };

    const graphLevelChanged = prevGraphLevel.current !== graphLevel;
    const graphUIDChanged = prevGraphUID.current !== graph.graphUID || graphLevelChanged;

    if (graph.manifest && graph.graphUID && (graphUIDChanged || graphLevelChanged)) {
      prevGraphUID.current = graph.graphUID;
      prevGraphLevel.current = graphLevel;
      handleManifest(!isInitial.current && graphLevelChanged);
    }
  }, [graphLevel, setNodes, setEdges, setManifest, setParsedGraphVersionUID, graph]);

  useEffect(() => {
    return () => {
      prevGraphUID.current = null;
      prevGraphLevel.current = null;
    };
  }, []);

  return null;
}

export default useParseGraphManifest;

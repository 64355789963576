import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';

import config from 'config';
import { Graph } from 'types/api';
import { useGraphURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

function useDeleteGraph(options: UseMutationOptions<Graph, unknown, string> = {}) {
  const queryClient = useQueryClient();

  const result = useMutation<Graph, unknown, string>(async (graphUID: string) => {
    const url = useGraphURL(graphUID);
    const res = await Axios.delete<Graph>(url);

    Router.push(config.views.graphs.pathname);

    queryClient.invalidateQueries(['graphs']);

    if (res.data.version_uid) {
      // Keeping track of the latest graph version updates for shitty multiplayer support
      const { setUpdatedGraphVersionUID } = useStore.getState();
      setUpdatedGraphVersionUID(res.data.version_uid);
    }

    return res?.data;
  }, options);

  return result;
}

export default useDeleteGraph;

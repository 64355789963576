import { ReactNode } from 'react';
import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

export type RadioCardValue = string | number;

export type RadioCardProps = {
  value: RadioCardValue;
  children: ReactNode;
};

const RadioCard = (props: UseRadioProps & RadioCardProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" width={350}>
      <input {...input} />
      <Box
        {...checkbox}
        width="100%"
        cursor="pointer"
        borderWidth="2px"
        borderRadius="lg"
        boxShadow="md"
        _checked={{
          borderColor: 'blue.500',
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;

import { Box, Flex, Image } from '@chakra-ui/react';

import { NodeStatus } from 'types';
import Hexagon from 'views/Graph/modules/GraphView/NodeTypes/components/NodeShape/Hexagon';

type ComponentsProps = {
  title: string;
  icon?: string | null;
  size?: number;
  includeShape?: boolean;
};

function ComponentIcon({ title, icon, size = 70, includeShape = false }: ComponentsProps) {
  return (
    <Box position="relative" width={size} height={size}>
      {includeShape && (
        <svg width={size} height={size}>
          <Hexagon
            width={size}
            height={size}
            fill={'white'}
            strokeColor={'black'}
            strokeWidth={1.2}
            status={NodeStatus.None}
          ></Hexagon>
        </svg>
      )}
      <Flex
        position={'absolute'}
        width={size}
        height={size}
        left={0}
        top={0}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Image
          src={icon ? icon : `/node-subgraph-icon.svg`}
          alt={`${title} icon`}
          h={'40%'}
          onError={({ currentTarget }) => {
            // if iconURL is invalid -> just show generic subgraph icon?
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = `/node-subgraph-icon.svg`;
          }}
        />
      </Flex>
    </Box>
  );
}

export default ComponentIcon;

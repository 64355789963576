import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Connection } from 'types/api';
import { useTestConnectionURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useTestConnection() {
  const queryClient = useQueryClient();

  return useMutation<Connection, AxiosError, string>(async (connectionUid) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const url = useTestConnectionURL(connectionUid);
    const res = await Axios.post<Connection>(url);

    queryClient.invalidateQueries(['connections']);
    queryClient.invalidateQueries(['connections', connectionUid]);
    return res?.data;
  });
}

export default useTestConnection;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, Heading, VStack } from '@chakra-ui/react';

import { TFormInputPassword } from 'components/TForm/TForm';
import { AuthFormDef } from 'views/Connections/Extra';

function CustomerioAuthForm(form: UseFormReturn<FieldValues, object>) {
  return (
    <>
      <Heading>Configure Your Customer.io Account</Heading>

      <VStack mt={5}>
        <TFormInputPassword name="app_api_key" label="App API Key" form={form}>
          <FormHelperText>
            The App API key can be found in your Customer.io{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://fly.customer.io/settings/api_credentials?keyType=app"
            >
              Settings
            </a>
            .
          </FormHelperText>
        </TFormInputPassword>
        <TFormInputPassword name="tracking_api_key" label="Tracking API Key" form={form}>
          <FormHelperText>
            The Tracking API key can be found in your Customer.io{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://fly.customer.io/settings/api_credentials?keyType=app"
            >
              Settings
            </a>
            .
          </FormHelperText>
        </TFormInputPassword>
        <TFormInputPassword name="tracking_site_id" label="Tracking Site ID" form={form}>
          <FormHelperText>
            The Tracking Site ID can be found in your Customer.io{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://fly.customer.io/settings/api_credentials?keyType=app"
            >
              Settings
            </a>
            .
          </FormHelperText>
        </TFormInputPassword>
      </VStack>
    </>
  );
}

const CustomerioAuth: AuthFormDef = {
  connectionType: 'customerio',
  element: CustomerioAuthForm,
};

export default CustomerioAuth;

import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormHelperText, HStack, VStack } from '@chakra-ui/react';

import { TFormInput } from 'components/TForm';
import { TFormInputPassword } from 'components/TForm/TForm';
import { MysqlCloudStorageConfig, PostgresCloudStorageConfig } from 'types/api';

import { DatabaseFormMode, StorageType } from './DatabaseTypes';

function SqlElement(form: UseFormReturn<FieldValues, object>, mode: DatabaseFormMode) {
  const disabled = mode === 'show_managed';
  const isRequired = mode !== 'show_managed';

  return (
    <VStack spacing={0}>
      <HStack justify="space-between" p={4} w="full" alignItems="flex-start">
        <TFormInput name="host" form={form} disabled={disabled} isRequired={isRequired} />
        <TFormInput
          name="port"
          form={form}
          disabled={disabled}
          isRequired={isRequired}
          pattern={{
            value:
              /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
            message: 'This does not look like a port',
          }}
        />
      </HStack>

      <TFormInput name="username" form={form} disabled={disabled} isRequired={isRequired} />
      <TFormInputPassword
        name="password"
        form={form}
        isRequired={mode === 'create'}
        disabled={disabled}
      >
        {mode === 'edit' && (
          <FormHelperText>Leave blank to keep the existing password</FormHelperText>
        )}
      </TFormInputPassword>
      <TFormInput name="database" form={form} disabled={disabled} isRequired={isRequired} />
    </VStack>
  );
}

function makePostgresConfig(formValues: any): PostgresCloudStorageConfig {
  const { host, port, username, password, database } = formValues;
  return {
    type: 'postgres',
    host,
    port,
    username,
    password,
    database,
  };
}

function makeMysqlConfig(formValues: any): MysqlCloudStorageConfig {
  const { host, port, username, password, database } = formValues;
  return {
    type: 'mysql',
    host,
    port,
    username,
    password,
    database,
  };
}

export const PostgresStorageType: StorageType = {
  name: 'postgres',
  label: 'Postgres',
  element: SqlElement,
  makeConfig: makePostgresConfig,
  tease_production: false,
};

export const MySqlStorageType: StorageType = {
  name: 'mysql',
  label: 'MySQL',
  element: SqlElement,
  makeConfig: makeMysqlConfig,
  tease_production: true,
};

import { Box, ChakraProps, PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';

import { formatDate } from 'utils';

type DateDisplayProps = ChakraProps & {
  date: Date | string;
  tooltipPlacement?: PlacementWithLogical;
};

const isInvalidDate = (d: Date) => isNaN(d.getTime());

function DateDisplay({ date, tooltipPlacement = 'auto-start', ...rest }: DateDisplayProps) {
  const dateObject = typeof date === 'string' ? new Date(date) : date;

  if (!dateObject || isInvalidDate(dateObject)) {
    return null;
  }

  return (
    <Tooltip label={formatDate(dateObject)} placement={tooltipPlacement}>
      <Box as="span" {...rest}>
        {formatDistanceToNow(dateObject, { addSuffix: true })}
      </Box>
    </Tooltip>
  );
}

export default DateDisplay;

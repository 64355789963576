import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { WebhookPaginated } from 'types/api';
import { useWebhooksURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { WebhooksQueryKey } from 'utils/queryKeys';

type WebhookRequestParams = {
  node_id?: string;
};

const defaultOptions: UseQueryOptions<WebhookPaginated> = {
  staleTime: 60 * 1000,
};

function useWebhooks(nodeId: string, options: UseQueryOptions<WebhookPaginated> = defaultOptions) {
  const url = useWebhooksURL();
  const queryKey = WebhooksQueryKey(nodeId);

  const result = useQuery<WebhookPaginated>(
    queryKey,
    async () => {
      const params: WebhookRequestParams = nodeId ? { node_id: nodeId } : {};

      const res = await Axios.get<WebhookPaginated>(url!, { params });
      return res?.data;
    },
    { enabled: !!url && !!nodeId, ...options }
  );

  return result;
}

export default useWebhooks;

import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { As, Flex, Icon, Text } from '@chakra-ui/react';

import { H2 } from 'components/Typography';

type PageTitleProps = {
  title: string | null;
  icon?: ReactNode | IconType | null;
  description?: string;
};

function PageTitle({ title, icon, description }: PageTitleProps) {
  return (
    <Flex flexDir="column" gap={2}>
      <Flex align="center" gap={2}>
        {icon && <Icon as={icon as As} width="20px" height="20px" />}
        <H2 color="text1">{title}</H2>
      </Flex>
      <Text fontSize="14px" color="text2">
        {description}
      </Text>
    </Flex>
  );
}

export default PageTitle;

import { useToast } from '@chakra-ui/react';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Connection, ConnectionEditData } from 'types/api';
import { useConnectionURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useEditConnection(
  options: UseMutationOptions<Connection, AxiosError, ConnectionEditData> = {}
) {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation<Connection, AxiosError, ConnectionEditData>(
    async ({ connectionUid, name }: ConnectionEditData) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const url = useConnectionURL(connectionUid);
      const res = await Axios.patch<Connection>(url, { name });

      await queryClient.invalidateQueries(['connections']);
      await queryClient.invalidateQueries(['connections', connectionUid]);

      toast({
        status: 'success',
        title: 'Connection Updated',
        description: `You have successfully updated connection ${res?.data.name}`,
        duration: 3000,
        isClosable: true,
      });

      return res?.data;
    },
    options
  );
}

export default useEditConnection;

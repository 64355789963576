import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { MarketplaceComponentPaginated } from 'types/api';
import { useMarketplaceSearchComponentsUrl } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const url = useMarketplaceSearchComponentsUrl();

export default function useMarketplaceSearchComponents(
  searchSlug: string | null = null,
  tags: String[] | null = null,
  pageSize: number = 250,
  page: number = 1,
  options?: UseQueryOptions<MarketplaceComponentPaginated>
) {
  async function getPage(): Promise<MarketplaceComponentPaginated> {
    let params = {
      page_size: pageSize,
      search_slug: searchSlug,
      tags: tags || undefined,
      page: page,
    };
    if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog) {
      posthog.capture('marketplace-search-components', params);
    }
    const res = await Axios.get<MarketplaceComponentPaginated>(url, { params: params });
    return res.data;
  }

  return useQuery<MarketplaceComponentPaginated>(
    ['marketplace', 'components', 'search', pageSize, searchSlug, tags],
    getPage,
    options
  );
}

import { ReactNode } from 'react';
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import { P1 } from 'components/Typography';

type ModalComponentProps = Partial<ModalProps> & {
  isOpen?: boolean;
  title?: string;
  showFooter?: boolean;
  onClose: () => void;
  customFooter?: ReactNode;
  children: ReactNode;
  withDivider?: boolean;
  customHeader?: ReactNode;
  minWidth?: string | 'initial';
  modalBodyProps?: ModalBodyProps;
};

function ModalComponent({
  isOpen = true,
  showFooter = true,
  title,
  onClose,
  customFooter,
  children,
  withDivider,
  customHeader,
  minWidth = 'initial',
  modalBodyProps,
  ...rest
}: ModalComponentProps) {
  const header = customHeader ? (
    <ModalHeader p={0}>
      {customHeader}
      {withDivider && <Divider borderColor="border3" />}
    </ModalHeader>
  ) : (
    <>
      {title && (
        <ModalHeader pb={withDivider ? 0 : 2} px={withDivider ? 0 : 6}>
          <P1 mb={withDivider ? 4 : undefined} px={withDivider ? 6 : 0}>
            {title}
          </P1>
          {withDivider && <Divider borderColor="border3" />}
        </ModalHeader>
      )}

      <ModalCloseButton />
    </>
  );

  const footer = customFooter ? (
    <ModalFooter>{customFooter}</ModalFooter>
  ) : (
    <ModalFooter>
      <Button onClick={onClose}>Got it!</Button>
    </ModalFooter>
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent minWidth={minWidth ? minWidth : 'initial'}>
        {header}
        <ModalBody
          pt={withDivider ? 0 : undefined}
          marginBottom={!showFooter ? 5 : 0}
          {...modalBodyProps}
        >
          {children}
        </ModalBody>
        {showFooter && footer}
      </ModalContent>
    </Modal>
  );
}

export default ModalComponent;

import { useFormContext } from 'react-hook-form';
import { Select } from '@chakra-ui/react';

import { defaultComputeValue, selectStyles } from 'components/CloneGraphModal/common';

function ComputeSelect() {
  const { register } = useFormContext();

  return (
    <Select
      {...selectStyles}
      {...register('compute', { required: true })}
      defaultValue={defaultComputeValue}
    >
      <option value={defaultComputeValue}>Patterns Cloud (Google Cloud Run)</option>
      <option disabled>Other Compute Providers Coming Soon</option>
    </Select>
  );
}

export default ComputeSelect;

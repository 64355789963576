import React from 'react';
import { Box, Text } from '@chakra-ui/react';

/**
 * Copied from @patterns/content
 */
function PriceTag({ price }: { price: number | string }) {
  if (typeof price === 'number') {
    return (
      <Box>
        <Text fontSize="16px" lineHeight="1.2" color="light.text1" mb={2}>
          ${price}
          <Box as="span" color="dark.text3">
            /mo
          </Box>
        </Text>
      </Box>
    );
  }

  return (
    <Text fontSize="20px" lineHeight="1.1" fontWeight={700} color="light.text1" minH="71.2px">
      {price}
    </Text>
  );
}

export default PriceTag;

import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Select,
  Spinner,
} from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import ModalComponent from 'components/Modal';
import { P2 } from 'components/Typography';
import config from 'config';
import useDefaultStorage from 'hooks/api/useDefaultStorage';
import useGraphs from 'hooks/api/useGraphs';
import useUpdateGraphMetadata from 'hooks/api/useUpdateGraphMetadata';
import useStore from 'state';
import { AppState, VegaTheme } from 'types';
import { getLatestGraphVersion } from 'utils';

export type EditGraphInfoModalProps = {
  uid: string;
  slug: string;
  title: string;
  theme?: VegaTheme;
};
type EditGraphInfoModalCallbacks = {
  setCurrentEditGraph: (graphPreview: any) => void;
};

type EditGraphInfoFormData = {
  slug: string;
  title: string;
  theme: VegaTheme;
};

const selector = (state: AppState) => ({
  setShowCreateAppModal: state.setShowCreateAppModal,
  organizationUID: state.organizationUID,
});

function EditGraphInfoModal({
  uid,
  title,
  slug,
  theme,
  setCurrentEditGraph,
}: EditGraphInfoModalProps & EditGraphInfoModalCallbacks) {
  const { data: graphsData } = useGraphs();
  const { data: defaultStorage } = useDefaultStorage(uid);

  const { setShowCreateAppModal, organizationUID } = useStore(selector, shallow);
  const [loadingGraphVersion, setLoadingGraphVersion] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditGraphInfoFormData>({
    defaultValues: {
      title,
      slug,
      theme,
    },
  });

  const resetForm = useCallback(() => {
    reset({
      title,
      slug,
      theme,
    });
  }, [reset, title, slug, theme]);

  useEffect(() => {
    // reset form when graphs are loaded
    graphsData && resetForm();
  }, [graphsData, resetForm]);

  const {
    mutate: updateGraph,
    error,
    isLoading,
  } = useUpdateGraphMetadata({
    onSuccess: () => {
      setCurrentEditGraph(null);
    },
  });

  if (!uid) {
    return null;
  }
  const onSubmit = (data: EditGraphInfoFormData) => {
    updateGraph({
      uid,
      title: data.title,
      slug: data.slug,
      theme: data?.theme,
    });
  };

  const cloneApp = async () => {
    setLoadingGraphVersion(true);
    const latestGraphVersion = await getLatestGraphVersion(uid);
    if (organizationUID) {
      setShowCreateAppModal({
        orgUID: organizationUID,
        graphUID: uid,
        graphVersionUID: latestGraphVersion.version_uid,
        title: title,
        theme: latestGraphVersion.theme || 'default',
      });
      setCurrentEditGraph(null);
    }
  };

  const errorMessage = error instanceof Error ? error.message : null;

  return (
    <ModalComponent
      size="xl"
      showFooter={false}
      title={`Edit graph info - ${title}`}
      onClose={() => setCurrentEditGraph(null)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="title" mb={4} isInvalid={!!errors?.title}>
          <FormLabel>Title</FormLabel>
          <Input
            placeholder="title"
            type="text"
            autoFocus={true}
            data-testid="graph-title-input"
            tabIndex={0}
            maxLength={64}
            {...register('title', { required: true })}
          />
          {errors.title && <FormErrorMessage>This field is required</FormErrorMessage>}
        </FormControl>
        <FormControl id="slug" mb={4} isInvalid={!!errors?.slug}>
          <FormLabel>Slug</FormLabel>
          <P2 fontSize={'xs'} mb={2}>
            Graph slugs can consist of letters, numbers, and hyphens, and must be unique within an
            organization (case-insensitive).
          </P2>

          <Input
            placeholder="slug (letters, numbers, and hyphens)"
            type="text"
            data-testid="graph-slug-input"
            tabIndex={0}
            maxLength={32}
            {...register('slug', { required: true })}
          />
          {errors.slug && <FormErrorMessage>This field is required</FormErrorMessage>}
        </FormControl>

        <FormControl>
          <FormLabel>Storage</FormLabel>

          {defaultStorage?.name && !loadingGraphVersion ? (
            <>
              <Input
                type="text"
                data-testid="graph-storage-input"
                defaultValue={defaultStorage.name}
                disabled={true}
              />
              <P2 fontSize={'xs'} my={2}>
                Storage is not configurable for an app. Click <Link onClick={cloneApp}>here </Link>{' '}
                to clone your app with a different storage.
              </P2>
            </>
          ) : (
            <Flex justifyContent="center" alignItems="center">
              <Spinner mt={2} mb={1} />
            </Flex>
          )}
        </FormControl>

        <FormControl>
          <FormLabel>Vega Theme</FormLabel>

          <Select size="sm" {...register('theme', { required: true })}>
            {config.vegaThemes.map((theme) => (
              <option key={theme.value} value={theme.value}>
                {theme.label}
              </option>
            ))}
          </Select>
          <P2 fontSize={'xs'} my={2}>
            Choose which theme you would like to use with your charts.
          </P2>
        </FormControl>

        {errorMessage && (
          <P2 mt={2} color="red.600">
            {errorMessage}
          </P2>
        )}
        <Button type="submit" size="md" w="100%" isLoading={isLoading} mt={4} mb={2}>
          Save changes
        </Button>
      </form>
    </ModalComponent>
  );
}

export default EditGraphInfoModal;

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { GraphTemplates } from 'types/api';
import { useMarketplaceAppsByCategoryUrl } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const url = useMarketplaceAppsByCategoryUrl();

export default function useMarketplaceAppsByCategory(options?: UseQueryOptions<GraphTemplates>) {
  async function getPage(): Promise<GraphTemplates> {
    const res = await Axios.get<GraphTemplates>(url);
    return res.data;
  }

  return useQuery<GraphTemplates>(['marketplace', 'apps', 'categories'], getPage, options);
}

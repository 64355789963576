import { applyEdgeChanges, applyNodeChanges, Edge, EdgeChange, NodeChange, Rect } from 'reactflow';
import { isEqual } from 'lodash';
import create from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import {
  ConnectingHandle,
  EditorWindow,
  EditorWindowTab,
  GraphNode,
  IdeState,
  NewNodeItem,
  NodeChanges,
  NodeError,
  Parameter,
  SidebarMode,
  Store,
  VegaTheme,
} from 'types';
import { GraphManifest, GraphStatus } from 'types/api';
import {
  isNodeCancelled,
  isNodeError,
  isNodeKilled,
  isNodeQueued,
  isNodeRunning,
  isNodeSuccess,
  isStoreNodeType,
} from 'utils/nodes';

type State = {
  currentSidebar: SidebarMode | null;
  hoverNodeId: string | null;
  hoverMenuNodeId: string | null;
  activeExecutionIds: Record<string, string>;
  edges: Edge[];
  nodes: GraphNode[];
  manifest: GraphManifest | null;
  nodeError: NodeError | null;
  graphSaving: boolean;
  parsedGraphVersionUID: string | null;
  updatedGraphVersionUIDs: Set<string>;
  nodeChanges: NodeChanges;
  graphLevel: string;
  readOnly: boolean;
  dashboardId: string | null;
  GraphStatus: GraphStatus;
  nodeIdsToDelete: string[];
  nodeIdsToDuplicate: string[];
  edgeIdsToDelete: string[];
  resetStoreNode: string | null;
  resetNodeState: string | null;
  activeGridElement: Rect | null;
  addNodeMenuOpen: boolean;
  nodesWillRun: string[];
  edgesWillRun: string[];
  executionPlanNode: null | 'root' | string;
  runningNodeIds: string[];
  runningWebhookIds: string[];
  queuedNodeIds: string[];
  successNodeIds: string[];
  errorNodeIds: string[];
  killedNodesIds: string[];
  cancelledNodesIds: string[];
  newNodeItem: NewNodeItem | null;
  newNodeItemVisible: boolean;
  ideState: IdeState;
  stores: Store[];
  windows: EditorWindow[];
  vegaTheme?: VegaTheme;
  connectingHandle: ConnectingHandle;
};

type Actions = {
  setCurrentSidebar: (sidebar: SidebarMode | null) => void;
  resetGraph: () => void;
  setGraphLevel: (graphLevel: string) => void;
  setNodes: (nodes: GraphNode[]) => void;
  deselectNodes: () => void;
  selectNode: (id: string) => void;
  onNodesChange: (changes: NodeChange[]) => void;
  setEdges: (edges: Edge[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
  setHoverNodeId: (hoverNodeId: string | null, showMenu?: boolean) => void;
  setManifest: (manifest: GraphManifest) => void;
  setNodeError: (nodeError: NodeError | null) => void;
  setActiveExecutionId: (nodeId: string, activeExecutionId: string | null) => void;
  setGraphSaving: (graphSaving: boolean) => void;
  setParsedGraphVersionUID: (parsedGraphVersionUID: string) => void;
  setUpdatedGraphVersionUID: (updatedGraphVersionUID: string) => void;
  setNodeChanges: (nodeChanges: NodeChanges) => void;
  setReadOnly: (readOnly: boolean) => void;
  setDashboardId: (dashboardId: string | null) => void;
  setGraphStatus: (GraphStatus: GraphStatus) => void;
  setRunningWebhookIds: (ids: string[]) => void;
  setNodeIdsToDelete: (nodeIds: string[]) => void;
  setNodeIdsToDuplicate: (nodeIds: string[]) => void;
  setEdgeIdsToDelete: (edgeIds: string[]) => void;
  setResetStoreNode: (nodeId: string | null) => void;
  setResetNodeState: (nodeId: string | null) => void;
  setActiveGridElement: (activeGridElement: Rect | null) => void;
  setAddNodeMenuOpen: (addNodeMenuOpen: boolean) => void;
  setNodesWillRun: (nodes: string[]) => void;
  setEdgesWillRun: (edgeIds: string[]) => void;
  setExecutionPlanNode: (nodeId: null | 'root' | string) => void;
  setNewNodeItem: (newNodeItem: NewNodeItem | null) => void;
  setNewNodeItemVisible: (visible: boolean) => void;
  setIdeState: (ideState: IdeState) => void;
  openTab: (tab: EditorWindowTab | string, show?: boolean) => void;
  addWindow: (tab: EditorWindowTab) => void;
  removeWindow: (id: string) => void;
  hideWindow: (id: string) => void;
  showWindow: (id: string) => void;
  addToWindow: (id: string, tab: EditorWindowTab) => void;
  removeFromWindow: (id: string | null, tabName: string) => void;
  removeTabsFromWindow: (id: string | null, tabName: string[]) => void;
  updateWindow: (id: string, activeTab: string | null, tabs: EditorWindowTab[] | null) => void;
  setTopWindow: (id: string) => void;
  setParameter: (nodeId: string, parameter: Parameter) => void;
  setVegaTheme: (theme: VegaTheme) => void;
  setConnectingHandle: (connectingHandle: ConnectingHandle) => void;
};

export type GraphState = State & Actions;

const emptyGraphStatus: GraphStatus = {
  executions: {},
  run_events_updated: {},
  data_updated: {},
  state_updated: {},
};

const initialState: State = {
  currentSidebar: null,
  hoverNodeId: null,
  hoverMenuNodeId: null,
  activeExecutionIds: {},
  nodes: [],
  edges: [],
  graphSaving: false,
  parsedGraphVersionUID: null,
  updatedGraphVersionUIDs: new Set(),
  manifest: null,
  nodeError: null,
  nodeChanges: {},
  graphLevel: 'root',
  readOnly: false,
  dashboardId: null,
  GraphStatus: emptyGraphStatus,
  nodeIdsToDelete: [],
  nodeIdsToDuplicate: [],
  edgeIdsToDelete: [],
  resetStoreNode: null,
  resetNodeState: null,
  activeGridElement: null,
  addNodeMenuOpen: false,
  nodesWillRun: [],
  edgesWillRun: [],
  executionPlanNode: null,
  runningNodeIds: [],
  runningWebhookIds: [],
  queuedNodeIds: [],
  successNodeIds: [],
  errorNodeIds: [],
  killedNodesIds: [],
  cancelledNodesIds: [],
  newNodeItem: null,
  newNodeItemVisible: true,
  ideState: {},
  stores: [],
  windows: [],
  connectingHandle: null,
};

const useStore = create<GraphState>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      ...initialState,

      setCurrentSidebar: (currentSidebar: SidebarMode | null) => {
        set({
          currentSidebar,
        });
      },

      setDashboardId: (dashboardId: string | null) => {
        set({
          dashboardId,
        });
      },

      resetGraph: () => {
        set(initialState);
      },

      setParsedGraphVersionUID: (parsedGraphVersionUID: string) => {
        set(
          {
            parsedGraphVersionUID,
          },
          false,
          'parsedGraphVersionUID/set'
        );
      },

      setUpdatedGraphVersionUID: (updatedGraphVersionUID: string) => {
        const { updatedGraphVersionUIDs } = get();
        set({
          updatedGraphVersionUIDs: new Set(updatedGraphVersionUIDs).add(updatedGraphVersionUID),
        });
      },

      setGraphLevel: (graphLevel: string) => {
        set({
          graphLevel,
        });
      },

      setNodes: (nodes: GraphNode[]) => {
        const { ideState, stores: oldStores } = get();

        const stores = nodes
          .filter((node) => isStoreNodeType(node.data.type))
          .map((node) => {
            return {
              type: node.data.type,
              name: node.data.name,
            };
          })
          .sort((nodeA, nodeB) => nodeA.name.localeCompare(nodeB.name));

        set(
          {
            nodes,
            stores: isEqual(stores, oldStores) ? oldStores : stores,
            ideState: nodes.reduce<IdeState>((accumulator, node) => {
              if (accumulator[node.id]) return accumulator;
              accumulator[node.id] = {};
              return accumulator;
            }, ideState),
          },
          false,
          'nodes/set'
        );
      },
      deselectNodes: () => {
        set({
          nodes: get().nodes.map((node) => {
            node.selected = false;
            return node;
          }),
        });
      },
      selectNode: (id: string) => {
        set({
          nodes: get().nodes.map((node) => {
            node.selected = node.id === id;
            return node;
          }),
        });
      },
      onNodesChange: (changes: NodeChange[]) => {
        const { nodes } = get();
        set({
          nodes: applyNodeChanges(changes, nodes),
        });
      },
      setEdges: (edges: Edge[]) => {
        set({ edges });
      },
      onEdgesChange: (changes: EdgeChange[]) => {
        const { edges } = get();
        set({ edges: applyEdgeChanges(changes, edges) });
      },
      setHoverNodeId: (hoverNodeId: string | null, showMenu?: boolean) => {
        set({ hoverNodeId, hoverMenuNodeId: showMenu ? hoverNodeId : null });
      },
      setManifest: (manifest: GraphManifest) => {
        set({ manifest });
      },
      setNodeError: (nodeError: NodeError | null) => {
        set({ nodeError });
      },
      setActiveExecutionId: (nodeID: string, activeExecutionId: string | null) => {
        const { activeExecutionIds } = get();
        set({ activeExecutionIds: { ...activeExecutionIds, [nodeID]: activeExecutionId || '' } });
      },
      setGraphSaving: (graphSaving: boolean) => {
        set({ graphSaving }, false, 'graphSaving/set');
      },
      setNodeChanges: (nodeChanges: NodeChanges) => {
        set({ nodeChanges });
      },
      setReadOnly: (readOnly: boolean) => {
        set({ readOnly });
      },
      setGraphStatus: (graphStatus: GraphStatus) => {
        const executions = Object.entries(graphStatus.executions);
        set({
          GraphStatus: graphStatus,
          runningNodeIds: executions
            .filter(([_, execution]) => isNodeRunning(execution))
            .map(([nodeID, _]) => nodeID),
          queuedNodeIds: executions
            .filter(([_, execution]) => isNodeQueued(execution))
            .map(([nodeID, _]) => nodeID),
          successNodeIds: executions
            .filter(([_, execution]) => isNodeSuccess(execution))
            .map(([nodeID, _]) => nodeID),
          errorNodeIds: executions
            .filter(([_, execution]) => isNodeError(execution))
            .map(([nodeID, _]) => nodeID),
          cancelledNodesIds: executions
            .filter(([_, execution]) => isNodeCancelled(execution))
            .map(([nodeID, _]) => nodeID),
          killedNodesIds: executions
            .filter(([_, execution]) => isNodeKilled(execution))
            .map(([nodeID, _]) => nodeID),
        });
      },
      setRunningWebhookIds: (runningWebhookIds: string[]) => {
        set({ runningWebhookIds });
      },
      setNodeIdsToDelete: (nodeIdsToDelete: string[]) => {
        set({ nodeIdsToDelete });
      },
      setNodeIdsToDuplicate: (nodeIdsToDuplicate: string[]) => {
        set({ nodeIdsToDuplicate });
      },
      setEdgeIdsToDelete: (edgeIdsToDelete: string[]) => {
        set({ edgeIdsToDelete });
      },

      setResetStoreNode: (nodeId: string | null) => {
        set({ resetStoreNode: nodeId });
      },

      setResetNodeState: (nodeId: string | null) => {
        set({ resetNodeState: nodeId });
      },

      setAddNodeMenuOpen: (addNodeMenuOpen: boolean) => {
        set({ addNodeMenuOpen });
      },
      setActiveGridElement: (activeGridElement: Rect | null) => {
        set({ activeGridElement });
      },
      setNodesWillRun: (nodesWillRun: string[]) => {
        set({ nodesWillRun });
      },
      setEdgesWillRun: (edgesWillRun: string[]) => {
        set({ edgesWillRun });
      },
      setExecutionPlanNode: (nodeId: null | 'root' | string) => {
        set({ executionPlanNode: nodeId });
      },
      setNewNodeItem: (newNodeItem: NewNodeItem | null) => {
        set({
          newNodeItem,
          newNodeItemVisible: newNodeItem !== null ? true : get().newNodeItemVisible,
        });
      },
      setNewNodeItemVisible: (newNodeItemVisible: boolean) => {
        set({ newNodeItemVisible });
      },
      setIdeState: (ideState: IdeState) => {
        set({ ideState });
      },

      openTab: (tab, show = true) => {
        let newTab: EditorWindowTab;
        if (typeof tab === 'string') {
          newTab = { name: tab } as EditorWindowTab;
        } else {
          newTab = tab;
        }
        const { windows } = get();
        if (windows.length === 0) {
          const newWindows = addWindowUtil([], newTab, show);
          set({ windows: newWindows });
        } else {
          const matchingWindows = windows.filter((window) =>
            getWindowTabNamesUtil(window).includes(newTab.name)
          );
          if (matchingWindows && matchingWindows.length > 0) {
            const window = matchingWindows[0];
            // update the window with the tab
            const updatedWindow = {
              ...window,
              show: window.show || show,
              tabs: window.tabs.map((tab) =>
                tab.name === newTab.name ? { ...tab, ...newTab } : tab
              ),
              activeTab: newTab.name,
            } as EditorWindow;
            const updatedWindows = windows.map((window) =>
              window.id === updatedWindow.id
                ? updatedWindow
                : { ...window, show: window.show || show }
            );

            const newWindows = setTopWindowUtil(window.id, updatedWindows);
            set({ windows: newWindows });
          } else {
            const topWindow = getTopWindowUtil(windows) as EditorWindow;
            if (topWindow) {
              const newWindows = addToWindowUtil(
                windows,
                topWindow.id,
                newTab,
                topWindow.show || show
              );
              set({ windows: newWindows });
            }
          }
        }
      },

      addWindow: (tab: EditorWindowTab) => {
        const { windows } = get();
        const newWindows = addWindowUtil(windows, tab);
        set({ windows: newWindows });
      },

      removeWindow: (id: string) => {
        const { windows } = get();
        const newWindows = windows.filter((window) => window.id !== id);
        set({ windows: newWindows });
      },

      hideWindow: (id: string) => {
        const { windows } = get();
        windows.forEach((window) => {
          if (window.id === id) {
            window.show = false;
          }
        });
        set({ windows: [...windows] });
      },

      showWindow: (id: string) => {
        const { windows } = get();
        windows.forEach((window) => {
          if (window.id === id) {
            window.show = true;
          }
        });
        set({ windows: [...windows] });
      },

      addToWindow: (id: string, tab: EditorWindowTab) => {
        const { windows } = get();
        const newWindows = addToWindowUtil(windows, id, tab);
        set({ windows: newWindows });
      },

      removeFromWindow: (id: string | null, tabName: string) => {
        // if window id is null, then remove from all windows
        const { windows } = get();
        windows.forEach((window) => {
          if (id === null || window.id === id) {
            if (window.activeTab === tabName && window.tabs.length > 0) {
              const activeTabIndex = window.tabs.findIndex((tab) => tab.name === tabName);
              if (activeTabIndex > 0) {
                window.activeTab = window.tabs[activeTabIndex - 1].name;
              } else if (window.tabs.length > 1) {
                window.activeTab = window.tabs[1].name;
              }
            }

            window.tabs = window.tabs.filter((tmpTab) => tmpTab.name !== tabName);
          }
        });
        set({ windows: windows.filter((window) => window.tabs.length > 0) });
      },

      removeTabsFromWindow: (id: string | null, tabs: string[]) => {
        const { windows } = get();
        windows.forEach((window) => {
          if (id === null || window.id === id) {
            window.tabs = window.tabs.filter((tab) => !tabs.includes(tab.name));
            if (window.tabs.length > 0) {
              const activeTab = window.tabs.find((tab) => window.activeTab === tab.name);
              if (!activeTab) {
                window.activeTab = window.tabs[0].name;
              }
            }
          }
        });
        set({ windows: [...windows] });
      },

      updateWindow: (id: string, activeTab: string | null, tabs: EditorWindowTab[] | null) => {
        const { windows } = get();
        windows.forEach((window) => {
          if (window.id === id) {
            if (activeTab) {
              window.activeTab = activeTab;
            }
            if (tabs) {
              window.tabs = tabs;
            }
          }
        });
        set({ windows });
      },

      setTopWindow: (id: string) => {
        const { windows } = get();
        const newWindows = setTopWindowUtil(id, windows);
        set({ windows: newWindows });
      },

      setParameter: (nodeId: string, parameter: Parameter) => {
        const { nodes } = get();
        const node = nodes.find((n) => n.id === nodeId);
        if (!node) return;
        const inSubNode = !!node.data.parentNodeId;
        const parameters = inSubNode ? node.data.resolvedParameters : node.data.parameters;

        const oldParameter = parameters.find((p) => p.name === parameter.name);
        if (!oldParameter) return;

        const index = parameters.indexOf(oldParameter);
        parameters[index] = parameter;

        if (inSubNode) {
          node.data.resolvedParameters[index] = parameter;
        } else {
          node.data.parameters[index] = parameter;
        }

        set({ nodes: [...nodes] });
      },

      setVegaTheme: (vegaTheme) => {
        set({ vegaTheme });
      },

      setConnectingHandle: (connectingHandle: ConnectingHandle) => {
        set({ connectingHandle });
      },
    })),
    { enabled: process.env.NODE_ENV === 'development' }
  )
);

function setTopWindowUtil(id: string, windows: EditorWindow[]): EditorWindow[] {
  if (!window) {
    return windows;
  }
  const topWindow = windows.find((w) => w.id === id);
  if (!topWindow) {
    return windows;
  }
  const oldZindex = topWindow.zIndex;
  windows.forEach((w) => {
    if (w.id === id) {
      w.zIndex = windows.length;
    } else if (w.zIndex > oldZindex) {
      w.zIndex -= 1;
    }
  });
  return windows;
}

export function getTopWindowUtil(windows: EditorWindow[]): EditorWindow | null {
  let window: null | EditorWindow = null;
  windows.forEach((w) => {
    if (!window || w.zIndex > window.zIndex) {
      window = w;
    }
  });
  return window;
}

export function getWindowTabNamesUtil(window: EditorWindow): string[] {
  return window.tabs.map((tab) => tab.name);
}

function addToWindowUtil(
  windows: EditorWindow[],
  id: string,
  tab: EditorWindowTab,
  show: boolean = true
): EditorWindow[] {
  windows.forEach((window) => {
    if (window.id === id) {
      window.activeTab = tab.name;
      window.show = show;
      if (!getWindowTabNamesUtil(window).includes(tab.name)) {
        window.tabs.push(tab);
      }
    }
  });
  return windows;
}

function addWindowUtil(
  windows: EditorWindow[],
  tab: EditorWindowTab,
  show: boolean = true
): EditorWindow[] {
  const id = Math.floor(Math.random() * 1000000).toString();
  const newWindow: EditorWindow = {
    id: id,
    show,
    isSidebar: true,
    zIndex: 0,
    tabs: [tab],
    activeTab: tab.name,
  };
  windows.push(newWindow);
  return setTopWindowUtil(id, windows);
}

export default useStore;

import React, { MouseEvent, useCallback } from 'react';
import { FiCopy } from 'react-icons/fi';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import shallow from 'zustand/shallow';

import IconButtonMinimal from 'components/IconButtonMinimal/IconButtonMinimal';
import { useAuth } from 'contexts/AuthContext';
import useStore from 'state';
import { AppState } from 'types';
import { Graph, Organization } from 'types/api';
import { getLatestGraphVersion, getSpecificGraphVersion } from 'utils';

const selector = (state: AppState) => ({
  setShowCreateAppModal: state.setShowCreateAppModal,
  organizationUID: state.organizationUID,
});

type menuButtonOptions =
  | 'default'
  | 'justIcon'
  | 'readOnly'
  | 'previousGraphVersion'
  | 'menuItem'
  | 'minimalIcon';

type DuplicateGraphProps = {
  graphUID: string;
  menuButton?: menuButtonOptions;
  graphVersionUID?: string | null;
};

function DuplicateGraph({
  graphUID,
  menuButton = 'default',
  graphVersionUID = null,
}: DuplicateGraphProps) {
  const { account, isAuthenticated } = useAuth();
  const router = useRouter();
  const { organizationUID, setShowCreateAppModal } = useStore(selector, shallow);

  const duplicateGraph = useCallback(
    async (event: MouseEvent, organization: Organization) => {
      event.preventDefault();
      event.stopPropagation();
      let graphVersion: Graph;
      if (graphVersionUID) {
        graphVersion = await getSpecificGraphVersion(graphVersionUID);
      } else {
        graphVersion = await getLatestGraphVersion(graphUID);
      }
      const descriptionText =
        graphVersion.manifest?.description_path &&
        graphVersion?.graph_files[graphVersion.manifest.description_path];

      setShowCreateAppModal({
        orgUID: organization.uid,
        graphUID: graphVersion.uid,
        graphVersionUID: graphVersion.version_uid,
        title: graphVersion.title,
        description: descriptionText || '',
        theme: graphVersion?.theme || 'default',
      });
    },
    [graphUID, graphVersionUID, setShowCreateAppModal]
  );

  const organizations = Object.values(account?.organizations || []);

  const menuButtonOnClick = useCallback(
    (event: MouseEvent) => {
      if (!isAuthenticated) {
        event.preventDefault();
        router.push({
          pathname: '/auth/login',
          query: { returnTo: router.query.returnTo || router.asPath },
        });
        return;
      }
      if (organizations.length === 1) {
        duplicateGraph(event, organizations[0]);
      }
      event.stopPropagation();
    },
    [isAuthenticated, organizations, router, duplicateGraph]
  );

  const MenuButtonElement = (
    <>
      {menuButton === 'default' && (
        <MenuButton
          as={Button}
          data-testid="env-button"
          mt="auto"
          size="md"
          onClick={menuButtonOnClick}
          minW={100}
        >
          Clone
        </MenuButton>
      )}
      {menuButton === 'justIcon' && (
        <MenuButton as={IconButtonMinimal} Icon={FiCopy} label="Duplicate" />
      )}
      {menuButton === 'readOnly' && (
        <MenuButton
          as={Button}
          size="md"
          leftIcon={<FiCopy />}
          ml={3}
          pointerEvents="auto"
          onClick={menuButtonOnClick}
        >
          Clone
        </MenuButton>
      )}
      {menuButton === 'previousGraphVersion' && (
        <MenuButton
          as={Button}
          size="xs"
          leftIcon={<FiCopy />}
          ml={3}
          pointerEvents="auto"
          variant="outline"
          colorScheme="gray"
          onClick={menuButtonOnClick}
        >
          Clone
        </MenuButton>
      )}
      {menuButton === 'menuItem' && (
        <MenuButton
          w="100%"
          justifyContent="left"
          textAlign="left"
          paddingY="0.4rem"
          paddingX="0.8rem"
          onClick={menuButtonOnClick}
        >
          Duplicate App
        </MenuButton>
      )}
      {menuButton === 'minimalIcon' && (
        <MenuButton as={IconButtonMinimal} Icon={FiCopy} label="Duplicate" position="relative" />
      )}
    </>
  );

  return (
    <Flex
      justify="flex-end"
      pointerEvents="auto"
      onClick={(e) => {
        e.preventDefault();
      }}
      w={menuButton === 'menuItem' ? '100%' : 'auto'}
    >
      <Menu autoSelect={false} closeOnBlur placement="bottom-end">
        {MenuButtonElement}
        <MenuList zIndex={12} fontSize="sm" color="text3" textAlign="center" boxShadow="md">
          <MenuGroup title="Duplicate in organization" fontSize="11px" fontWeight={400}>
            <MenuDivider />
            {account &&
              organizations.map((org) => (
                <MenuItem
                  color="text2"
                  key={org.uid}
                  onClick={(event) => duplicateGraph(event, org)}
                  fontSize="xs"
                >
                  {org.slug}
                  {organizationUID === org.uid && ' (current)'}
                </MenuItem>
              ))}
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
}

export default DuplicateGraph;

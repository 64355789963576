import { Edge } from 'reactflow';

import { GraphNode } from 'types';

export function toggleEdgeVisibility(edges: Edge[], nodes: GraphNode[]): Edge[] {
  return edges.filter((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    const targetNode = nodes.find((node) => node.id === edge.target);

    const invalidSource = sourceNode?.parentNode === targetNode?.id || !sourceNode;
    const invalidTarget = targetNode?.parentNode === sourceNode?.id || !targetNode;
    const hiddenChildrenEdge = invalidSource || invalidTarget;

    return sourceNode && targetNode && !hiddenChildrenEdge;
  });
}

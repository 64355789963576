import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { chakra, Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';

import PriceTag from './PriceTag';
import { Plan } from './pricing';

const CheckIcon = chakra(FiCheck);

/**
 * Copied from @patterns/content with some slight tweaks
 */
function PlanCard({ name, description, price, features }: Plan) {
  return (
    <Flex direction="column" justify="space-between" height={300}>
      <Flex direction="row" justifyContent="space-between" gap={8}>
        <Text fontSize="24px" fontWeight="600" lineHeight="1.1">
          {name.charAt(0).toUpperCase() + name.slice(1)}
          <PriceTag price={price} />
        </Text>

        <Text fontSize="14px" color="dark.text4" textAlign="center" lineHeight="1.2">
          {description}
        </Text>
      </Flex>

      <Text fontWeight={400} lineHeight={1.2} color="light.text1">
        {features.title}
      </Text>

      {features.description && <Text color="dark.text4">{features.description}</Text>}

      {features.list &&
        features.list?.length > 0 &&
        features.list.map((feature) => (
          <List key={feature} spacing={4}>
            <ListItem color="dark.text4">
              <ListIcon as={CheckIcon} stroke="dark.text3" mr={2} />
              {feature}
            </ListItem>
          </List>
        ))}
    </Flex>
  );
}

export default PlanCard;

import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import useStore from 'state';
import { AppState } from 'types';
import { useVariableURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

const selector = (s: AppState) => s.organizationUID;

function useDeleteVariable(options: UseMutationOptions<any, unknown, string> = {}) {
  const organizationUID = useStore(selector);
  const queryClient = useQueryClient();

  return useMutation<any, unknown, string>(
    ['variable', organizationUID],
    async (name: string) => {
      const url = useVariableURL(organizationUID, name);
      const res = await Axios.delete(url);
      await queryClient.invalidateQueries(['variables']);
      return res?.data;
    },
    options
  );
}

export default useDeleteVariable;

import { ReactNode } from 'react';

import useMarketplaceGraph from 'hooks/api/useMarketplaceGraph';

import NodeReference from './NodeReference';

export type AppPreviewNodeReference = {
  graphUID: string;
  props: any;
  children: ReactNode;
};

const AppPreviewNodeReference = ({ graphUID, props, children }: AppPreviewNodeReference) => {
  const { data: name } = useMarketplaceGraph(graphUID, {
    select: (data) => data?.manifest?.nodes.find((node) => node.id === props.id)?.title,
  });

  return <NodeReference {...{ name, props, children }} />;
};

export default AppPreviewNodeReference;

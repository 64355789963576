import { useEffect } from 'react';
import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import chmln from '@chamaeleonidae/chmln';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import posthog from 'posthog-js';
import { inter } from 'styles/fonts';
import theme from 'styles/theme';

import AppModals from 'components/AppModals';
import { useCloseAllAppModals } from 'components/AppModals/AppModals';
import Atlas from 'components/Atlas';
import AxiosExtend from 'components/AxiosExtend';
import CustomQueryClientProvider from 'components/CustomQueryClientProvider';
import ErrorModal from 'components/ErrorModal';
import HelpMenu from 'components/HelpMenu';
import VersionCheck from 'components/VersionCheck';
import AuthProvider from 'contexts/AuthContext';
import { FB_PIXEL_ID, FB_PIXEL_PAGEVIEW } from 'utils/fpixel';
import AppEffects from 'views/AppEffects';
import 'views/Graph/modules/GraphView/Flow.css';
import 'views/Graph/styles.css';
import 'styles/scrollbars.css';
import 'styles/monaco.css';
import 'styles/tableStyles.css';
import 'reactflow/dist/style.css';
import '@reactflow/node-resizer/dist/style.css';
import 'rc-tabs/assets/index.css';
import '@docsearch/css';

const TESTING = process.env.NEXT_PUBLIC_ENV === 'test';

const AuthDomain: string = process.env.NEXT_PUBLIC_AUTH_DOMAIN!;
const AuthClientId: string = process.env.NEXT_PUBLIC_AUTH_CLIENT_ID!;
var AuthRedirectUri: string = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI!;
if (typeof window !== 'undefined') {
  AuthRedirectUri = window.location.origin + '/home';
}
const AuthAudience: string = process.env.NEXT_PUBLIC_AUTH_AUDIENCE!;

const onAuthRedirectCallback = (appState?: AppState) => {
  if (appState?.inviteUid) {
    // redirect to the invite page if there's an inviteUid in the appState
    Router.replace({
      pathname: `/join/${appState.inviteUid}`,
      query: { joinedOrg: true },
    });
  } else {
    Router.replace(appState?.returnTo || '/');
  }
};

function PatternsApp({ Component, pageProps, router }: AppProps) {
  const toast = useToast();
  const closeAllAppModals = useCloseAllAppModals();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog) {
      posthog.init('phc_U4f49A4AwHSnagT0GP40ietEhEcJKrwKMSMuc7xYnIT', {
        api_host: 'https://app.posthog.com',
      });
    }

    if (process.env.NEXT_PUBLIC_ENABLE_CHAMELEON !== 'false' && chmln) {
      chmln.init('SUPJLd9xZCh6VRJz4SUCfePLf5Rx5AWCucUL18ByBm7N1P-1OV4Wn-E1QVf44eh402mxVB', {
        fastUrl: 'https://fast.chameleon.io/',
      });
    }
  }, []);

  const authArgs: Auth0ProviderOptions = {
    domain: AuthDomain,
    clientId: AuthClientId,
    redirectUri: AuthRedirectUri,
    audience: AuthAudience,
    cacheLocation: 'localstorage', // Using localstorage since some browsers such as Safari do not persist tokens between tabs or retrieve tokens silently
    useRefreshTokens: true,
    onRedirectCallback: onAuthRedirectCallback,
    skipRedirectCallback: TESTING,
  };

  if (router.pathname.startsWith('/connections/callback')) {
    authArgs.skipRedirectCallback = true;
  }

  useEffect(() => {
    const handleRouteChange = () => {
      toast.closeAll(); // close toasts on page navigation
      closeAllAppModals(); // close app modals on page navigation
    };
    router.events.on('routeChangeStart', handleRouteChange);
    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [closeAllAppModals, router.events, toast]);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    FB_PIXEL_PAGEVIEW();
    const handleRouteChange = () => {
      FB_PIXEL_PAGEVIEW();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <main className={inter.className}>
      <Head>
        <title>Patterns</title>
        <meta name="description" content="Build powerful Data Apps from reuseable components" />
        <link rel="icon" href="/favicon.svg" />
        <meta name="fragment" content="!" />
      </Head>

      <ChakraProvider theme={theme}>
        <CustomQueryClientProvider>
          {!TESTING && <ReactQueryDevtools initialIsOpen={false} />}
          <Auth0Provider {...authArgs}>
            <AuthProvider>
              <AxiosExtend />
              <Atlas />
              <Component {...pageProps} />
              <ErrorModal />
              <VersionCheck />
              <HelpMenu />
              <AppModals />
              <AppEffects pathname={router.pathname} />
            </AuthProvider>
          </Auth0Provider>
        </CustomQueryClientProvider>
      </ChakraProvider>

      {process.env.NEXT_PUBLIC_ENABLE_APPCUES === 'true' && (
        <>
          <Script id="appcues-settings">
            {`window.AppcuesSettings = { enableURLDetection: true };`}
          </Script>
          <Script src="//fast.appcues.com/128504.js" />
        </>
      )}

      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
        }}
      />
    </main>
  );
}

export default PatternsApp;

import { MonacoTheme } from 'monaco-editor';
import { colors } from 'styles/colors';

import { SyntaxTheme } from './lighttheme';

const backgroundColor = '#2C313A';
const backgroundColor2 = '#21252B';
const borderColor = '#181A1F';

const editorColors = {
  magenta: '#C678DD',
  grey: '#5C6370',
  green: '#98C379',
  blue: '#56B6C2',
  dark: '#333842',
  lightGrey: '#ABB2BF',
};

export const syntaxDark: SyntaxTheme = {
  'hljs': {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: colors.dark.bg1,
    color: colors.gray[300],
  },
  'hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-selector-tag': {
    color: editorColors.magenta,
  },
  'hljs-literal': {
    color: editorColors.magenta,
  },
  'hljs-section': {
    color: editorColors.magenta,
  },
  'hljs-link': {
    color: editorColors.magenta,
  },
  'hljs-function .hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-subst': {
    color: editorColors.lightGrey,
  },
  'hljs-string': {
    color: editorColors.green,
  },
  'hljs-title': {
    color: editorColors.lightGrey,
  },
  'hljs-name': {
    color: editorColors.green,
  },
  'hljs-type': {
    color: editorColors.green,
  },
  'hljs-attribute': {
    color: editorColors.green,
  },
  'hljs-symbol': {
    color: editorColors.green,
  },
  'hljs-bullet': {
    color: editorColors.green,
  },
  'hljs-addition': {
    color: editorColors.green,
  },
  'hljs-variable': {
    color: editorColors.green,
  },
  'hljs-template-tag': {
    color: editorColors.green,
  },
  'hljs-template-variable': {
    color: editorColors.green,
  },
  'hljs-comment': {
    color: editorColors.grey,
  },
  'hljs-quote': {
    color: editorColors.green,
  },
  'hljs-deletion': {
    color: editorColors.green,
  },
  'hljs-meta': {
    color: editorColors.green,
  },
  'hljs-doctag': {
    fontWeight: 'bold',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
};

const theme: MonacoTheme = {
  inherit: false,
  base: 'vs-dark',
  colors: {
    'activityBar.background': editorColors.dark,
    'activityBar.foreground': '#D7DAE0',
    'activityBarBadge.background': '#528BFF',
    'activityBarBadge.foreground': '#D7DAE0',
    'button.background': '#4D78CC',
    'button.foreground': '#FFFFFF',
    'button.hoverBackground': '#6087CF',
    'diffEditor.insertedTextBackground': '#00809B33',
    'dropdown.background': '#353b45',
    'dropdown.border': borderColor,
    'editorIndentGuide.activeBackground': '#626772',
    'editor.background': '#282C34',
    'editor.foreground': editorColors.lightGrey,
    'editor.lineHighlightBackground': '#99BBFF0A',
    'editor.selectionBackground': '#3E4451',
    'editorCursor.foreground': '#528BFF',
    'editor.findMatchHighlightBackground': '#528BFF3D',
    'editorGroup.background': backgroundColor2,
    'editorGroup.border': borderColor,
    'editorGroupHeader.tabsBackground': backgroundColor2,
    'editorIndentGuide.background': '#ABB2BF26',
    'editorLineNumber.foreground': '#636D83',
    'editorLineNumber.activeForeground': editorColors.lightGrey,
    'editorWhitespace.foreground': '#ABB2BF26',
    'editorRuler.foreground': '#ABB2BF26',
    'editorHoverWidget.background': backgroundColor2,
    'editorHoverWidget.border': borderColor,
    'editorSuggestWidget.background': backgroundColor2,
    'editorSuggestWidget.border': borderColor,
    'editorSuggestWidget.selectedBackground': colors.light.action,
    'editorWidget.background': backgroundColor2,
    'editorWidget.border': '#3A3F4B',
    'input.background': '#1B1D23',
    'input.border': borderColor,
    'focusBorder': '#528BFF',
    'list.activeSelectionBackground': backgroundColor,
    'list.activeSelectionForeground': colors.light.bg1,
    'list.focusBackground': backgroundColor,
    'list.hoverBackground': '#2C313A66',
    'list.highlightForeground': '#D7DAE0',
    'list.inactiveSelectionBackground': backgroundColor,
    'list.inactiveSelectionForeground': '#D7DAE0',
    'notification.background': backgroundColor2,
    'pickerGroup.border': '#528BFF',
    'scrollbarSlider.background': '#4E566680',
    'scrollbarSlider.activeBackground': '#747D9180',
    'scrollbarSlider.hoverBackground': '#5A637580',
    'sideBar.background': backgroundColor2,
    'sideBarSectionHeader.background': editorColors.dark,
    'statusBar.background': backgroundColor2,
    'statusBar.foreground': '#9DA5B4',
    'statusBarItem.hoverBackground': backgroundColor,
    'statusBar.noFolderBackground': backgroundColor2,
    'tab.activeBackground': '#282C34',
    'tab.activeForeground': '#D7DAE0',
    'tab.border': borderColor,
    'tab.inactiveBackground': backgroundColor2,
    'titleBar.activeBackground': backgroundColor2,
    'titleBar.activeForeground': '#9DA5B4',
    'titleBar.inactiveBackground': backgroundColor2,
    'titleBar.inactiveForeground': '#9DA5B4',
    'statusBar.debuggingForeground': '#FFFFFF',
    'extensionButton.prominentBackground': '#2BA143',
    'extensionButton.prominentHoverBackground': '#37AF4E',
    'badge.background': '#528BFF',
    'badge.foreground': '#D7DAE0',
    'peekView.border': '#528BFF',
    'peekViewResult.background': backgroundColor2,
    'peekViewResult.selectionBackground': backgroundColor,
    'peekViewTitle.background': '#1B1D23',
    'peekViewEditor.background': '#1B1D23',
  },
  rules: [
    {
      foreground: editorColors.grey,
      fontStyle: 'italic',
      token: 'comment',
    },
    {
      foreground: editorColors.grey,
      token: 'comment markup.link',
    },
    {
      foreground: editorColors.blue,
      token: 'delimiter.jinja',
    },
    {
      foreground: '#E5C07B',
      token: 'entity.name.type',
    },
    {
      foreground: '#E5C07B',
      token: 'entity.other.inherited-class',
    },
    {
      foreground: editorColors.magenta,
      token: 'keyword',
    },
    {
      foreground: editorColors.magenta,
      token: 'keyword.control',
    },
    {
      foreground: editorColors.magenta,
      token: 'keyword.operator',
    },
    {
      foreground: '#61AFEF',
      token: 'keyword.other.special-method',
    },
    {
      foreground: '#D19A66',
      token: 'keyword.other.unit',
    },
    {
      foreground: editorColors.magenta,
      token: 'storage',
    },
    {
      foreground: editorColors.magenta,
      token: 'storage.type.annotation',
    },
    {
      foreground: editorColors.magenta,
      token: 'storage.type.primitive',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'storage.modifier.package',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'storage.modifier.import',
    },
    {
      foreground: '#D19A66',
      token: 'constant',
    },
    {
      foreground: '#D19A66',
      token: 'constant.variable',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.character.escape',
    },
    {
      foreground: '#D19A66',
      token: 'constant.numeric',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.other.color',
    },
    {
      foreground: editorColors.blue,
      token: 'constant.other.symbol',
    },
    {
      foreground: '#E06C75',
      token: 'variable',
    },
    {
      foreground: '#BE5046',
      token: 'variable.interpolation',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'variable.parameter',
    },
    {
      foreground: editorColors.green,
      token: 'string',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'string > source',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'string embedded',
    },
    {
      foreground: editorColors.blue,
      token: 'string.regexp',
    },
    {
      foreground: '#E5C07B',
      token: 'string.regexp source.ruby.embedded',
    },
    {
      foreground: '#E06C75',
      token: 'string.other.link',
    },
    {
      foreground: editorColors.grey,
      token: 'punctuation.definition.comment',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.method-parameters',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.function-parameters',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.parameters',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.separator',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.seperator',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.array',
    },
    {
      foreground: '#61AFEF',
      token: 'punctuation.definition.heading',
    },
    {
      foreground: '#61AFEF',
      token: 'punctuation.definition.identity',
    },
    {
      foreground: '#E5C07B',
      fontStyle: 'bold',
      token: 'punctuation.definition.bold',
    },
    {
      foreground: editorColors.magenta,
      fontStyle: 'italic',
      token: 'punctuation.definition.italic',
    },
    {
      foreground: '#BE5046',
      token: 'punctuation.section.embedded',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.section.method',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.section.class',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.section.inner-class',
    },
    {
      foreground: '#E5C07B',
      token: 'support.class',
    },
    {
      foreground: editorColors.blue,
      token: 'support.type',
    },
    {
      foreground: editorColors.blue,
      token: 'support.function',
    },
    {
      foreground: '#61AFEF',
      token: 'support.function.any-method',
    },
    {
      foreground: '#61AFEF',
      token: 'entity.name.function',
    },
    {
      foreground: '#E5C07B',
      token: 'entity.name.class',
    },
    {
      foreground: '#E5C07B',
      token: 'entity.name.type.class',
    },
    {
      foreground: '#61AFEF',
      token: 'entity.name.section',
    },
    {
      foreground: '#E06C75',
      token: 'entity.name.tag',
    },
    {
      foreground: '#D19A66',
      token: 'entity.other.attribute-name',
    },
    {
      foreground: '#61AFEF',
      token: 'entity.other.attribute-name.id',
    },
    {
      foreground: '#E5C07B',
      token: 'meta.class',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.class.body',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.method-call',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.method',
    },
    {
      foreground: '#E06C75',
      token: 'meta.definition.variable',
    },
    {
      foreground: '#D19A66',
      token: 'meta.link',
    },
    {
      foreground: '#61AFEF',
      token: 'meta.require',
    },
    {
      foreground: editorColors.magenta,
      token: 'meta.selector',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.separator',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.tag',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'none',
    },
    {
      foreground: '#523D14',
      background: '#E0C285',
      token: 'invalid.deprecated',
    },
    {
      foreground: '#FFFFFF',
      background: '#E05252',
      token: 'invalid.illegal',
    },
    {
      foreground: '#D19A66',
      fontStyle: 'bold',
      token: 'markup.bold',
    },
    {
      foreground: editorColors.magenta,
      token: 'markup.changed',
    },
    {
      foreground: '#E06C75',
      token: 'markup.deleted',
    },
    {
      foreground: editorColors.magenta,
      fontStyle: 'italic',
      token: 'markup.italic',
    },
    {
      foreground: '#E06C75',
      token: 'markup.heading',
    },
    {
      foreground: '#61AFEF',
      token: 'markup.heading punctuation.definition.heading',
    },
    {
      foreground: editorColors.blue,
      token: 'markup.link',
    },
    {
      foreground: editorColors.green,
      token: 'markup.inserted',
    },
    {
      foreground: '#D19A66',
      token: 'markup.quote',
    },
    {
      foreground: editorColors.green,
      token: 'markup.raw',
    },
    {
      foreground: editorColors.magenta,
      token: 'source.c keyword.operator',
    },
    {
      foreground: editorColors.magenta,
      token: 'source.cpp keyword.operator',
    },
    {
      foreground: editorColors.magenta,
      token: 'source.cs keyword.operator',
    },
    {
      foreground: '#E06C75',
      token: 'source.json meta.structure.dictionary.json > string.quoted.json',
    },
    {
      foreground: '#E06C75',
      token: 'source.json meta.structure.dictionary.json > string.quoted.json > punctuation.string',
    },
    {
      foreground: editorColors.green,
      token: 'source.json meta.structure.dictionary.json > value.json > string.quoted.json',
    },
    {
      foreground: editorColors.green,
      token: 'source.json meta.structure.array.json > value.json > string.quoted.json',
    },
    {
      foreground: editorColors.green,
      token:
        'source.json meta.structure.dictionary.json > value.json > string.quoted.json > punctuation',
    },
    {
      foreground: editorColors.green,
      token:
        'source.json meta.structure.array.json > value.json > string.quoted.json > punctuation',
    },
    {
      foreground: editorColors.blue,
      token: 'source.json meta.structure.dictionary.json > constant.language.json',
    },
    {
      foreground: editorColors.blue,
      token: 'source.json meta.structure.array.json > constant.language.json',
    },
    {
      foreground: editorColors.magenta,
      token: 'source.python keyword.operator.logical.python',
    },
    {
      foreground: '#D19A66',
      token: 'source.python variable.parameter',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'customrule',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'support.type.property-name',
    },
    {
      foreground: editorColors.green,
      token: 'string.quoted.double punctuation',
    },
    {
      foreground: '#D19A66',
      token: 'support.constant',
    },
    {
      foreground: '#E06C75',
      token: 'support.type.property-name.json',
    },
    {
      foreground: '#E06C75',
      token: 'support.type.property-name.json punctuation',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'source.python constant.other',
    },
    {
      foreground: '#D19A66',
      token: 'source.python constant',
    },
    {
      foreground: '#D19A66',
      token: 'constant.character.format.placeholder.other.python storage',
    },
    {
      foreground: '#E06C75',
      token: 'support.variable.magic.python',
    },
    {
      foreground: '#D19A66',
      token: 'meta.function.parameters.python',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.separator.annotation.python',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.separator.parameters.python',
    },
    {
      foreground: '#E06C75',
      token: 'entity.name.variable.field.cs',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'source.cs keyword.operator',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'variable.other.readwrite.cs',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'variable.other.object.cs',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'variable.other.object.property.cs',
    },
    {
      foreground: '#61AFEF',
      token: 'entity.name.variable.property.cs',
    },
    {
      foreground: '#E5C07B',
      token: 'storage.type.cs',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'markup.raw.block.markdown',
    },
    {
      foreground: '#E06C75',
      token: 'punctuation.definition.variable.shell',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'support.constant.property-value.css',
    },
    {
      foreground: '#D19A66',
      token: 'punctuation.definition.constant.css',
    },
    {
      foreground: '#E06C75',
      token: 'punctuation.separator.key-value.scss',
    },
    {
      foreground: '#D19A66',
      token: 'punctuation.definition.constant.scss',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.property-list.scss punctuation.separator.key-value.scss',
    },
    {
      foreground: '#E5C07B',
      token: 'storage.type.primitive.array.java',
    },
    {
      foreground: '#E06C75',
      token: 'entity.name.section.markdown',
    },
    {
      foreground: '#E06C75',
      token: 'punctuation.definition.heading.markdown',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'markup.heading.setext',
    },
    {
      foreground: '#D19A66',
      token: 'punctuation.definition.bold.markdown',
    },
    {
      foreground: editorColors.green,
      token: 'markup.inline.raw.markdown',
    },
    {
      foreground: '#E06C75',
      token: 'beginning.punctuation.definition.list.markdown',
    },
    {
      foreground: editorColors.grey,
      fontStyle: 'italic',
      token: 'markup.quote.markdown',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.string.begin.markdown',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.string.end.markdown',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'punctuation.definition.metadata.markdown',
    },
    {
      foreground: editorColors.magenta,
      token: 'punctuation.definition.metadata.markdown',
    },
    {
      foreground: editorColors.magenta,
      token: 'markup.underline.link.markdown',
    },
    {
      foreground: editorColors.magenta,
      token: 'markup.underline.link.image.markdown',
    },
    {
      foreground: '#61AFEF',
      token: 'string.other.link.title.markdown',
    },
    {
      foreground: '#61AFEF',
      token: 'string.other.link.description.markdown',
    },
    {
      foreground: '#E06C75',
      token: 'punctuation.separator.variable.ruby',
    },
    {
      foreground: '#D19A66',
      token: 'variable.other.constant.ruby',
    },
    {
      foreground: editorColors.green,
      token: 'keyword.operator.other.ruby',
    },
    {
      foreground: '#E06C75',
      token: 'punctuation.definition.variable.php',
    },
    {
      foreground: editorColors.lightGrey,
      token: 'meta.class.php',
    },
  ],
  encodedTokensColors: [],
};

export default theme;
